import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import get from 'lodash/get';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { Button, ButtonLink } from 'components/Button';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {
  selectLoanId,
  fetchLoanDetail,
  fetchPaymentsList,
  fetchTransactionsList,
  fetchDocuments,
  fetchLoanTerm,
  makePayment,
  fetchPaymentMethods,
  putScheduleAutopay as putScheduleAutopayMapped,
  fetchAutopayInfo,
  fetchCurrentPayoffInfo,
  fetchFuturePayoffInfo,
  putPaymentAccount,
  postPaymentAccount,
  postDebitCardAccount as postDebitCardAccountAction,
  fetchAutopays,
  fetchLoanCharges,
  getBillingSetting,
  validateBankRoutingNumber as validateBankRoutingNumberAction,
} from 'actions/dashboard';

import PaymentModal from 'components/PaymentModal';
import AddPaymentModal from 'components/AddPaymentMethods';
import TermsModal from 'components/TermsModal';
import LoansAgreementModal from 'components/LoansAgreementModal';
import RecurringPaymentModal from 'components/RecurringPaymentModal';
import DisclosureModal from 'components/DisclosureModal';
import PromoPayoffModal from 'components/PromoPayoffModal';
import Loader from 'components/Loader';

import { formatCurrency } from 'utils/formatCurrency';
import { parseUrlParams } from 'utils/parseUrlParams';
import { appConfig } from 'config/appConfig';
import CancelPaymentModal from 'components/CancelPaymentModal';

const Dashboard = (props) => {
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState('one-time');
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [isLoanAgreementModal, setIsLoanAgreementModal] = useState(false);
  const [isRecurringPaymentModal, setIsRecurringPaymentModal] = useState(false);
  const [isAddOrEditPaymentModalVisible, setIsAddOrEditPaymentModalVisible] = useState(false);
  const [isDisclosureModalVisible, setIsDisclosureModalVisible] = useState(false);
  const [isCancelPaymentModal, setIsCancelPaymentModal] = useState(false);
  const [paymentModalType, setPaymentModalType] = useState('add');
  const [scheduledPaymentDate, setScheduledPaymentDate] = useState('');
  const [isPromoPayoffModal, setIsPromoPayoffModal] = useState(false);
  const [isMakePaymentLoading, setIsMakePaymentLoading] = useState(false);
  const [isAutoPayLoading, setIsAutoPayLoading] = useState(false);
  const isAutoPayEnabled = get(props.autopay, 'data.isAutoPayEnabled', false);

  const handleModal = (newTab) => {
    const { loansList } = props;

    if (!props.paymentMethods.isLoading && !modal) {
      if (newTab === 'one-time') setIsMakePaymentLoading(true);
      else if (newTab === 'autopay') setIsAutoPayLoading(true);
      props.fetchLoanCharges(loansList.activeLoanId);
      props.fetchPaymentMethods({
        success: () => {
          setModal(!modal);
          setActiveTab(newTab);
          setIsMakePaymentLoading(false);
          setIsAutoPayLoading(false);
        },
        fail: () => {
          setModal(!modal);
          setActiveTab(newTab);
          setIsMakePaymentLoading(false);
          setIsAutoPayLoading(false);
        },
      });
    } else {
      setModal(!modal);
      setActiveTab('one-time');
    }
  };

  useEffect(() => {
    const { displayLoanId, autopay } = parseUrlParams(window.location.search);
    const { loansList } = props;
    let { activeLoanId } = loansList;

    if (displayLoanId && autopay === 'true') {
      const loan = loansList.data.find((l) => l.displayId === displayLoanId);
      activeLoanId = loan.id;
      props.selectLoanId(activeLoanId);
      props.fetchTransactionsList(activeLoanId);
      if (!isAutoPayEnabled) handleModal('autopay');
    }
    props.fetchLoanTerm(activeLoanId);
    props.fetchLoanDetail({
      loanId: activeLoanId,
    });
    props.fetchPaymentsList(activeLoanId);
    props.fetchAutopayInfo(activeLoanId);
    props.fetchLoanCharges(activeLoanId);
    props.getBillingSetting(activeLoanId);

    return () => {
      sessionStorage.setItem('visitedDashboard', true);
    };
  }, []);

  useEffect(() => {
    const promoPayoffModalShown = JSON.parse(sessionStorage.getItem('promoPayoffModalShown')) || [];
    if (`${props.promoEligibility}`.toLowerCase() === 'yes' && get(props.loanDetail, 'data.0.loanSubStatusId') === 50 && !promoPayoffModalShown.includes(get(props.loanDetail, 'data.0.id'))) {
      promoPayoffModalShown.push(get(props.loanDetail, 'data.0.id'));
      sessionStorage.setItem('promoPayoffModalShown', JSON.stringify(promoPayoffModalShown));
      setIsPromoPayoffModal(true);
    }
  }, [props.loanDetail, props.promoEligibility]);

  const handleTermsModal = () => {
    setIsTermsModalVisible(!isTermsModalVisible);
  };

  const handleLoanAgreementModal = () => {
    setIsLoanAgreementModal(!isLoanAgreementModal);
  };

  const handleRecurringModal = () => {
    setIsRecurringPaymentModal(!isRecurringPaymentModal);
  };

  const handleCancelPaymentModal = () => {
    setIsCancelPaymentModal(!isCancelPaymentModal);
  };

  const handleClickAllPayments = (e) => {
    e.preventDefault();
    props.history.push('/consumer/loans', { activeTab: 1 });
  };

  const handleChange = (e) => {
    props.getBillingSetting(e.target.value);
    props.selectLoanId(e.target.value);
    props.fetchLoanDetail({
      loanId: e.target.value,
    });
    props.fetchTransactionsList(e.target.value);
    props.fetchLoanTerm(e.target.value);
    props.fetchAutopayInfo(e.target.value);
    props.fetchPaymentsList(e.target.value);
    props.fetchLoanCharges(e.target.value);
  };

  const toggleDisclosureModal = (date = null) => {
    setIsDisclosureModalVisible(!isDisclosureModalVisible);
    if (date) {
      setScheduledPaymentDate(date);
    } else {
      setScheduledPaymentDate('');
    }
  };

  const putMakePayment = (data, cb) => {
    const { loansList } = props;

    props.fetchAutopays({
      loanId: loansList.activeLoanId,
      success: (res1) => {
        props.makePayment({
          data,
          loanId: loansList.activeLoanId,
          success: (res) => {
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', 'Payment made successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );

            const autopayArr = res1.slice();

            if (autopayArr.length) {
              autopayArr.sort((a, b) => new Date(a.processDateTime) - new Date(b.processDateTime));
              const firstAutopayAfterToday = autopayArr.find((item) => (new Date(item.processDateTime) - new Date()) > 0 && `${get(item, 'recurringFrequency.id')}`.toLowerCase() !== 'autopay.recurringfrequency.single');
              if (firstAutopayAfterToday) {
                toggleDisclosureModal(get(firstAutopayAfterToday, 'processDateTime'));
              }
            }

            setModal(false);
            props.fetchTransactionsList(loansList.activeLoanId);
            props.fetchPaymentsList(loansList.activeLoanId);
            props.fetchLoanDetail({
              loanId: loansList.activeLoanId,
              delay: appConfig.delay,
            });
            setTimeout(() => {
              props.fetchLoanCharges(loansList.activeLoanId);
            }, 15000);
            cb();
          },
          fail: (err) => {
            if (err.status === 400) {
              toast.error(
                (
                  <div>
                    <h5>There was an error processing your payment</h5>
                    <small>{get(err, 'data.message')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
            cb();
          },
        });
      },
    });
  };

  const putScheduleAutopay = (data, cb) => {
    const { loansList } = props;
    props.fetchAutopays({
      loanId: loansList.activeLoanId,
      success: (res1) => {
        props.putScheduleAutopay({
          data,
          loanId: loansList.activeLoanId,
          success: (res) => {
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', data.type === 'autopay.type.single' ? 'Payment scheduled successfully.' : 'AutoPay was scheduled successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );

            const autopayArr = res1.slice();

            if (autopayArr.length && data.type === 'autopay.type.single') {
              autopayArr.sort((a, b) => new Date(a.processDateTime) - new Date(b.processDateTime));
              const firstAutopayAfterToday = autopayArr.find((item) => (new Date(item.processDateTime) - new Date()) > 0 && `${get(item, 'recurringFrequency.id')}`.toLowerCase() !== 'autopay.recurringfrequency.single');
              if (firstAutopayAfterToday) {
                toggleDisclosureModal(get(firstAutopayAfterToday, 'processDateTime'));
              }
            }

            setModal(false);
            props.fetchTransactionsList(loansList.activeLoanId);
            props.fetchPaymentsList(loansList.activeLoanId);
            props.fetchLoanDetail({
              loanId: loansList.activeLoanId,
              delay: appConfig.delay,
            });
            props.fetchAutopayInfo(loansList.activeLoanId);
            setTimeout(() => {
              props.fetchLoanCharges(loansList.activeLoanId);
            }, 15000);
            cb();
          },
          fail: (err) => {
            if (err.status === 400) {
              toast.error(
                (
                  <div>
                    <h5>There was an error processing your payment</h5>
                    <small>{get(err, 'data.message')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
            cb();
          },
        });
      },
      fail: (err) => {
        if (err.status === 400) {
          toast.error(
            (
              <div>
                <h5>There was an error processing your payment</h5>
                <small>{get(err, 'data.message')}</small>
              </div>
            ),
            { autoClose: true }
          );
        }
        cb();
      },
    });
  };

  const handleAddPaymentModal = () => {
    setIsAddOrEditPaymentModalVisible(!isAddOrEditPaymentModalVisible);
    setPaymentModalType('add');
  };

  const savePaymentAccount = (data, cb) => {
    if (data) {
      props.postPaymentAccount({
        data,
        success: (res) => {
          if (cb) { cb(true); }
          toast.success(
            (
              <div>
                <h5>{get(res, 'data.message', 'Payment method added successfully!')}</h5>
              </div>
            ),
            { autoClose: true }
          );
          props.fetchPaymentMethods();
          setIsAddOrEditPaymentModalVisible(false);
        },
        fail: (err) => {
          if (cb) { cb(); }
          if (err.status === 400) {
            toast.error(
              (
                <div>
                  <h5>There was an error saving your payment account</h5>
                  <small>{get(err, 'data.message', 'Error occured')}</small>
                </div>
              ),
              { autoClose: true }
            );
          }
        },
      });
    }
  };

  const handlePayoffModal = () => {
    setIsPromoPayoffModal(!isPromoPayoffModal);
  };

  const saveDebitCardToken = (data, cb) => {
    if (data) {
      if (paymentModalType === 'add') {
        props.postDebitCardAccount({
          data,
          success: (res) => {
            if (cb) { cb(true); }
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', 'Payment method added successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );
            props.fetchPaymentMethods();
            setIsAddOrEditPaymentModalVisible(false);
          },
          fail: (err) => {
            if (cb) { cb(); }
            if (err.status === 400) {
              toast.error(
                (
                  <div>
                    <h5>There was an error saving your payment account</h5>
                    <small>{get(err, 'data.message', 'Error occured')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
          },
        });
      } else {
        if (cb) {
          cb(true);
        }
        toast.success(
          (
            <div>
              <h5>Payment method edited successfully!</h5>
            </div>
          ),
          { autoClose: true }
        );
        setIsAddOrEditPaymentModalVisible(false);
      }
    }
  };

  const {
    loansList,
    loanDetail,
    loanTerm,
    paymentsList,
    paymentMethods,
    autopay,
    loanCharges,
    channel,
    maxLateFee,
  } = props;
  const customerDetail = JSON.parse(sessionStorage.getItem('customerDetail'));
  const allpaymentmethods = [];

  paymentMethods.data && paymentMethods.data.forEach((item) => {
    const accountType = get(item, 'type', 'paymentAccount.type.checking');
    if (accountType === 'paymentAccount.type.checking' && item.checkingAccount.bankName && item.checkingAccount.accountNumber) {
      allpaymentmethods.push({
        accountName: item.checkingAccount.bankName,
        accountNumber: item.checkingAccount.accountNumber,
        paymentAccountId: item.id,
        type: item.checkingAccount.accountType.split('.'),
        token: item.checkingAccount.token,
        isPrimary: item.isPrimary,
      });
    }

    if ((accountType === 'paymentAccount.type.card' || accountType === 'paymentAccount.type.credit') && item.creditCard.cardType && item.creditCard.cardNumber) {
      allpaymentmethods.push({
        accountName: item.creditCard.cardData.issuer,
        accountNumber: item.creditCard.cardNumber,
        accountholderName: item.creditCard.cardHolderName,
        paymentAccountId: item.id,
        type: get(item, 'creditCard.cardData.type', '') || '',
        token: item.creditCard.token,
        isPrimary: item.isPrimary,
        brand: item.creditCard.cardData.brand,
        address: {
          city: item.creditCard.city,
          state: item.creditCard.state,
          address: item.creditCard.address1,
          zipCode: item.creditCard.zipCode,
        },
        cardExpiration: item.creditCard.cardExpiration,
      });
    }
  });

  let primaryPaymentMethod = '';
  if (paymentMethods.data) {
    primaryPaymentMethod = paymentMethods.data.find((item) => item.isPrimary);

    if (primaryPaymentMethod && primaryPaymentMethod.type === 'paymentAccount.type.checking' && primaryPaymentMethod.checkingAccount.bankName && primaryPaymentMethod.checkingAccount.accountNumber) {
      primaryPaymentMethod = `${primaryPaymentMethod.id}-${primaryPaymentMethod.checkingAccount.accountType.split('.')[2]}`;
    }

    if (primaryPaymentMethod && (primaryPaymentMethod.type === 'paymentAccount.type.card' || primaryPaymentMethod.type === 'paymentAccount.type.credit') && primaryPaymentMethod.creditCard.cardType && primaryPaymentMethod.creditCard.cardNumber) {
      primaryPaymentMethod = `${primaryPaymentMethod.id}-${primaryPaymentMethod.creditCard.cardData.type.toLowerCase()}`;
    }
  }

  const pastDue = Number(get(loanDetail, 'data.0.daysPastDue', 0));
  // session
  const visitedDashboard = sessionStorage.getItem('visitedDashboard');

  // data parsing
  const payments = get(paymentsList, 'data');
  const customerName = `${get(customerDetail, 'firstName')} ${get(customerDetail, 'lastName')}`;
  const principalAmount = Number(get(loanTerm, 'data.principalAmount', 0));
  const principalBalance = Number(get(loanDetail, 'data.0.principalBalance', 0));
  const nextPaymentAmount = get(loanDetail, 'data.0.nextPaymentAmount', 0) && formatCurrency(get(loanDetail, 'data.0.nextPaymentAmount', 0), 2).split('.');
  const pastDueAmount = formatCurrency(get(loanDetail, 'data.0.amountDue'), 2).split('.');
  const dueFees = Number(get(loanCharges, 'data.unpaidCharges'));
  const totalDueAmount = get(loanDetail, 'data.0.totalDue');
  const nextPaymentDate = get(loanDetail, 'data.0.nextPaymentDate', '') ? moment(new Date(get(loanDetail, 'data.0.nextPaymentDate', ''))).format('MM/DD/YYYY').toString() : '-';
  const activeloanId = loansList.activeLoanId;

  let paid = principalAmount - principalBalance;

  if (paid > principalAmount) {
    paid = principalAmount;
  } else if (paid < 0) {
    paid = 0;
  }

  // initialize graph
  const paymentData = [{ name: 'Payments', paid }];

  return (
    <div className={`page-dashboard ${visitedDashboard ? 'noAnimations' : ''}`}>
      <Container fluid>

        <Row className="mb-3 align-items-center">
          <Col className="text-center text-md-left">
            <h3 className="mb-0">
              Welcome,
              {' '}
              {customerName}
              !
            </h3>
            <small>
              <strong>Customer ID:&nbsp;</strong>
              {' '}
              {get(customerDetail, 'customId')}
            </small>
          </Col>
          <Col xs={12} md="auto" className="mt-3 mt-md-0">
            { pastDue > 0
              ? (
                <Row>
                  <Col xs="auto" className="pr-0">
                    <img src="/icons/consumer-status-pastdue.svg" alt="Past Due" />
                  </Col>
                  <Col>
                    <h5 className="mb-0">Call 888-502-8085 or Make A Payment</h5>
                    <small>To keep your account in good standing</small>
                  </Col>
                </Row>
              )
              : (
                <Row>
                  <Col xs="auto" className="pr-0">
                    <img src="/icons/consumer-status-current.svg" alt="Current" />
                  </Col>
                  <Col>
                    <h5 className="mb-0">
                      Great work
                      {' '}
                      {customerName && customerName.split(' ')[0]}
                      !
                    </h5>
                    <small>You are up to date with your payments</small>
                  </Col>
                </Row>
              )}
          </Col>
          <Col xs={12} md="auto" className="text-right justify-content-end ml-md-3 mt-3 mt-md-0">
            <FormGroup className="dropdown-toggle mb-0 input-inline pb-0">
              <Input
                type="select"
                name="select"
                id="loanSelection"
                onChange={handleChange}
                value={activeloanId}
                style={{
                  padding: '.675rem 1.125rem',
                  height: 'auto',
                  paddingRight: '45px',
                }}
              >
                <option disabled>Select</option>
                {
                  loansList.data && loansList.data.map((item, index) => (
                    <option value={item.id} key={`loanlist_${index}`}>{item.displayId}</option>
                  ))
                }
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={4}>

            <Card inverse color="primary">
              <CardBody className="text-center">
                <div style={{ height: 57 }}>
                  {
                    loanDetail.data
                      ? (
                        <h1 style={{ fontSize: '3rem' }}>
                          $
                          {formatCurrency(principalBalance, 2).split('.')[0]}
                          .
                          <small>{formatCurrency(principalBalance, 2).split('.')[1]}</small>
                        </h1>
                      )
                      : <Loader className="light height-100" />
                  }
                </div>
                <h6>Principal Balance</h6>
                <Row className="mt-4">
                  <Col className="pr-0">
                    <Card className="pt-2 pb-2" style={{ height: '110px' }}>
                      {
                        loanDetail.data ? (
                          <>
                            <h6>Next Payment</h6>
                            {nextPaymentAmount ? (
                              <h3>
                                $
                                {nextPaymentAmount[0]}
                                .
                                <small>{nextPaymentAmount[1]}</small>
                              </h3>
                            ) : <h3>-</h3>}
                            <h6 className="mb-0">
                              <span>
                                Due by
                                {loanDetail.data ? nextPaymentDate : '-'}
                              </span>
                            </h6>
                          </>
                        ) : (
                          <Loader className="light height-100" />
                        )
                      }
                    </Card>
                  </Col>
                  <Col>
                    <Card className={`pt-2 pb-2 ${pastDue > 0 ? 'bg-white' : ''}`} style={{ height: '110px' }}>
                      {
                        loanDetail.data ? (
                          <>
                            <h6 className={pastDue > 0 ? 'text-danger' : ''}>Past Due</h6>
                            <h3 className={`${pastDue > 0 ? 'text-danger' : ''}`}>
                              $
                              {pastDueAmount[0]}
                              .
                              <small className={`${pastDue > 0 ? 'text-danger' : ''}`}>{pastDueAmount[1]}</small>
                            </h3>
                            <h6 className={`mb-0 ${pastDue > 0 ? 'text-danger' : ''}`}>
                              {pastDue > 0 ? 'DUE NOW' : 'UP TO DATE'}
                            </h6>
                          </>
                        ) : (
                          <Loader className="light height-100" />
                        )
                      }
                    </Card>
                  </Col>
                </Row>
                {!isAutoPayEnabled && (
                  <Button isLoading={autopay.isLoading || isAutoPayLoading} color="light" outline className="w-100 mb-1" onClick={handleModal.bind(null, 'autopay')}>
                    <img src="/icons/autopay.svg" alt="" className="mr-1" />
                    Set Up Autopay
                  </Button>
                )}
                <Button isLoading={isMakePaymentLoading} color="light" className="w-100" onClick={handleModal.bind(null, 'one-time')}>
                  <img src="/icons/payment.svg" alt="" className="mr-1" />
                  Make A Payment
                </Button>
              </CardBody>
            </Card>

          </Col>
          <Col md={6} lg={8}>

            <Card>
              <CardHeader className="text-left">
                Loan Tracker
                <ButtonLink onClick={handleModal.bind(null, 'one-time')} className={`float-right ${pastDue > 0 ? 'text-danger' : ''}`} style={{ cursor: 'pointer' }}><span className="btn-link" style={{ textDecoration: 'none' }}>Make A Payment</span></ButtonLink>
              </CardHeader>
              <CardBody className="pt-2">
                <ResponsiveContainer width="100%" height={30}>
                  <BarChart
                    data={paymentData}
                    layout="vertical"
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    height={15}
                    barSize={15}
                  >
                    <XAxis type="number" height={0} domain={[0, loanTerm.data ? principalAmount : 100]} />
                    <YAxis dataKey="name" type="category" width={0} />
                    <Bar dataKey="paid" stackId="a" fill="#04BC6C" background={{ fill: '#DBE3EB' }} radius={3} />
                  </BarChart>
                </ResponsiveContainer>
                <small className="float-left">
                  <strong>
                    $
                    {formatCurrency(principalAmount - principalBalance, 2)}
                    {' '}
                    paid in principal
                  </strong>
                </small>
                <small className="float-right">
                  $
                  {formatCurrency(principalAmount, 2)}
                </small>
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="text-md-left">
                Last 5 Payments
                <NavLink to="/consumer/loans" onClick={handleClickAllPayments} className="d-block d-md-inline float-md-right">View All Payments</NavLink>
              </CardHeader>
              {
                payments === undefined
                  ? <Loader className="height-2" />
                  : (
                    <Table className="mb-0" size="sm" responsive striped borderless>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th className="text-right">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        payments.length > 0 ? payments.slice(0, 5).map((item, index) => (
                          <tr key={index}>
                            <th scope="row">{moment(new Date(item.date)).format('MM/DD/YYYY').toString()}</th>
                            <td>Payment Received</td>
                            <td>{item.status.label}</td>
                            <td className="text-right">
                              $
                              {formatCurrency(item.amount, 2)}
                            </td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan={6} align="center" className="pt-3 pb-3">No data</td>
                          </tr>
                        )
                      }
                      </tbody>
                    </Table>
                  )
              }
            </Card>

          </Col>
        </Row>
      </Container>

      <PaymentModal
        isOpen={modal}
        toggle={handleModal}
        nextPaymentAmount={nextPaymentAmount}
        nextPaymentDate={nextPaymentDate}
        pastDueAmount={pastDueAmount}
        dueFees={dueFees}
        totalDueAmount={totalDueAmount}
        paymentMethods={allpaymentmethods}
        putMakePayment={putMakePayment}
        putScheduleAutopay={putScheduleAutopay}
        toggleTermsModal={handleTermsModal}
        toggleLoanAgreementModal={handleLoanAgreementModal}
        toggleRecurringModal={handleRecurringModal}
        toggleCancelPaymentModal={handleCancelPaymentModal}
        activeTab={activeTab}
        primaryPaymentMethod={primaryPaymentMethod}
        fetchCurrentPayoffInfo={props.fetchCurrentPayoffInfo}
        fetchFuturePayoffInfo={props.fetchFuturePayoffInfo}
        addPaymentMethod={handleAddPaymentModal}
        channel={channel}
      />

      <AddPaymentModal
        isOpen={isAddOrEditPaymentModalVisible}
        toggle={handleAddPaymentModal}
        saveToken={savePaymentAccount}
        saveDebitCardToken={saveDebitCardToken}
        paymentModalType={paymentModalType}
        validateBankRoutingNumber={props.validateBankRoutingNumber}
      />

      <DisclosureModal
        isOpen={isDisclosureModalVisible}
        toggle={toggleDisclosureModal}
        scheduledPaymentDate={scheduledPaymentDate}
      />

      <TermsModal
        isOpen={isTermsModalVisible}
        fee={maxLateFee}
        toggle={handleTermsModal}
        channel={channel}
      />

      <LoansAgreementModal
        isOpen={isLoanAgreementModal}
        toggle={handleLoanAgreementModal}
      />

      <RecurringPaymentModal
        isOpen={isRecurringPaymentModal}
        toggle={handleRecurringModal}
      />

      <CancelPaymentModal
        isOpen={isCancelPaymentModal}
        toggle={handleCancelPaymentModal}
      />

      <PromoPayoffModal
        isOpen={isPromoPayoffModal}
        toggle={handlePayoffModal}
      />
    </div>
  );
};

Dashboard.propTypes = {
  fetchPaymentsList: PropTypes.func.isRequired,
  selectLoanId: PropTypes.func.isRequired,
  putScheduleAutopay: PropTypes.func.isRequired,
  postDebitCardAccount: PropTypes.func.isRequired,
  fetchAutopayInfo: PropTypes.func.isRequired,
  postPaymentAccount: PropTypes.func.isRequired,
  fetchLoanTerm: PropTypes.func.isRequired,
  fetchLoanDetail: PropTypes.func.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  fetchTransactionsList: PropTypes.func.isRequired,
  makePayment: PropTypes.func.isRequired,
  fetchCurrentPayoffInfo: PropTypes.func.isRequired,
  fetchFuturePayoffInfo: PropTypes.func.isRequired,
  fetchAutopays: PropTypes.func.isRequired,
  fetchLoanCharges: PropTypes.func.isRequired,
  getBillingSetting: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loansList: PropTypes.object.isRequired,
  loanDetail: PropTypes.object.isRequired,
  loanTerm: PropTypes.object.isRequired,
  paymentsList: PropTypes.object.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  autopay: PropTypes.object.isRequired,
  loanCharges: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired,
  promoEligibility: PropTypes.string.isRequired,
  maxLateFee: PropTypes.string.isRequired,
  validateBankRoutingNumber: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loansList: state.dashboard.loansList,
  loanDetail: state.dashboard.loanDetail,
  loanTerm: state.dashboard.loanTerm,
  transactionsList: state.dashboard.transactionsList,
  paymentsList: state.dashboard.paymentsList,
  paymentMethods: state.dashboard.paymentMethods,
  makePaymentMessage: state.dashboard.makePaymentMessage,
  autopay: state.dashboard.autopay,
  loanCharges: state.dashboard.loanCharges,
  channel: state.dashboard.billingSetting && state.dashboard.billingSetting.data && state.dashboard.billingSetting.data.customFields ? state.dashboard.billingSetting.data.customFields.channel : '',
  promoEligibility: state.dashboard.billingSetting && state.dashboard.billingSetting.data && state.dashboard.billingSetting.data.customFields ? state.dashboard.billingSetting.data.customFields.promoEligibility : 'No',
  maxLateFee: state.dashboard.billingSetting && state.dashboard.billingSetting.data && state.dashboard.billingSetting.data.customFields ? state.dashboard.billingSetting.data.customFields.maxLateFee : null,
});

const mapDispatchToProps = {
  selectLoanId,
  fetchLoanDetail,
  fetchPaymentsList,
  fetchTransactionsList,
  fetchDocuments,
  fetchLoanTerm,
  makePayment,
  fetchPaymentMethods,
  putScheduleAutopay: putScheduleAutopayMapped,
  fetchAutopayInfo,
  fetchCurrentPayoffInfo,
  fetchFuturePayoffInfo,
  putPaymentAccount,
  postPaymentAccount,
  postDebitCardAccount: postDebitCardAccountAction,
  fetchAutopays,
  fetchLoanCharges,
  getBillingSetting,
  validateBankRoutingNumber: validateBankRoutingNumberAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
