import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { ButtonLink } from 'components/Button';
import { appConfig } from 'config/appConfig';
import { numberUnmask } from 'utils/masks';

const RegistrationSuccess = ({ history }) => {
  const handleResetClick = () => history.push('/forgot');

  const handleSignInClick = () => history.push('/');

  return (
    <div className="registration-page">
      <Container fluid>
        <Row style={{ minHeight: 600 }}>
          <Col className="d-flex text-center flex-column col col-md-6 offset-md-3 pt-2">
            <h2 className="text-center">You are already registered!</h2>
            <p className="mt-3">You can proceed to Sign In by clicking the button below</p>
            <div className="d-flex justify-content-center">
              <Button
                style={{ width: 350 }}
                onClick={handleSignInClick}
                color="primary"
                type="submit"
              >
                Sign In
              </Button>
            </div>

            <div className="mt-4"><b>Forgot Password?</b></div>
            <ButtonLink className="mt-1 btn-link cursor-pointer" style={{ cursor: 'pointer' }} onClick={handleResetClick}>
              Click here to reset your password
            </ButtonLink>
            <p className="text-center mt-2">
              After you provide your username, a confirmation code will be sent to your email.
              <br />
              Follow directions on screen to proceed.
            </p>

            <div className="mt-4"><b>Forgot Username?</b></div>
            <p className="text-center mt-2">
              Please call customer service team at
              {' '}
              <a href={`tel:+1${numberUnmask(appConfig.servicingPhone)}`} className="phoneNumber">{appConfig.servicingPhone}</a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

RegistrationSuccess.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(RegistrationSuccess);
