import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import moment from 'moment';
import { Button } from 'components/Button';

const DisclosureModal = ({ isOpen, toggle, scheduledPaymentDate }) => (
  <Modal isOpen={isOpen} toggle={toggle} size="md">
    <ModalBody className="pt-3 pb-2">
      <Container fluid>
        <Row>
          <Col className="pl-0 pr-0">
            <p className="disclosure-content text-center"><b>Thank you for making an additional payment!</b></p>
            <p className="disclosure-content">
              {`As a reminder, your upcoming autopay payment is scheduled for ${moment(new Date(scheduledPaymentDate)).format('MM/DD/YYYY').toString()}. To cancel any future autopay payments, please visit the “Manage Autopay” section of the Customer Portal, and make adjustments at least 72 hours ahead of the scheduled draft date.`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-md-center">
            <Button
              color="primary"
              onClick={toggle}
            >
              Close
            </Button>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
);

DisclosureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  scheduledPaymentDate: PropTypes.string.isRequired,
};

export default DisclosureModal;
