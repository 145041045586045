import React from 'react';
import PropTypes from 'prop-types';

const CirclePhone = ({ width, className }) => (
  <svg
    width={width}
    height={width}
    className={className}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g id="Borrowers---Apply" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Borrowers---Apply---Step-1---Desktop---V2" transform="translate(-910.000000, -472.000000)">
        <g id="Group-10" transform="translate(910.000000, 472.000000)">
          <circle id="Oval" fill="#002248" cx="16" cy="16" r="16" />
          <path d="M11.3649769,8.01362702 C11.2612475,7.99288114 11.1367723,7.99288114 11.0537888,8.03437289 C9.93351157,8.47003625 9.22815184,10.0674686 9.06218484,11.1669999 C8.54353798,14.7352903 11.3649769,17.8264256 13.9582112,19.8597288 C16.2610033,21.6644124 20.6798745,24.6312799 23.2938547,21.7681417 C23.6257887,21.4156693 24.0199603,20.8970225 23.9992144,20.3783756 C23.9369768,19.5068414 23.1278877,18.8844652 22.5055115,18.4073101 C22.0283563,18.0546302 21.0325544,17.0795741 20.4101781,17.10032 C19.8500395,17.1210659 19.4973597,17.7019504 19.1446798,18.0546302 L18.5223036,18.6770065 C18.4185742,18.7807358 17.0908382,17.9094091 16.9456171,17.8056797 C16.4062244,17.4529999 15.8875775,17.0588283 15.4311683,16.6231649 C14.974759,16.1875015 14.5598416,15.7103464 14.2071617,15.2124454 C14.1034323,15.0672243 13.2528515,13.801726 13.335835,13.6979966 C13.335835,13.6979966 14.0619406,12.9096534 14.2693993,12.5984653 C14.7050627,11.9345973 15.0369967,11.4159504 14.5390957,10.6276072 C14.3523828,10.3371649 14.1241782,10.1089603 13.8752277,9.86000984 C13.4395643,9.44509235 13.003901,9.00942899 12.506,8.65674912 C12.2363036,8.44929038 11.7798944,8.07586464 11.3649769,8.01362702" id="Fill-1" fill="#FFFFFF" />
        </g>
      </g>
    </g>
  </svg>
);

CirclePhone.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

CirclePhone.defaultProps = {
  width: 32,
  className: '',
};

export default CirclePhone;
