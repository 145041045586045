import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './style.scss';

const PrivacyPolicy = () => (
  <div className="privacy-policy-page">
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
          <h1>Privacy Policy</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
          <p><strong>Revised October 2021</strong></p>
          <p><span>This privacy policy is for LendingUSA, LLC and its non-ServicingUSA, LLC affiliates (“LendingUSA” or “we” or “us”). LendingUSA is committed to your privacy and security online. The LendingUSA Privacy Policy (“Policy”) applies to everyone who visits any one of our affiliated websites, portals or platforms (“Websites”).</span></p>
          <h3>Information Collection and Use</h3>
          <h4>Merchants</h4>
          <p><span>Subject to any agreement to the contrary, LendingUSA is the owner of the information collected on these Websites. When you visit LendingUSA Websites, such as to submit a merchant enrollment application or to submit a loan application through your merchant portal, or have any interaction with us online, the information below may be collected for various purposes including, but not limited to, to review your and your business’ eligibility for the LendingUSA merchant program and to provide the services related to that and other programs:</span></p>
          <ul>
            <li>Your browser type</li>
            <li>Your IP address</li>
            <li>The presence of any software on your device that may be necessary to view our Websites</li>
            <li>Information about the device you are using</li>
            <li>
              Business and Personal information submitted by you on applications, forms, and electronic messaging. Such personal or business information may include:
              <ul className="level-1">
                <li>
                  Your Business’:
                  <ul className="level-2">
                    <li>Legal Name and DBA</li>
                    <li>Size and Type</li>
                    <li>Address</li>
                    <li>Telephone Number</li>
                    <li>E-mail Address</li>
                    <li>Federal Tax ID</li>
                    <li>Years in Operation</li>
                    <li>Ownership Information</li>
                    <li>Registration and Licenses</li>
                    <li>Insurance Information</li>
                    <li>Website</li>
                    <li>Bank Account Information</li>
                    <li>Primary financing contact’s name, job title, business e-mail and phone number (if different than you)</li>
                    <li>Customer Invoices</li>
                  </ul>
                </li>
                <li>
                  Your:
                  <ul>
                    <li>Name</li>
                    <li>Job title</li>
                    <li>Social Security Number</li>
                    <li>Date of Birth</li>
                    <li>E-mail Address</li>
                    <li>Telephone Number</li>
                    <li>Address</li>
                    <li>Employment Information</li>
                  </ul>
                </li>
                <li>We also collect any other public and non-public information that you voluntarily provide us with so that we can process your request, this information may be provided through our Websites or through other means. Your decision to provide additional information is entirely optional, however the information may be required to receive the product that you requested.</li>
              </ul>
            </li>
            <li>
              Business and Personal information retrieved from third parties for the purpose of processing your request. Such personal information may include:
              <ul>
                <li>Credit Scores</li>
                <li>Credit History</li>
                <li>Payment History</li>
                <li>Information to Verify Your Identity and to Mitigate Risk</li>
                <li>Bank Account Information and Transactions</li>
                <li>Pending litigation</li>
              </ul>
            </li>
          </ul>
          <h4>Borrowers</h4>
          <p>LendingUSA is the owner of the information collected on these Websites. When you visit LendingUSA Websites, submit an application or have any interaction with us online, the information below may be collected for various purposes, including to review your eligibility for the financial product and/or service that you requested, to verify your identity and to service your account:</p>
          <ul>
            <li>Your browser type</li>
            <li>Your IP address</li>
            <li>The presence of any software on your device that may be necessary to view our Websites</li>
            <li>Information about the device you are using</li>
            <li>
              Personal information submitted by you on applications, forms, and electronic messaging, including:
              <ul className="level-1">
                <li>Name</li>
                <li>Social Security Number</li>
                <li>Address</li>
                <li>Date of Birth</li>
                <li>Income Information</li>
                <li>Employment Information</li>
                <li>Email Address</li>
                <li>Telephone Number</li>
                <li>Account Numbers</li>
                <li>Paystubs</li>
                <li>Cancelled Checks</li>
                <li>Selfies</li>
                <li>Driver’s Licenses</li>
                <li>Passport</li>
                <li>Usernames</li>
                <li>Passwords</li>
                <li>We also collect any other public and non-public information that you voluntarily provide us so that we can process your request. This information may be provided through our Websites or through other means. Your decision to provide additional information is entirely optional. However, the information may be required to receive the product that you requested.</li>
              </ul>
            </li>
            <li>
              Personal information retrieved from third parties for the purpose of processing your request, including:
              <ul className="level-1">
                <li>Credit Scores</li>
                <li>Credit History</li>
                <li>Payment History</li>
                <li>Information to Verify Your Identity and to Mitigate Risk</li>
                <li>Bank Account Information and Transactions</li>
                <li>Information from Your Merchant Regarding Your Transaction</li>
              </ul>
            </li>
          </ul>
          <h3>Security</h3>
          <p>
            We take precautions to protect your information. When you submit sensitive information via the Websites, your information is protected both online and offline. Wherever we collect sensitive information (such as credit history data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon in your web browser, or looking for “https” at the beginning of the address of the web page. Placement of this icon varies by website browser. While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment. If you feel that we are not abiding by this Policy, you should contact us immediately via telephone at
            {' '}
            <a href="tel:18009946177">1-800-994-6177</a>
            .
          </p>
          <h3>Registration</h3>
          <p>In order to use some of our Websites, a user must first complete a registration form. During registration, a user is required to give certain information (such as name and email address). This information may be used to contact you about the products and/or services on our Websites in which you have expressed interest. At your option, you may also provide demographic information (such as gender or age) about yourself, but it is not required.</p>
          <h3>Cookies</h3>
          <p>We use “cookies” on our Websites. A cookie is a piece of data stored on a website visitor’s hard drive to help us improve your access to our Websites and identify repeat visitors to our Websites. For instance, when we use a cookie to identify you, you may not have to enter information that you already entered more than once, thereby saving time while on our Websites. Cookies can also enable us to track and target the interests of our users to enhance the experience on our Websites. Some of our business partners may use cookies on our Websites (for example, advertisers). However, we have no access to or control over our business partners’ cookies.</p>
          <p>You can disallow cookies by making the appropriate selection from your browser options. However, if you disallow cookies, you may limit the enhanced functionality that cookies allow.</p>
          <h3>Sharing</h3>
          <p>We partner with other third parties to provide specific services so that we may process your request or service your accounts. We will share your name and any other information that is necessary for the third party to provide these services. These third parties are not allowed to use personally identifiable information except for the purpose of providing these services.</p>
          <p>If you are a borrower, we will also share your information with the merchant whose products and/or services you are attempting to finance.</p>
          <p>If you are a borrower, periodically, we may identify third party products and/or services that you may have an interest in. In those cases, we will share your information so that those third parties can provide you with more details about their products and/or services. We may receive compensation arising out of these referrals. With respect to merchants, and only with consent, periodically we may identify third party products and/or services that you may have an interest in, and we will share your information so that they can provide you with more details about their products and/or services. We may receive compensation arising out of these referrals.</p>
          <p>We may disclose your personal information as otherwise permitted or required by law, including furnishing your account history to credit bureaus or to assignees if you are a borrower.</p>
          <h3>Links</h3>
          <p>These Websites contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our Websites and to read the privacy statements of any other site that collects personally identifiable information.</p>
          <h3>Surveys &amp; Contests</h3>
          <p>From time-to-time, our Websites may request information via surveys or contests. Participation in these surveys or contests is completely voluntary and you may choose whether or not to participate and therefore disclose this information. Information requested may include contact information (such as name and address), and demographic information (such as zip code and age range). Contact information will be used to notify the winners and award prizes. Survey information will be used for the purpose of monitoring and improving the use and satisfaction of our Websites and/or services.</p>
          <h3>Questions and Contact</h3>
          <p>
            If you have any questions about this Policy or the practices described herein, you may contact us at
            {' '}
            <a href="tel:18009946177">1-800-994-6177</a>
            . Unless you ask us not to by calling us at
            {' '}
            <a href="tel:18009946177">1-800-994-6177</a>
            {' '}
            or e-mailing us at
            {' '}
            <a href="mailto:customercare@lendingusa.com">customercare@lendingusa.com</a>
            , we may contact you via email, text messaging, or via telephone in the future to tell you about specials, new products and/or services.
          </p>
          <h3>Revisions to This Policy</h3>
          <p>LendingUSA reserves the right to revise this Policy at any time. When we do, we will post the change(s) on these Websites. You will not be given any notice of these changes. Any change to this Policy will be posted on our Websites in a timely manner. Your continued use of our Websites and web pages after any changes are posted will constitute your agreement to and acceptance of such changes to this Policy.</p>
          <h3>California Residents Only – California Consumer Privacy Act Notice</h3>
          <p>Under the California Consumer Privacy Act (“CCPA”), consumers who are residents of California have certain privacy rights that they may exercise regarding their personal information that we may collect. The CCPA defines personal information (“Personal Information”) as information that identifies, relates to, or could reasonably be linked directly or indirectly with a particular California consumer. The following CCPA Notice (“CCPA Notice”) supplements the information contained in LendingUSA’s general Privacy Policy described above.</p>
          <p>The CCPA does not apply to certain Personal Information, such as information subject to the Gramm-Leach-Bliley Act (“GLBA”) or the Fair Credit Reporting Act (“FCRA”). As a result, the CCPA does not apply with respect to information that we collect about California consumers who apply for or obtain financial products and/or services for personal, family, or household purposes. For more information about how we collect, disclose, and secure information relating to these consumers, please refer to our Privacy Notice.</p>
          <p>Pursuant to this CCPA Notice, and subject to exceptions and limitations provided by the CCPA, California consumers (“you”) have the right to request that we:</p>
          <ul>
            <li>Disclose the categories of Personal Information that we collected about you during the last twelve (12) months;</li>
            <li>Disclose the categories of sources from which we collected such information;</li>
            <li>Disclose the business or commercial purposes for collecting such information about you;</li>
            <li>Disclose the categories of third parties to whom we shared such information;</li>
            <li>Disclose the specific pieces of Personal Information we collected about you;</li>
            <li>Delete Personal Information that we collected about you;</li>
            <li>Not sell your Personal Information that we collected about you.</li>
          </ul>
          <p>You have the right to designate an authorized agent to make a CCPA request on your behalf. However, in order for that request to be reviewed and processed, either you or your authorized agent must provide proof of written permission to act on your behalf.</p>
          <p>While the categories and purpose of Personal Information that we collect, use, and disclose about a California resident will vary based on our specific relationship and interaction with that individual, generally, in the last twelve (12) months, we have collected the following information relating to California residents:</p>
          <ul>
            <li>
              Categories and Specific Examples of Personal Information Collected:
              <ul>
                <li>Identifiers (name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security Number, driver’s license or state-issued identification card number, passport number or other similar government issued identifiers, bank account numbers, credit or debit card numbers, financial information);</li>
                <li>Commercial Information (records of personal property, products or services purchased, obtained or considered, other purchasing or consuming histories or tendencies);</li>
                <li>Professional and Employment-Related Information (current or past job history and income);</li>
                <li>Characteristics of Protected Classifications under California or Federal Law (age, race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex – including gender, gender identity, gender expression and sexual orientation, military or veteran status, genetic information – including familial genetic information);</li>
                <li>Records of Your Interactions (phone calls, text messages, emails, other communications with our Customer Service and call center representatives, or any employees of ours);</li>
                <li>Internet or Other Electronic Network Activities (information regarding your interaction with an Internet website, application or advertisement, browsing history, search history);</li>
                <li>Preferences (communication preferences – for example, whether you prefer electronic communications versus receiving hard copies of documents);</li>
                <li>Biometric Information (height, eye color, weight, voice recordings);</li>
                <li>Geolocation Data (device location and certain device information);</li>
                <li>Sensory Data (audio, electronic, visual or other information);</li>
                <li>Inferences drawn from any of the information identified above to create a profile about you reflecting your preferences, characteristics, psychological trends, preferences, predispositions, behavior, attitudes, intelligence, abilities and aptitudes.</li>
              </ul>
            </li>
            <li>
              Purpose for Which the Personal Information Shall be Used:
              <ul>
                <li>Operate, manage and maintain our business;</li>
                <li>Provide our products and/or services;</li>
                <li>For our employment and vendor management purposes;</li>
                <li>Detect and prevent fraud (including protecting against and/or prosecuting malicious, deceptive, fraudulent or illegal activity);</li>
                <li>Personalize, develop, market, advertise and provide our products and/or services;</li>
                <li>Perform identity verification;</li>
                <li>Conduct risk and security control and monitoring;</li>
                <li>Perform audit functions, including auditing interactions with consumers;</li>
                <li>Comply with legal obligations;</li>
                <li>Exercise and defend legal claims and rights.</li>
              </ul>
            </li>
            <li>
              Categories of Sources from Which Personal Information is Collected:
              <ul>
                <li>Directly from you;</li>
                <li>Our affiliates;</li>
                <li>Online services, including cookies and pixel tags;</li>
                <li>Consumer reporting agencies;</li>
                <li>Social and advertising networks;</li>
                <li>Data analytics providers;</li>
                <li>Government entities and other public sources;</li>
                <li>Third parties who you have authorized or directed to share your information with us;</li>
                <li>Service providers with whom we have a contractual relationship to perform services on our behalf.</li>
              </ul>
            </li>
            <li>
              Categories of Third Parties with Whom LendingUSA Shares Personal Information:
              <ul>
                <li>Our affiliates;</li>
                <li>Consumer reporting agencies;</li>
                <li>Government entities and other public sources;</li>
                <li>Third parties with whom you have authorized or directed us to share your information;</li>
                <li>Financial institutions</li>
                <li>Merchants whose products and/or services you sought financing for</li>
                <li>Debt settlement companies</li>
                <li>Service providers with whom we have a contractual relationship to perform services on our behalf.</li>
              </ul>
            </li>
            <li>
              Purpose for Which the Personal Information Shall be Shared:
              <ul>
                <li>To provide credit reporting to credit reporting agencies;</li>
                <li>Sharing information with any successor to all or part of our business, including the right to transfer information we have about you in the event we sell or transfer all or a portion of our business or assets;</li>
                <li>To transfer or assign your loan or account;</li>
                <li>To complete a transaction you requested;</li>
                <li>To perform services on our behalf, including any services related to our purpose for using Personal Information;</li>
                <li>For compensation;</li>
                <li>To comply with a legal or regulatory request, audit, or court order.</li>
              </ul>
            </li>
          </ul>
          <p>Only you or your authorized agent may make a request related to your Personal Information. An authorized agent may be a person or a business entity registered with the California Secretary of State to act on your behalf. If you intend to submit a request on behalf of someone else, we must have on file or you must provide to us appropriate power of attorney, guardian, conservator or trustee document(s) confirming you have the authority to act on behalf of the individual for whom you are submitting the request.&nbsp; We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to act on another’s behalf. Making a request does not require you to create an account with us, and we will only use Personal Information provided in such a request to verify the requestor’s identity or authority to make the request.</p>
          <p>We sell Personal Information as described in the CCPA.</p>
          <p>
            To exercise your rights under the CCPA, you can do so by clicking
            {' '}
            <a className="link" href="https://next.lendingusa.com/do-not-sell">here</a>
            {' '}
            or by e-mailing us at
            {' '}
            <a href="mailto:customercare@lendingusa.com">customercare@lendingusa.com</a>
            {' '}
            You must specify which right you want to exercise and provide your full name and contact information, including e-mail address, mailing address and phone number.
          </p>
          <h3>LendingUSA Privacy Notice</h3>
          <p><strong>Revised October 2021</strong></p>
          <table>
            <tbody>
              <tr>
                <td valign="top" width="134"><strong style={{ fontSize: 18, fontWeight: 900 }}>FACTS</strong></td>
                <td valign="top" width="406">
                  <strong>WHAT DOES LENDINGUSA, LLC DO WITH YOUR PERSONAL INFORMATION?</strong>
                </td>
              </tr>
              <tr>
                <td valign="top"><strong>Why?</strong></td>
                <td valign="top">Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.</td>
              </tr>
              <tr>
                <td valign="top"><strong>What?</strong></td>
                <td valign="top">
                  The types of personal information we collect and share depend on the product or service you have with us. This information can include:
                  <p />
                  <ul>
                    <li>Social Security Number and Account Balances</li>
                    <li>Payment History and Income Information</li>
                    <li>Credit History and Credit Score</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top"><strong>How?</strong></td>
                <td valign="top">All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information, the reasons LendingUSA, LLC chooses to share, and whether you can limit this sharing.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td valign="top" width="340"><strong>Reasons we can share your personal information</strong></td>
                <td valign="top" width="150"><strong>Does LendingUSA share?</strong></td>
                <td valign="top" width="150"><strong>Can you limit this sharing?</strong></td>
              </tr>
              <tr>
                <td valign="top">
                  <strong>For our everyday business purposes—</strong>
                  such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus
                </td>
                <td valign="top">Yes</td>
                <td valign="top">No</td>
              </tr>
              <tr>
                <td valign="top">
                  <strong>For our marketing purposes—</strong>
                  to offer our products and services to you
                </td>
                <td valign="top">Yes</td>
                <td valign="top">No</td>
              </tr>
              <tr>
                <td valign="top"><strong>For joint marketing with other financial companies</strong></td>
                <td valign="top">Yes</td>
                <td valign="top">No</td>
              </tr>
              <tr>
                <td valign="top">
                  <strong>For our affiliates’ everyday business purposes—</strong>
                  information about your transactions and experiences
                </td>
                <td valign="top">Yes</td>
                <td valign="top">No</td>
              </tr>
              <tr>
                <td valign="top">
                  <strong>For our affiliates’ everyday business purposes—</strong>
                  information about your creditworthiness
                </td>
                <td valign="top">Yes</td>
                <td valign="top">Yes</td>
              </tr>
              <tr>
                <td valign="top"><strong>For our affiliates to market to you</strong></td>
                <td valign="top">Yes</td>
                <td valign="top">Yes</td>
              </tr>
              <tr>
                <td valign="top"><strong>For nonaffiliates to market to you</strong></td>
                <td valign="top">Yes</td>
                <td valign="top">Yes</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td valign="top"><strong>To limit our sharing</strong></td>
                <td valign="top">
                  Mail the form below.
                  <br />
                  <strong>Please Note:</strong>
                  If you are a new customer, we can begin sharing your information thirty (30) days from the date we sent this notice. When you are no longer our customer, we continue to share your information as described in this notice. However, you can contact us at any time to limit our sharing.
                </td>
              </tr>
              <tr>
                <td valign="top"><strong>Questions?</strong></td>
                <td valign="top">
                  Call
                  {' '}
                  <a href="tel:8009446177">(800) 994-6177</a>
                  {' '}
                  or go to
                  {' '}
                  <a href="https://lendingusa.com">lendingusa.com</a>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td colSpan="3">
                  <h5>Mail in Form</h5>
                </td>
              </tr>
              <tr>
                <td>
                  Leave Blank
                  <br />
                  <strong>OR</strong>
                  If you have a joint account, your choice(s) will apply to everyone on your account unless you mark below.
                  <p />
                  <div>
                    <input name="" type="checkbox" />
                    {' '}
                    Apply my choices only to me.
                  </div>
                </td>
                <td colSpan="2">
                  Mark any/all you want to limit
                  <p />
                  <div>
                    <input name="" type="checkbox" />
                    {' '}
                    Do not share information about my creditworthiness with your affiliates for their everyday business purposes.
                  </div>
                  <div>
                    <input name="" type="checkbox" />
                    {' '}
                    Do not allow your affiliates to use my personal information to market to me.
                  </div>
                  <div>
                    <input name="" type="checkbox" />
                    {' '}
                    Do not share my personal information with nonaffiliates to market their products and services to me.
                  </div>
                </td>
              </tr>
              <tr>
                <td>Account Name</td>
                <td />
                <td rowSpan="5" valign="top">
                  <strong>Mail To:</strong>
                  <br />
                  LendingUSA
                  <br />
                  15021 Ventura Blvd #879
                  <br />
                  Sherman Oaks, CA 91403
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td />
              </tr>
              <tr>
                <td>Address</td>
                <td />
              </tr>
              <tr>
                <td>City, State</td>
                <td />
              </tr>
              <tr>
                <td>Zip</td>
                <td />
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  <h5>Who we are</h5>
                </td>
              </tr>
              <tr>
                <td valign="top" width="134"><strong>Who is providing this notice?</strong></td>
                <td valign="top" width="406">This notice is provided by LendingUSA, LLC and other affiliated companies</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  <h5>What we do</h5>
                </td>
              </tr>
              <tr>
                <td valign="top" width="134"><strong>How does LendingUSA, LLC protect my personal information?</strong></td>
                <td valign="top" width="406">To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.</td>
              </tr>
              <tr>
                <td valign="top" width="134">
                  <strong>
                    How does LendingUSA, LLC
                    <br />
                    collect my personal information?
                  </strong>
                </td>
                <td valign="top" width="406">
                  We collect your personal information, for example, when you
                  <p />
                  <ul>
                    <li>Open an account or apply for a loan</li>
                    <li>Give us your income information or provide employment information</li>
                    <li>Give us your contact information or make a payment</li>
                    <li>Give us personal information as part of a transaction or inquiry.</li>
                  </ul>
                </td>
              </tr>
              <tr style={{ backgroundColor: '#fff' }}>
                <td valign="top" width="134" />
                <td valign="top" width="406">We also collect your personal information from others, such as credit bureaus, affiliates or other companies.</td>
              </tr>
              <tr style={{ backgroundColor: '#f2f2f2' }}>
                <td valign="top" width="134"><strong>Why can’t I limit all sharing?</strong></td>
                <td valign="top" width="406">
                  Federal law gives you the right to limit only
                  <p />
                  <ul>
                    <li>sharing for affiliates’ everyday business purposes— information about your creditworthiness.</li>
                    <li>affiliates from using your information to market to you</li>
                    <li>sharing for nonaffiliates to market to you</li>
                  </ul>
                  <p>State laws and individual companies may give you additional rights to limit sharing. See below for more on your rights under state law</p>
                </td>
              </tr>
              <tr style={{ backgroundColor: '#fff' }}>
                <td valign="top" width="134"><strong>What happens when I limit sharing for an account I hold jointly with someone else?</strong></td>
                <td valign="top" width="406">Your choices will apply to everyone on your account – unless you tell us otherwise.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  <h5>Definitions</h5>
                </td>
              </tr>
              <tr>
                <td valign="top" width="134"><strong>Affiliates</strong></td>
                <td valign="top" width="406">Companies related by common ownership or control. They can be financial and nonfinancial companies.</td>
              </tr>
              <tr>
                <td valign="top" width="134"><strong>Nonaffiliates</strong></td>
                <td valign="top" width="406">Companies not related by common ownership or control. They can be financial and nonfinancial companies.</td>
              </tr>
              <tr>
                <td valign="top" width="134"><strong>Joint marketing</strong></td>
                <td valign="top" width="406">A formal agreement between nonaffiliated financial companies that together market financial products or services to you.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>
                  <h5>Other important information</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>For Vermont Residents:</strong>
                  {' '}
                  Under Vermont law, we will not share information we collect about Vermont residents with companies outside of our corporate family, unless the law allows. For example, we may share information with your consent, to service your accounts or under joint marketing agreements with other financial institutions with which we have joint marketing agreements. We will not share information about your creditworthiness within our corporate family except with your consent, but we may share information about our transactions or experiences with you within our corporate family without your consent.
                  <br />
                  <strong>For California and Virginia Residents:</strong>
                  {' '}
                  Under state law, we will not share information we collect about you with companies outside of LendingUSA unless the law allows. For example, we may share information with your consent or to service your account. We will limit sharing to the extent required by state law.
                  <strong>For Nevada Residents:</strong>
                  {' '}
                  We are providing you this notice pursuant to Nevada law. For more information, or to be placed on our internal Do Not Call List, call (800) 994-6177 or write us at 15021 Ventura Blvd #879 Sherman Oaks, CA 91403. You may also contact the Nevada Attorney General’s office: Bureau of Consumer Protection, Office of the Nevada Attorney General, 555 E. Washington Avenue, Ste. 3900, Las Vegas, NV 89101; telephone number: 702–486–3132; email BCPINFO@ag.state.nv.us.
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Cross River Bank Privacy Notice</h2>
          <p>The Cross River Bank Privacy Notice applies to all Cross River Bank customers:</p>
          <p>FACTS Rev. 11/2018</p>
          <p>What does Cross River Bank do with your personal information?</p>
          <table className="t1" cellSpacing="0" cellPadding="0">
            <tbody>
              <tr>
                <td className="td20" valign="top">
                  <p className="p1"><span className="s1"><strong>Why?</strong></span></p>
                </td>
                <td className="td20" valign="top">
                  <p className="p3"><span className="s1">Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share and protect your personal information. Please read this notice carefully to understand what we do.</span></p>
                </td>
              </tr>
              <tr>
                <td className="td21" valign="top">
                  <p className="p3"><span className="s1"><strong>What?</strong></span></p>
                </td>
                <td className="td21" valign="top">
                  <p className="p3"><span className="s1">The types of personal information we collect and share depend on the product or service you have with us. This information can include:</span></p>
                  <ul>
                    <li>Social Security number and Account balances</li>
                    <li>Payment history and Transaction history</li>
                    <li>Account transactions and Wire transfer instructions</li>
                  </ul>
                  <p className="p3"><span className="s1">When you are no longer our customer, we continue to share your information as described in this notice.</span></p>
                </td>
              </tr>
              <tr>
                <td className="td22" valign="top">
                  <p className="p3"><span className="s1"><strong>How?</strong></span></p>
                </td>
                <td className="td22" valign="top">
                  <p className="p3"><span className="s1">All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons Cross River Bank chooses to share; and whether you can limit this sharing.</span></p>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="t1" cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td className="td1" style={{ width: 'auto !important' }} valign="top">
                  <p className="p1"><span className="s1"><strong>Reasons we can share your personal information</strong></span></p>
                </td>
                <td className="td2" valign="top">
                  <p className="p3"><span className="s1"><strong>Does Cross River Bank share?</strong></span></p>
                </td>
                <td className="td1" valign="top">
                  <p className="p3"><span className="s1"><strong>Can you limit this sharing?</strong></span></p>
                </td>
              </tr>
              <tr>
                <td className="td3" style={{ width: 'auto !important' }} valign="top">
                  <p className="p3">
                    <span className="s1">
                      <strong>For our everyday business purposes –</strong>
                      {' '}
                      such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus
                    </span>
                  </p>
                </td>
                <td className="td4" valign="top">
                  <p className="p3"><span className="s1">Yes</span></p>
                </td>
                <td className="td3" valign="top">
                  <p className="p3"><span className="s1">No</span></p>
                </td>
              </tr>
              <tr>
                <td className="td5" style={{ width: 'auto !important' }} valign="top">
                  <p className="p3">
                    <span className="s1">
                      <strong>For our marketing purposes –</strong>
                      {' '}
                      To offer our products and services to you
                    </span>
                  </p>
                </td>
                <td className="td6" valign="top">
                  <p className="p3"><span className="s1">Yes</span></p>
                </td>
                <td className="td5" valign="top">
                  <p className="p3"><span className="s1">No</span></p>
                </td>
              </tr>
              <tr>
                <td className="td1" style={{ width: 'auto !important' }} valign="top">
                  <p className="p3"><span className="s1"><strong>For joint marketing with other financial companies</strong></span></p>
                </td>
                <td className="td2" valign="top">
                  <p className="p3"><span className="s1">Yes</span></p>
                </td>
                <td className="td1" valign="top">
                  <p className="p3"><span className="s1">No</span></p>
                </td>
              </tr>
              <tr>
                <td className="td7" style={{ width: 'auto !important' }} valign="top">
                  <p className="p3">
                    <span className="s1">
                      <strong>For our affiliates’ everyday business purposes</strong>
                      {' '}
                      – information about your transactions and experiences
                    </span>
                  </p>
                </td>
                <td className="td8" valign="top">
                  <p className="p3"><span className="s1">No</span></p>
                </td>
                <td className="td7" valign="top">
                  <p className="p3"><span className="s1">We don’t share</span></p>
                </td>
              </tr>
              <tr>
                <td className="td9" style={{ width: 'auto !important' }} valign="top">
                  <p className="p3">
                    <span className="s1">
                      {' '}
                      <strong>For our affiliates’ everyday business purposes – </strong>
                      {' '}
                      information about your creditworthiness
                    </span>
                  </p>
                </td>
                <td className="td10" valign="top">
                  <p className="p3"><span className="s1">No</span></p>
                </td>
                <td className="td9" valign="top">
                  <p className="p3"><span className="s1">We don’t share</span></p>
                </td>
              </tr>
              <tr>
                <td className="td11" style={{ width: 'auto !important' }} valign="top">
                  <p className="p3"><span className="s1"><strong>For our nonaffiliates to market to you</strong></span></p>
                </td>
                <td className="td12" valign="top">
                  <p className="p3"><span className="s1">No</span></p>
                </td>
                <td className="td11" valign="top">
                  <p className="p3"><span className="s1">We don’t share</span></p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Questions? Call Toll-Free 1-877-55CRB55 or go to
            {' '}
            <a href="http://www.crossriverbank.com">www.crossriverbank.com</a>
            .
          </p>
          <table className="t1" cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td className="td17" valign="top">
                  <h5>WHAT WE DO</h5>
                </td>
                <td className="td17" valign="top" />
              </tr>
              <tr>
                <td className="td23" valign="top">
                  <p className="p3"><span className="s1"><strong>How does Cross River Bank protect my personal information?</strong></span></p>
                </td>
                <td className="td23" valign="top">
                  <p className="p3"><span className="s1">To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.</span></p>
                  <p className="p3"><span className="s1">We also maintain other physical electronic and procedural safeguards to protect this information and we limit access to information to those employees for whom access is appropriate.</span></p>
                </td>
              </tr>
              <tr>
                <td className="td15" valign="top">
                  <p className="p3"><span className="s1"><strong>How does Cross River Bank collect my personal information?</strong></span></p>
                </td>
                <td className="td15" valign="top">
                  <p className="p3"><span className="s1">We collect your personal information, for example, when you:</span></p>
                  <ul>
                    <li>Open an account or apply for a loan</li>
                    <li>Make deposits or withdrawals from your account or provide employment information</li>
                    <li>Give us your contact information</li>
                  </ul>
                  <p>We also collect your personal information from others, such as credit bureaus, affiliates, or other companies.</p>
                </td>
              </tr>
              <tr>
                <td className="td24" valign="top">
                  <p className="p3"><span className="s1"><strong>Why can’t I limit all sharing?</strong></span></p>
                </td>
                <td className="td24" valign="top">
                  <p className="p3"><span className="s1">Federal law gives you the right to limit only:</span></p>
                  <ul>
                    <li>Sharing for affiliates’ everyday business purposes – information about your creditworthiness</li>
                    <li>Affiliates from using your information to market to you</li>
                    <li>Sharing for nonaffiliates to market to you</li>
                  </ul>
                  <p>State laws and individual companies may give you additional rights to limit sharing. See below for more on your rights under state law.</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Definitions</h2>
          <table className="t1" cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td className="td22" valign="top">
                  <p className="p3"><span className="s1"><strong>Affiliates</strong></span></p>
                </td>
                <td className="td22" valign="top">
                  <p className="p3"><span className="s1">Companies related by common ownership or control. They can be financial and non-financial companies.</span></p>
                  <ul>
                    <li>Cross River Bank does not share with our affiliates.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="td27" valign="top">
                  <p className="p3"><span className="s1"><strong>Nonaffiliates</strong></span></p>
                </td>
                <td className="td27" valign="top">
                  <p className="p3"><span className="s1">Companies not related by common ownership or control. They can be financial and non-financial companies.</span></p>
                  <ul>
                    <li>Nonaffiliates we share with can include loan finance companies.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="td19" valign="top">
                  <p className="p3"><span className="s1"><strong>Joint marketing</strong></span></p>
                </td>
                <td className="td19" valign="top">
                  <p className="p3"><span className="s1">A formal agreement between nonaffiliated financial companies that together market financial products or services to you.</span></p>
                  <ul>
                    <li>Our joint marketing partner(s) include loan finance companies.</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Other Important Information:</h2>
          <p>
            <strong>For Alaska, Illinois, Maryland and North Dakota Customers</strong>
            . We will not share personal information with nonaffiliates either for them to market to you or for joint marketing-without your authorization.
          </p>
          <p>
            <strong>For California Customers</strong>
            . We will not share personal information with nonaffiliates either for them to market to you or for joint marketing without your authorization. We will also limit our sharing of personal information about you with our affiliates to comply with all California privacy laws that apply to us.
          </p>
          <p>
            <strong>For Massachusetts, Mississippi and New Jersey Customers</strong>
            . We will not share personal information from deposit or share relationships with nonaffiliates either for them to market to you or for joint marketing-without your authorization.
          </p>
          <p>
            <strong>For Vermont Customers</strong>
            . We will not disclose information about your creditworthiness to our affiliates and will not disclose your personal information, financial information, credit report, or health information to nonaffiliated third parties to market to you, other than as permitted by Vermont law, unless you authorize us to make those disclosures. Additional information concerning our privacy policies can be found at www.crossriverbank.com or call 1-877-55CRB55.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PrivacyPolicy;
