import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import Input from 'components/Form/Input';

const EditUsername = (props) => {
  const [disabled, setDisabled] = useState(true);

  const handleValueChange = () => {
    setDisabled(false);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle}>Change Username</ModalHeader>
      <ModalBody>

        <Input
          label="Current Username"
          name="currentUsername"
          onChange={handleValueChange}
          isRequired
        />

        <Input
          label="New Username"
          name="newUsername"
          onChange={handleValueChange}
          isRequired
        />

        <Input
          type="password"
          label="Current Password"
          name="currentPassword"
          onChange={handleValueChange}
          isRequired
        />

        <Button color="primary" className="w-100 mt-1" disabled={disabled}>Change Username</Button>

      </ModalBody>
    </Modal>
  );
};

EditUsername.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default EditUsername;
