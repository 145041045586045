import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ width, className }) => (
  <svg
    width={width}
    height={width}
    className={className}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Shape</title>
    <desc>Created with Sketch.</desc>
    <g id="Corporate-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Merchant-Portal---Corporate---Merchant-Detail" transform="translate(-392.000000, -186.000000)" fill="#A5B0BB" fillRule="nonzero">
        <g id="Group-27" transform="translate(50.000000, 170.000000)">
          <g id="Group-36" transform="translate(25.000000, 0.000000)">
            <path d="M327.221443,19.2776196 L331.722029,23.7782052 L321.949198,33.5510362 L317.936568,33.9939933 C317.399395,34.0534059 316.945539,33.599199 317.005304,33.0620255 L317.451776,29.0465834 L327.221443,19.2776196 Z M334.505628,18.6075589 L332.392441,16.4943719 C331.733279,15.8352094 330.664205,15.8352094 330.005042,16.4943719 L328.017008,18.4824059 L332.517594,22.9829916 L334.505628,20.9949576 C335.164791,20.3354435 335.164791,19.2667214 334.505628,18.6075589 Z" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Edit.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

Edit.defaultProps = {
  width: 18,
  className: '',
};

export default Edit;
