import React from 'react';
import PropTypes from 'prop-types';

const Trash = ({ width, className }) => (
  <svg
    width={width}
    height={width * (18 / 16)}
    className={className}
    viewBox="0 0 16 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="trash" fill="#A5B0BB" fillRule="nonzero">
        <path d="M15.1874896,1.12466079 L10.9687425,1.12466079 L10.638274,0.467239362 C10.4954844,0.180555365 10.2026908,-0.000568148334 9.8824151,-0.000344639299 L5.86405848,-0.000344639299 C5.54438517,-0.00156732965 5.25214377,0.180059332 5.11171525,0.467239362 L4.78124672,1.12466079 L0.562499615,1.12466079 C0.251839656,1.12466079 0,1.37650044 0,1.6871604 L0,2.81215963 C0,3.12281959 0.251839656,3.37465925 0.562499615,3.37465925 L15.1874896,3.37465925 C15.4981496,3.37465925 15.7499892,3.12281959 15.7499892,2.81215963 L15.7499892,1.6871604 C15.7499892,1.37650044 15.4981496,1.12466079 15.1874896,1.12466079 Z M1.87031122,16.4176191 C1.92599517,17.3068206 2.6633511,17.9996585 3.55429444,17.9996585 L12.1956948,17.9996585 C13.0866381,17.9996585 13.823994,17.3068206 13.879678,16.4176191 L14.6249992,4.49965847 L1.12499923,4.49965847 L1.87031122,16.4176191 Z" id="Shape" />
      </g>
    </g>
  </svg>
);

Trash.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

Trash.defaultProps = {
  width: 16,
  className: '',
};

export default Trash;
