import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { capitalize, get } from 'lodash';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Button } from 'components/Button';
import Loader from 'components/Loader';
import {
  selectLoanId as selectLoanIdAction,
  fetchPaymentMethods as fetchPaymentMethodsAction,
  fetchLoanDetail as fetchLoanDetailAction,
  fetchTransactionsList as fetchTransactionsListAction,
  fetchPaymentsList as fetchPaymentsListAction,
  fetchDocuments as fetchDocumentsAction,
  fetchLoanTerm as fetchLoanTermAction,
  removePaymentMethod as removePaymentMethodAction,
  putPaymentAccount as putPaymentAccountAction,
  postPaymentAccount as postPaymentAccountAction,
  postDebitCardAccount as postDebitCardAccountAction,
  makePayment as makePaymentAction,
  fetchCurrentPayoffInfo as fetchCurrentPayoffInfoAction,
  fetchFuturePayoffInfo as fetchFuturePayoffInfoAction,
  fetchScheduledPayment as fetchScheduledPaymentAction,
  cancelAutopay as cancelAutopayAction,
  putScheduleAutopay as putScheduleAutopayAction,
  getBillingSetting as getBillingSettingAction,
  putBillingSetting as putBillingSettingAction,
  fetchAutopayInfo as fetchAutopayInfoAction,
  fetchAutopays,
  fetchLoanCharges,
  validateBankRoutingNumber as validateBankRoutingNumberAction,
} from 'actions/dashboard';

import PaymentModal from 'components/PaymentModal';
import AddPaymentModal from 'components/AddPaymentMethods';
import TermsModal from 'components/TermsModal';
import LoansAgreementModal from 'components/LoansAgreementModal';
import RecurringPaymentModal from 'components/RecurringPaymentModal';
import DisclosureModal from 'components/DisclosureModal';

import Edit from 'components/Icons/Edit';
import Trash from 'components/Icons/Trash';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPercentage } from 'utils/formatPercentage';
import { downloadCSV } from 'utils/csvExport';
import { appConfig } from 'config/appConfig';
import CancelPaymentModal from 'components/CancelPaymentModal';
import Download from 'components/Icons/Download';

import './styles.scss';

const ConsumerMyLoans = (props) => {
  const [modal, setModal] = useState(false);
  const [isAddOrEditPaymentModalVisible, setIsAddOrEditPaymentModalVisible] = useState(false);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [isLoanAgreementModal, setIsLoanAgreementModal] = useState(false);
  const [isRecurringPaymentModal, setIsRecurringPaymentModal] = useState(false);
  const [activeTab, setActiveTab] = useState(get(props, 'location.state.activeTab', 0));
  const [activePaymentTab, setActivePaymentTab] = useState('one-time');
  const [paymentModalType, setPaymentModalType] = useState('add');
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
  const [deleteConfirmationItem, setDeleteConfirmationItem] = useState(null);
  const [isRemovePaymentMethodModalVisible, setIsRemovePaymentMethodModalVisible] = useState(false);
  const [removePaymentMethodItem, setRemovePaymentMethodItem] = useState(null);
  const [isDisclosureModalVisible, setIsDisclosureModalVisible] = useState(false);
  const [scheduledPaymentDate, setScheduledPaymentDate] = useState('');
  const [isCancelPaymentModal, setIsCancelPaymentModal] = useState(false);

  const fetchTabsData = (tab) => {
    const {
      fetchTransactionsList,
      fetchPaymentsList,
      fetchScheduledPayment,
      loansList,
    } = props;

    switch (tab) {
      case 1:
        fetchPaymentsList(loansList.activeLoanId);
        break;
      case 2:
        fetchScheduledPayment(loansList.activeLoanId);
        break;
      default:
        fetchTransactionsList(loansList.activeLoanId);
    }
  };

  useEffect(() => {
    const {
      loansList,
      fetchLoanTerm,
      fetchLoanDetail,
      fetchPaymentMethods,
      getBillingSetting,
      fetchAutopayInfo,
    } = props;

    fetchLoanTerm(loansList.activeLoanId);
    fetchLoanDetail({
      loanId: loansList.activeLoanId,
    });
    fetchPaymentMethods(loansList.activeLoanId);
    getBillingSetting(loansList.activeLoanId);
    fetchAutopayInfo(loansList.activeLoanId);
    props.fetchLoanCharges(loansList.activeLoanId);

    fetchTabsData(activeTab);
  }, []);

  const getPaymentMethod = (item) => {
    const accountType = get(item, 'primaryPaymentMethod.type', 'paymentAccount.type.checking');
    if ((accountType === 'paymentAccount.type.checking' || accountType === 'paymentAccount.type.savings') && item.primaryPaymentMethod.checkingAccount.accountNumber) {
      const type = get(item, 'primaryPaymentMethod.checkingAccount.accountType');
      return (
        <>
          <strong>{capitalize(type.split('.')[2])}</strong>
          {` xxxxxx${get(item, 'primaryPaymentMethod.checkingAccount.accountNumber', '').slice(-4)}`}
        </>
      );
    }

    if ((accountType === 'paymentAccount.type.card' || accountType === 'paymentAccount.type.credit') && item.primaryPaymentMethod.creditCard.cardType && item.primaryPaymentMethod.creditCard.cardNumber) {
      return (
        <>
          <strong>{`${capitalize(get(item, 'primaryPaymentMethod.creditCard.cardData.type', ''))}`}</strong>
          {` xxxx-xxxx-xxxx-${get(item, 'primaryPaymentMethod.creditCard.cardNumber', '').slice(-4)}`}
        </>
      );
    }
  };

  const handleActiveTab = (tab) => {
    fetchTabsData(tab);
    setActiveTab(tab);
  };

  const handleCheck = (checked) => {
    const { putBillingSetting, loansList } = props;

    putBillingSetting({
      loanId: loansList.activeLoanId,
      data: { eBilling: checked },
    });
  };

  const handleModal = (newActivePaymentTab) => {
    const { loansList } = props;

    if (newActivePaymentTab === 'manageAutoPay') {
      setActiveTab(2);
      fetchTabsData(2);
    } else if (!props.paymentMethods.isLoading && !modal) {
      props.fetchLoanCharges(loansList.activeLoanId);
      props.fetchPaymentMethods({
        success: () => {
          setModal(!modal);
          setActivePaymentTab(newActivePaymentTab);
        },
        fail: (err) => {
          if (err.status === 404) {
            setModal(!modal);
            setActivePaymentTab(newActivePaymentTab);
          }
        },
      });
    } else {
      setModal(!modal);
      setActivePaymentTab(newActivePaymentTab);
    }
  };

  const handleTermsModal = () => {
    setIsTermsModalVisible(!isTermsModalVisible);
  };

  const handleLoanAgreementModal = () => {
    setIsLoanAgreementModal(!isLoanAgreementModal);
  };

  const handleRecurringModal = () => {
    setIsRecurringPaymentModal(!isRecurringPaymentModal);
  };

  const handleCancelPaymentModal = () => {
    setIsCancelPaymentModal(!isCancelPaymentModal);
  };

  const handleAddPaymentModal = () => {
    setIsAddOrEditPaymentModalVisible(!isAddOrEditPaymentModalVisible);
    setPaymentModalType('add');
    setCurrentPaymentMethod(null);
  };

  const handleEditPaymentModal = (item) => {
    if (!isAddOrEditPaymentModalVisible) {
      setCurrentPaymentMethod(item);
    }
    setIsAddOrEditPaymentModalVisible(!isAddOrEditPaymentModalVisible);
    setPaymentModalType('save');
  };

  const handleChange = (e) => {
    props.getBillingSetting(e.target.value);
    props.selectLoanId(e.target.value);
    props.fetchLoanDetail({ loanId: e.target.value });
    props.fetchPaymentsList(e.target.value);
    props.fetchPaymentMethods();
    props.fetchTransactionsList(e.target.value);
    props.fetchDocuments(e.target.value);
    props.fetchLoanTerm(e.target.value);
    props.fetchScheduledPayment(e.target.value);
    props.fetchAutopayInfo(e.target.value);
    props.fetchLoanCharges(e.target.value);
  };

  const toggleRemovingPaymentMethod = (item) => {
    setIsRemovePaymentMethodModalVisible(!isRemovePaymentMethodModalVisible);
    setRemovePaymentMethodItem(item);
  };

  const savePaymentAccount = (data, cb) => {
    if (data) {
      if (paymentModalType === 'add') {
        props.postPaymentAccount({
          data,
          success: (res) => {
            if (cb) { cb(true); }
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', 'Payment method added successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );
            props.fetchPaymentMethods();
            setIsAddOrEditPaymentModalVisible(false);
          },
          fail: (err) => {
            if (cb) { cb(); }
            if (err.status === 400) {
              toast.error(
                (
                  <div>
                    <h5>There was an error saving your payment account</h5>
                    <small>{get(err, 'data.message', 'Error occured')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
          },
        });
      } else {
        props.putPaymentAccount({
          data,
          success: (res) => {
            if (cb) { cb(true); }
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', 'Payment method edited successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );
            props.fetchPaymentMethods();
            setIsAddOrEditPaymentModalVisible(false);
          },
          fail: (err) => {
            if (cb) { cb(); }
            if (err.status === 400) {
              toast.error(
                (
                  <div>
                    <h5>There was an error editing your payment account</h5>
                    <small>{get(err, 'data.message', 'Error occured')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
          },
        });
      }
    }
  };

  const saveDebitCardToken = (data, cb) => {
    if (data) {
      if (paymentModalType === 'add') {
        props.postDebitCardAccount({
          data,
          success: (res) => {
            if (cb) { cb(true); }
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', 'Payment method added successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );
            props.fetchPaymentMethods();
            setIsAddOrEditPaymentModalVisible(false);
          },
          fail: (err) => {
            if (cb) { cb(); }
            if (err.status === 400 || err.status === 500) {
              toast.error(
                (
                  <div>
                    <h5>There was an error saving your payment account</h5>
                    <small>{get(err, 'data.message', 'Error occured')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
          },
        });
      } else {
        props.putPaymentAccount({
          data,
          success: (res) => {
            if (cb) { cb(true); }
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', 'Payment method edited successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );
            props.fetchPaymentMethods();
            setIsAddOrEditPaymentModalVisible(false);
          },
          fail: (err) => {
            if (cb) { cb(); }
            if (err.status === 400 || err.status === 500) {
              toast.error(
                (
                  <div>
                    <h5>There was an error editing your payment account</h5>
                    <small>{get(err, 'data.message', 'Error occured')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
          },
        });
      }
    }
  };

  const toggleDisclosureModal = (date = null) => {
    setIsDisclosureModalVisible(!isDisclosureModalVisible);
    if (date) {
      setScheduledPaymentDate(date);
    } else {
      setScheduledPaymentDate('');
    }
  };

  const putMakePayment = (data, cb) => {
    const {
      loansList,
      makePayment,
      fetchScheduledPayment,
      fetchTransactionsList,
      fetchPaymentsList,
      fetchLoanDetail,
    } = props;
    props.fetchAutopays({
      loanId: loansList.activeLoanId,
      success: (res1) => {
        makePayment({
          data,
          loanId: loansList.activeLoanId,
          success: (res) => {
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', 'Payment made successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );
            const autopayArr = res1.slice();

            if (autopayArr.length) {
              autopayArr.sort((a, b) => new Date(a.processDateTime) - new Date(b.processDateTime));
              const firstAutopayAfterToday = autopayArr.find((item) => (new Date(item.processDateTime) - new Date()) > 0 && `${get(item, 'recurringFrequency.id')}`.toLowerCase() !== 'autopay.recurringfrequency.single');
              if (firstAutopayAfterToday) {
                toggleDisclosureModal(get(firstAutopayAfterToday, 'processDateTime'));
              }
            }

            fetchScheduledPayment(loansList.activeLoanId);
            fetchTransactionsList(loansList.activeLoanId);
            fetchPaymentsList(loansList.activeLoanId);
            fetchLoanDetail({
              loanId: loansList.activeLoanId,
              delay: appConfig.delay,
            });
            props.fetchLoanCharges(loansList.activeLoanId);
            setModal(false);
            cb();
          },
          fail: (err) => {
            if (err.status === 400) {
              toast.error(
                (
                  <div>
                    <h5>There was an error processing your payment</h5>
                    <small>{get(err, 'data.message', 'Error occured!')}</small>
                  </div>
                ),
                { autoClose: true }
              );
            }
            cb();
          },
        });
      },
    });
  };

  const putScheduleAutopay = (data, cb) => {
    const { loansList } = props;
    props.fetchAutopays({
      loanId: loansList.activeLoanId,
      success: (res1) => {
        props.putScheduleAutopay({
          data,
          loanId: loansList.activeLoanId,
          success: (res) => { // eslint-disable-line
            toast.success(
              (
                <div>
                  <h5>{get(res, 'data.message', data.type === 'autopay.type.single' ? 'Payment scheduled successfully.' : 'AutoPay was scheduled successfully!')}</h5>
                </div>
              ),
              { autoClose: true }
            );

            const autopayArr = res1.slice();

            if (autopayArr.length && data.type === 'autopay.type.single') {
              autopayArr.sort((a, b) => new Date(a.processDateTime) - new Date(b.processDateTime));
              const firstAutopayAfterToday = autopayArr.find((item) => (new Date(item.processDateTime) - new Date()) > 0 && `${get(item, 'recurringFrequency.id')}`.toLowerCase() !== 'autopay.recurringfrequency.single');
              if (firstAutopayAfterToday) {
                toggleDisclosureModal(get(firstAutopayAfterToday, 'processDateTime'));
              }
            }

            setModal(false);
            props.fetchScheduledPayment(loansList.activeLoanId);
            props.fetchTransactionsList(loansList.activeLoanId);
            props.fetchPaymentsList(loansList.activeLoanId);
            props.fetchLoanDetail({
              loanId: loansList.activeLoanId,
              delay: appConfig.delay,
            });
            props.fetchAutopayInfo(loansList.activeLoanId);
            props.fetchLoanCharges(loansList.activeLoanId);
            cb();
          },
          fail: (err) => {
            toast.error(
              (
                <div>
                  <h5>There was an error scheduling autopay</h5>
                  <small>{get(err, 'data.message', 'Error occured!')}</small>
                </div>
              ),
              { autoClose: true }
            );
            cb();
          },
        });
      },
      fail: (err) => {
        toast.error(
          (
            <div>
              <h5>There was an error scheduling autopay</h5>
              <small>{get(err, 'data.message', 'Error occured!')}</small>
            </div>
          ),
          { autoClose: true }
        );
        cb();
      },
    });
  };

  const loanInfoBody = () => {
    const { loanTerm, loanDetail } = props;

    return (
      loanTerm.data && loanDetail.data
        ? (
          <Table className="mb-0" size="sm" responsive striped borderless>
            <tbody>
              <tr>
                <th scope="row">Principal Amount</th>
                <td className="text-right">
                  $
                  {formatCurrency(get(loanTerm, 'data.loanAmount', 0) + get(loanTerm, 'data.underwriting', 0), 2)}
                </td>
              </tr>
              <tr>
                <th scope="row">Contract Date</th>
                <td className="text-right">
                  {get(loanTerm, 'data.contractDate', '') ? moment(new Date(get(loanTerm, 'data.contractDate', ''))).format('MM/DD/YYYY').toString() : '-'}
                </td>
              </tr>
              <tr>
                <th scope="row">Loan Term</th>
                <td className="text-right">
                  {get(loanTerm, 'data.loanTerm', 0)}
                  {' '}
                  Months
                </td>
              </tr>
              <tr>
                <th scope="row">Interest Rate</th>
                <td className="text-right">{formatPercentage(get(loanTerm, 'data.loanRate', 0))}</td>
              </tr>
              <tr>
                <th scope="row">Monthly Payment Amount</th>
                <td className="text-right">
                  $
                  {formatCurrency(get(loanTerm, 'data.regularPayment', 0), 2)}
                </td>
              </tr>
              <tr>
                <th scope="row">Final Payment Date</th>
                <td className="text-right">{moment(new Date(get(loanDetail, 'data.0.finalPaymentDate', ''))).format('MM/DD/YYYY').toString()}</td>
              </tr>
            </tbody>
          </Table>
        )
        : <Loader className="height-2" />
    );
  };

  const handleDelete = (item) => {
    setIsDeleteConfirmationModalVisible(true);
    setDeleteConfirmationItem(item);
  };

  const handleCancelAutopay = (id) => {
    const {
      loansList,
      cancelAutopay,
      fetchScheduledPayment,
    } = props;

    cancelAutopay({
      data: {
        loanId: loansList.activeLoanId,
        id,
      },
      success: () => {
        fetchScheduledPayment(loansList.activeLoanId);
      },
    });
  };

  const handleScheduledModal = () => {
    setIsDeleteConfirmationModalVisible(!isDeleteConfirmationModalVisible);
  };

  const handleCSVExport = () => {
    const { transactionsList, paymentsList } = props;
    let dataToExport = [];
    let fileName = '';

    if (activeTab === 0) {
      dataToExport = (transactionsList?.data || []).map((item) => {
        const isPayment = item.type.id === 'payment' || item.type.id === 'credit' || item.type.id === 'advancement';
        return ({
          date: moment(new Date(item.date)).format('MM/DD/YYYY').toString(),
          description: item.type.label,
          principal: `$${formatCurrency(isPayment ? item.paymentPrincipal : item.chargePrincipal, 2)}`,
          interest: `$${formatCurrency(isPayment ? item.paymentInterest : item.chargeInterest, 2)}`,
          fees: `$${formatCurrency(isPayment ? item.paymentFees : item.chargeFees, 2)}`,
          total: `$${formatCurrency(isPayment ? item.paymentAmount : item.chargeAmount, 2)}`,
        });
      });
      fileName = 'transaction-history';
    } else if (activeTab === 1) {
      dataToExport = (paymentsList?.data || []).map((item) => ({
        date: moment(new Date(item.date)).format('MM/DD/YYYY').toString(),
        description: 'Payment Received',
        status: item.status.label,
        amount: `$${formatCurrency(item.amount, 2)}`,
      }));
      fileName = 'payment-history';
    }

    if (dataToExport.length > 0) {
      downloadCSV(dataToExport, fileName);
    }
  };

  const renderTabContent = (tabIndex) => {
    const { transactionsList, paymentsList, scheduledPaymentsList } = props;
    const transactions = get(transactionsList, 'data');
    const payments = get(paymentsList, 'data');
    const scheduledPayments = get(scheduledPaymentsList, 'data');

    switch (tabIndex) {
      case 1:
        return (
          payments === undefined
            ? <Loader className="height-2" />
            : (
              <Table className="mb-0" size="sm" responsive striped borderless>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  payments.length > 0 ? payments.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{moment(new Date(item.date)).format('MM/DD/YYYY').toString()}</th>
                      <td>Payment Received</td>
                      <td>{item.status.label}</td>
                      <td className="text-right">
                        $
                        {formatCurrency(item.amount, 2)}
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan={4} align="center" className="pt-3 pb-3">No data</td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            )
        );
      case 2:
        return (
          scheduledPayments === undefined
            ? <Loader className="height-2" />
            : (
              <Table className="mb-0" size="sm" responsive striped borderless>
                <thead>
                  <tr>
                    <th>Next Payment Date</th>
                    <th>Payments Remaining</th>
                    <th>Frequency</th>
                    <th className="text-right">Amount</th>
                    <th>Payment Method</th>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                  scheduledPayments.length > 0 ? scheduledPayments.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{moment(new Date(item.processDateTime)).format('MM/DD/YYYY').toString()}</th>
                      <td>{item.recurringPeriods}</td>
                      <td>{item.recurringFrequency.label}</td>
                      <td className="text-right">
                        $
                        {formatCurrency(item.amount, 2)}
                      </td>
                      <td>
                        {getPaymentMethod(item)}
                      </td>
                      <td>
                        {item.recurringFrequency.label === 'Single' && (
                          <div style={{ cursor: 'pointer' }} onClick={handleDelete.bind(null, item)}>
                            <Trash />
                          </div>
                        )}
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan={6} align="center" className="pt-3 pb-3">No data</td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            )
        );
      default:
        return (
          transactions === undefined
            ? <Loader className="height-2" />
            : (
              <Table className="mb-0" size="sm" responsive striped borderless>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th className="text-right">Principal</th>
                    <th className="text-right">Interest</th>
                    <th className="text-right">Fees</th>
                    <th className="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  transactions.length > 0 ? transactions.map((item, index) => {
                    if (item.type.id === 'payment' || item.type.id === 'credit' || item.type.id === 'advancement') {
                      return (
                        <tr key={index}>
                          <th scope="row">{moment(new Date(item.date)).format('MM/DD/YYYY').toString()}</th>
                          <td>{item.type.label}</td>
                          <td className="text-right">
                            $
                            {formatCurrency(item.paymentPrincipal, 2)}
                          </td>
                          <td className="text-right">
                            $
                            {formatCurrency(item.paymentInterest, 2)}
                          </td>
                          <td className="text-right">
                            $
                            {formatCurrency(item.paymentFees, 2)}
                          </td>
                          <td className="text-right">
                            <strong>
                              $
                              {formatCurrency(item.paymentAmount, 2)}
                            </strong>
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={index}>
                        <th scope="row">{moment(new Date(item.date)).format('MM/DD/YYYY').toString()}</th>
                        <td>{item.type.label}</td>
                        <td className="text-right">
                          $
                          {formatCurrency(item.chargePrincipal, 2)}
                        </td>
                        <td className="text-right">
                          $
                          {formatCurrency(item.chargeInterest, 2)}
                        </td>
                        <td className="text-right">
                          $
                          {formatCurrency(item.chargeFees, 2)}
                        </td>
                        <td className="text-right">
                          <strong>
                            $
                            {formatCurrency(item.chargeAmount, 2)}
                          </strong>
                        </td>
                      </tr>
                    );
                  }) : (
                    <tr>
                      <td colSpan={6} align="center" className="pt-3 pb-3">No data</td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            )
        );
    }
  };

  const renderDeleteConfirmationModal = () => (deleteConfirmationItem && deleteConfirmationItem.recurringFrequency.id === 'autopay.recurringFrequency.monthly' ? (
    <Modal isOpen={isDeleteConfirmationModalVisible} toggle={handleScheduledModal} size="md">
      <ModalHeader toggle={handleScheduledModal}>Turn Off Autopay</ModalHeader>
      <ModalBody className="text-center">
        <p>If you turn off AutoPay, all future payments will be canceled.</p>
        <h4 className="mb-3">Are you sure you want to turn off AutoPay?</h4>
        <Button
          onClick={() => {
            handleCancelAutopay(deleteConfirmationItem.id);
            handleScheduledModal();
          }}
          color="danger"
          className="w-100 mb-1"
        >
          Turn Off Autopay
        </Button>
        <Button onClick={handleScheduledModal} className="w-100">Go back</Button>
      </ModalBody>
    </Modal>
  ) : (
    <Modal isOpen={isDeleteConfirmationModalVisible} toggle={handleScheduledModal} size="md">
      <ModalHeader toggle={handleScheduledModal}>Cancel scheduled payment</ModalHeader>
      <ModalBody className="text-center">
        <h4 className="mb-3">Are you sure you want to cancel this payment?</h4>
        <Button
          onClick={() => {
            handleCancelAutopay(deleteConfirmationItem.id);
            handleScheduledModal();
          }}
          color="danger"
          className="w-100 mb-1"
        >
          CANCEL THIS SCHEDULED PAYMENT
        </Button>
        <Button onClick={handleScheduledModal} className="w-100">Go back</Button>
      </ModalBody>
    </Modal>
  ));

  const renderPaymentType = (item) => (typeof item.type === 'string' ? item.type : get(item, 'type.2') || '');

  const {
    loanDetail,
    billingSetting,
    autopay,
    paymentMethods,
    loansList,
    removePaymentMethod,
    loanCharges,
    channel,
    maxLateFee,
  } = props;

  const pastDue = Number(get(loanDetail, 'data.0.daysPastDue', 0));
  const nextPaymentAmount = get(loanDetail, 'data.0.nextPaymentAmount') && formatCurrency(get(loanDetail, 'data.0.nextPaymentAmount'), 2).split('.');
  const pastDueAmount = formatCurrency(get(loanDetail, 'data.0.amountDue'), 2).split('.');
  const dueFees = Number(get(loanCharges, 'data.unpaidCharges'));
  const nextPaymentDate = get(loanDetail, 'data.0.nextPaymentDate', '') ? moment(new Date(get(loanDetail, 'data.0.nextPaymentDate', ''))).format('MM/DD/YYYY').toString() : '-';
  const totalDueAmount = get(loanDetail, 'data.0.totalDue');
  const activeloanId = loansList.activeLoanId;
  const allpaymentmethods = [];

  paymentMethods.data && paymentMethods.data.forEach((item) => {
    const accountType = get(item, 'type', 'paymentAccount.type.checking');
    if (accountType === 'paymentAccount.type.checking' && item.checkingAccount.bankName && item.checkingAccount.accountNumber) {
      allpaymentmethods.push({
        accountName: item.checkingAccount.bankName,
        accountNumber: item.checkingAccount.accountNumber,
        routingNumber: item.checkingAccount.routingNumber,
        accountholderName: item.checkingAccount.cardHolderName,
        paymentAccountId: item.id,
        type: item.checkingAccount.accountType.split('.'),
        token: item.checkingAccount.token,
        isPrimary: item.isPrimary,
        address: item.checkingAccount.address,
      });
    }

    if ((accountType === 'paymentAccount.type.card' || accountType === 'paymentAccount.type.credit') && item.creditCard.cardType && item.creditCard.cardNumber) {
      allpaymentmethods.push({
        accountName: item.creditCard.cardData.issuer,
        accountNumber: item.creditCard.cardNumber,
        accountholderName: item.creditCard.cardHolderName,
        paymentAccountId: item.id,
        type: item.creditCard.cardData.type,
        token: item.creditCard.token,
        isPrimary: item.isPrimary,
        brand: item.creditCard.cardData.brand,
        address: {
          city: item.creditCard.city,
          state: item.creditCard.state,
          address: item.creditCard.address1,
          zipCode: item.creditCard.zipCode,
        },
        cardExpiration: item.creditCard.cardExpiration,
      });
    }
  });

  let primaryPaymentMethod = '';
  if (paymentMethods.data) {
    primaryPaymentMethod = paymentMethods.data.find((item) => item.isPrimary);

    if (primaryPaymentMethod && primaryPaymentMethod.type === 'paymentAccount.type.checking' && primaryPaymentMethod.checkingAccount.bankName && primaryPaymentMethod.checkingAccount.accountNumber) {
      primaryPaymentMethod = `${primaryPaymentMethod.id}-${primaryPaymentMethod.checkingAccount.accountType.split('.')[2]}`;
    }

    if (primaryPaymentMethod && (primaryPaymentMethod.type === 'paymentAccount.type.card' || primaryPaymentMethod.type === 'paymentAccount.type.credit') && primaryPaymentMethod.creditCard.cardType && primaryPaymentMethod.creditCard.cardNumber) {
      primaryPaymentMethod = `${primaryPaymentMethod.id}-${primaryPaymentMethod.creditCard.cardData.type.toLowerCase()}`;
    }
  }

  const isAutoPayEnabled = get(autopay, 'data.isAutoPayEnabled', false);

  return (
    <div className="page-myloans pb-3">
      <Container fluid>
        <Row className="mb-3 align-items-center">
          <Col className="text-center text-md-left">
            <h3 className="mb-0">Loan Details</h3>
          </Col>
          <Col xs={12} md="auto" className="text-right justify-content-end ml-md-3 mt-3 mt-md-0">
            <FormGroup className="dropdown-toggle mb-0 input-inline pb-0">
              <Input
                type="select"
                name="select"
                id="loanSelection"
                onChange={handleChange}
                value={activeloanId}
                style={{
                  padding: '.675rem 1.125rem',
                  height: 'auto',
                  paddingRight: '45px',
                }}
              >
                <option disabled>Select</option>
                {
                  loansList.data && loansList.data.map((item, index) => (
                    <option value={item.id} key={`loanlist_${index}`}>{item.displayId}</option>
                  ))
                }
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={4}>

            <Card inverse color="primary">
              <CardBody className="text-center">
                <Row>
                  <Col className="pr-0">
                    <Card className="pt-2 pb-2" style={{ height: '110px' }}>
                      {
                        loanDetail.data ? (
                          <>
                            <h6>Next Payment</h6>
                            {nextPaymentAmount ? (
                              <h3>
                                $
                                {nextPaymentAmount[0]}
                                .
                                <small>{nextPaymentAmount[1]}</small>
                              </h3>
                            ) : <h3>-</h3>}
                            <h6 className="mb-0">
                              <span>
                                Due by
                                {' '}
                                {loanDetail.data ? nextPaymentDate : '-'}
                              </span>
                            </h6>
                          </>
                        ) : (
                          <Loader className="light height-100" />
                        )
                      }
                    </Card>
                  </Col>
                  <Col>
                    <Card className={`pt-2 pb-2 ${pastDue > 0 ? 'bg-white' : ''}`} style={{ height: '110px' }}>
                      {
                        loanDetail.data ? (
                          <>
                            <h6 className={pastDue > 0 ? 'text-danger' : ''}>Past Due</h6>
                            <h3 className={`${pastDue > 0 ? 'text-danger' : ''}`}>
                              $
                              {pastDueAmount[0]}
                              .
                              <small className={`${pastDue > 0 ? 'text-danger' : ''}`}>{pastDueAmount[1]}</small>
                            </h3>
                            <h6 className={`mb-0 ${pastDue > 0 ? 'text-danger' : ''}`}>
                              {pastDue > 0 ? 'DUE NOW' : 'UP TO DATE'}
                            </h6>
                          </>
                        ) : (
                          <Loader className="light height-100" />
                        )
                      }
                    </Card>
                  </Col>
                </Row>
                {!isAutoPayEnabled && (
                  <Button color="light" outline className="w-100 mb-1" onClick={handleModal.bind(null, 'autopay')} isLoading={autopay.isLoading}>
                    <img src="/icons/autopay.svg" alt="" className="mr-1" />
                    Set Up Autopay
                  </Button>
                )}
                <Button color="light" className="w-100" onClick={handleModal.bind(null, 'one-time')}>
                  <img src="/icons/payment.svg" alt="" className="mr-1" />
                  Make A Payment
                </Button>
                <Row className="mt-3">
                  <Col className="text-left">
                    <strong>Paperless Billing</strong>
                  </Col>
                  <Col className="text-right">
                    <small className="mr-1"><strong>{ billingSetting ? 'ON' : 'OFF' }</strong></small>
                    <Switch
                      onChange={handleCheck}
                      checked={billingSetting}
                      className="toggle-switch float-right"
                      onColor="#04BC6C"
                      offColor="#3989E3"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>Payment Methods</CardHeader>
              {
                paymentMethods.isLoading
                  ? (
                    <ListGroup>
                      <ListGroupItem>
                        <Loader className="height-2" />
                      </ListGroupItem>
                    </ListGroup>
                  )
                  : (
                    <ListGroup style={{ margin: 0 }}>
                      { paymentMethods && paymentMethods.data && paymentMethods.data.length > 0
                        ? allpaymentmethods.map((item, index) => (
                          <ListGroupItem key={index} className="position-relative">
                            <div style={{ paddingRight: 70, position: 'relative' }}>
                              <p className="mb-0">
                                <strong>{capitalize(renderPaymentType(item))}</strong>
                                {['debit', 'credit'].indexOf(renderPaymentType(item).toLowerCase()) !== -1 ? ' xxxx-xxxx-xxxx-' : ' xxxxxx'}
                                {item.accountNumber.slice(-4)}
                              </p>
                              <small className="text-capitalize">
                                {item.brand || item.accountName}
                                {' '}
&nbsp;
                                {item.isPrimary && <Badge color="primary">PRIMARY</Badge>}
                              </small>
                              <Button color="link" className="btn-icon position-absolute" style={{ top: 0, right: 35 }} onClick={handleEditPaymentModal.bind(null, item)}>
                                <Edit />
                              </Button>
                              <Button color="link" className="btn-icon position-absolute" style={{ top: 0, right: 0 }} onClick={toggleRemovingPaymentMethod.bind(null, item)}>
                                <Trash />
                              </Button>
                            </div>
                          </ListGroupItem>
                        )) : (
                          <div className="noPaymentMethodsData">
                            <p className="mb-0">No payment method yet.</p>
                          </div>
                        )}
                      <ListGroupItem tag="a" onClick={handleAddPaymentModal} className="d-flex">
                        <img src="/icons/add-primary.svg" alt="Add" className="mr-1" />
                        <strong>Add Payment Method</strong>
                      </ListGroupItem>
                    </ListGroup>
                  )
              }
            </Card>

            <Card>
              <CardHeader>Loan Info</CardHeader>
              {loanInfoBody()}
            </Card>

          </Col>
          <Col md={6} lg={8}>
            <Card>
              <Nav className="LoansNav" tabs>
                {(activeTab === 0 || activeTab === 1) && !(props.transactionsList?.isLoading || props.paymentsList?.isLoading) ? (
                  <div className="downloadBtn" onClick={handleCSVExport}>
                    <Download />
                    <div className="tooltipText">Download as CSV</div>
                  </div>
                ) : null}
                <NavItem>
                  <NavLink className={`${activeTab === 0 && 'active'}`} onClick={handleActiveTab.bind(null, 0)}>
                    Transactions
                    <span> History</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={`${activeTab === 1 && 'active'}`} onClick={handleActiveTab.bind(null, 1)}>
                    Payments
                    <span> History</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={`${activeTab === 2 && 'active'}`} onClick={handleActiveTab.bind(null, 2)}>
                    Scheduled
                    <span> Payments</span>
                  </NavLink>
                </NavItem>
              </Nav>
              {renderTabContent(activeTab)}
            </Card>

          </Col>
        </Row>

      </Container>

      <PaymentModal
        isOpen={modal}
        toggle={handleModal}
        nextPaymentAmount={nextPaymentAmount}
        nextPaymentDate={nextPaymentDate}
        pastDueAmount={pastDueAmount}
        dueFees={dueFees}
        totalDueAmount={totalDueAmount}
        paymentMethods={allpaymentmethods}
        putMakePayment={putMakePayment}
        putScheduleAutopay={putScheduleAutopay}
        toggleTermsModal={handleTermsModal}
        toggleLoanAgreementModal={handleLoanAgreementModal}
        toggleRecurringModal={handleRecurringModal}
        activeTab={activePaymentTab}
        primaryPaymentMethod={primaryPaymentMethod}
        fetchCurrentPayoffInfo={props.fetchCurrentPayoffInfo}
        fetchFuturePayoffInfo={props.fetchFuturePayoffInfo}
        addPaymentMethod={handleAddPaymentModal}
        toggleCancelPaymentModal={handleCancelPaymentModal}
        channel={channel}
      />

      <AddPaymentModal
        isOpen={isAddOrEditPaymentModalVisible}
        toggle={paymentModalType === 'add' ? handleAddPaymentModal : handleEditPaymentModal}
        saveToken={savePaymentAccount}
        saveDebitCardToken={saveDebitCardToken}
        paymentModalType={paymentModalType}
        currentPaymentMethod={currentPaymentMethod}
        validateBankRoutingNumber={props.validateBankRoutingNumber}
      />

      <DisclosureModal
        isOpen={isDisclosureModalVisible}
        toggle={toggleDisclosureModal}
        scheduledPaymentDate={scheduledPaymentDate}
      />

      <TermsModal
        isOpen={isTermsModalVisible}
        toggle={handleTermsModal}
        channel={channel}
        fee={maxLateFee}
      />

      <LoansAgreementModal
        isOpen={isLoanAgreementModal}
        toggle={handleLoanAgreementModal}
      />

      <RecurringPaymentModal
        isOpen={isRecurringPaymentModal}
        toggle={handleRecurringModal}
      />

      <CancelPaymentModal
        isOpen={isCancelPaymentModal}
        toggle={handleCancelPaymentModal}
      />

      {renderDeleteConfirmationModal()}

      <Modal isOpen={isRemovePaymentMethodModalVisible} toggle={toggleRemovingPaymentMethod} size="md">
        <ModalHeader toggle={toggleRemovingPaymentMethod}>Delete Payment Method</ModalHeader>
        <ModalBody className="text-center">
          <h4 className="mb-3">Are you sure you want to remove this payment method?</h4>
          <Button
            onClick={() => {
              removePaymentMethod({
                paymentAccountId: removePaymentMethodItem.paymentAccountId,
                success: () => {
                  toast.success(
                    (
                      <div>
                        <h5>Payment method removed successfully.</h5>
                      </div>
                    ),
                    { autoClose: true }
                  );
                  toggleRemovingPaymentMethod();
                  props.fetchPaymentMethods();
                },
                fail: (err) => {
                  toast.error(
                    (
                      <div>
                        <h5>{get(err, 'data.message', 'Failed to remove payment method')}</h5>
                      </div>
                    ),
                    { autoClose: true }
                  );
                },
              });
            }}
            color="danger"
            className="w-100 mb-1"
          >
            Delete Payment Method
          </Button>
          <Button onClick={toggleRemovingPaymentMethod} className="w-100">Go back</Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

ConsumerMyLoans.propTypes = {
  fetchCurrentPayoffInfo: PropTypes.func.isRequired,
  fetchFuturePayoffInfo: PropTypes.func.isRequired,
  fetchPaymentsList: PropTypes.func.isRequired,
  fetchAutopayInfo: PropTypes.func.isRequired,
  fetchLoanTerm: PropTypes.func.isRequired,
  fetchLoanDetail: PropTypes.func.isRequired,
  selectLoanId: PropTypes.func.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  fetchTransactionsList: PropTypes.func.isRequired,
  fetchScheduledPayment: PropTypes.func.isRequired,
  putScheduleAutopay: PropTypes.func.isRequired,
  makePayment: PropTypes.func.isRequired,
  putPaymentAccount: PropTypes.func.isRequired,
  postPaymentAccount: PropTypes.func.isRequired,
  postDebitCardAccount: PropTypes.func.isRequired,
  removePaymentMethod: PropTypes.func.isRequired,
  fetchDocuments: PropTypes.func.isRequired,
  cancelAutopay: PropTypes.func.isRequired,
  getBillingSetting: PropTypes.func.isRequired,
  putBillingSetting: PropTypes.func.isRequired,
  fetchAutopays: PropTypes.func.isRequired,
  fetchLoanCharges: PropTypes.func.isRequired,
  scheduledPaymentsList: PropTypes.object.isRequired,
  loansList: PropTypes.object.isRequired,
  loanDetail: PropTypes.object.isRequired,
  loanTerm: PropTypes.object.isRequired,
  paymentsList: PropTypes.object.isRequired,
  transactionsList: PropTypes.object.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  billingSetting: PropTypes.bool.isRequired,
  autopay: PropTypes.object.isRequired,
  loanCharges: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired,
  maxLateFee: PropTypes.string.isRequired,
  validateBankRoutingNumber: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loansList: state.dashboard.loansList,
  loanDetail: state.dashboard.loanDetail,
  loanTerm: state.dashboard.loanTerm,
  transactionsList: state.dashboard.transactionsList,
  paymentsList: state.dashboard.paymentsList,
  paymentMethods: state.dashboard.paymentMethods,
  putPaymentMethod: state.dashboard.putPaymentMethod,
  makePaymentMessage: state.dashboard.makePaymentMessage,
  remove: state.dashboard.remove,
  scheduledPaymentsList: state.dashboard.scheduledPaymentsList,
  billingSetting: state.dashboard.billingSetting.data ? state.dashboard.billingSetting.data.eBilling : false,
  channel: state.dashboard.billingSetting.data && state.dashboard.billingSetting.data.customFields ? state.dashboard.billingSetting.data.customFields.channel : '',
  autopay: state.dashboard.autopay,
  loanCharges: state.dashboard.loanCharges,
  maxLateFee: state.dashboard.billingSetting && state.dashboard.billingSetting.data && state.dashboard.billingSetting.data.customFields ? state.dashboard.billingSetting.data.customFields.maxLateFee : null,
});

const mapDispatchToProps = {
  selectLoanId: selectLoanIdAction,
  fetchPaymentMethods: fetchPaymentMethodsAction,
  fetchLoanDetail: fetchLoanDetailAction,
  fetchTransactionsList: fetchTransactionsListAction,
  fetchPaymentsList: fetchPaymentsListAction,
  fetchDocuments: fetchDocumentsAction,
  fetchLoanTerm: fetchLoanTermAction,
  removePaymentMethod: removePaymentMethodAction,
  putPaymentAccount: putPaymentAccountAction,
  postPaymentAccount: postPaymentAccountAction,
  postDebitCardAccount: postDebitCardAccountAction,
  makePayment: makePaymentAction,
  fetchCurrentPayoffInfo: fetchCurrentPayoffInfoAction,
  fetchFuturePayoffInfo: fetchFuturePayoffInfoAction,
  fetchScheduledPayment: fetchScheduledPaymentAction,
  cancelAutopay: cancelAutopayAction,
  putScheduleAutopay: putScheduleAutopayAction,
  getBillingSetting: getBillingSettingAction,
  putBillingSetting: putBillingSettingAction,
  fetchAutopayInfo: fetchAutopayInfoAction,
  fetchAutopays,
  fetchLoanCharges,
  validateBankRoutingNumber: validateBankRoutingNumberAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConsumerMyLoans);
