import { removeSpace, removeUnderline } from 'utils/masks';

const addPaymentMethodSchema = {
  accountType: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  bankName: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  routingNumber: [{
    rule: 'required',
    isValid: (input, err, opt, formData) => {
      if (!input) {
        return 'The input field is required';
      }

      if (`${input}`.replace('_', '').length < 9 || !formData?.isValidRoutingNumber) {
        return 'Routing Number is not valid';
      }

      return /^(\d)\1+$/.test(input) ? 'Routing Number is not valid' : true;
    },
  }],
  accountNumber: [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return 'The input field is required';
      }

      return /^(\d)\1+$/.test(input) ? 'Account Number is not valid' : true;
    },
  }],
  accountholderName: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }

      if (input.trim().split(' ').length < 2) {
        return 'Full name is required';
      }

      return true;
    },
  }],
  address: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  city: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  state: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  zipcode: [{
    rule: 'required',
    error: 'The input field is required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }

      if (removeUnderline(input).length !== 5) {
        return 'Invalid zipcode';
      }
      return true;
    },
  }],
};

export default addPaymentMethodSchema;
