import { validateEmail } from 'utils/helper';
import { numberUnmask } from 'utils/masks';

const registrationSchema = {
  ssn: [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return 'The input field is required';
      }

      if (numberUnmask(input).length < 9) {
        return 'SSN is not valid';
      }

      const blockingSsns = [
        '078-05-1120',
        '219-09-9999',
        '111-11-1111',
        '222-22-2222',
        '333-33-3333',
        '444-44-4444',
        '555-55-5555',
        '666-66-6666',
        '777-77-7777',
        '888-88-8888',
        '999-99-9999',
        '123-45-6789',
      ];

      if (
        numberUnmask(input).substring(0, 3) === '000'
        || numberUnmask(input).substring(3, 5) === '00'
        || numberUnmask(input).substring(5, 9) === '0000'
        || (Number(numberUnmask(input).substring(0, 3)) > 899 && Number(numberUnmask(input).substring(0, 3)) < 1000)
        || blockingSsns.includes(input)

      ) {
        return 'SSN is not valid';
      }
    },
  }],
  email: [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return 'The input field is required';
      }

      const blokingEmails = [
        'none@none.com',
        'noemail@noemail.com',
        'noemail@gmail.com',
        'Noemail@email.com',
        'NONE@NOEMAIL.COM',
        'none@gmail.com',
        'none@yahoo.com',
        'no@no.com',
        'no@email.com',
        'noemail@yahoo.com',
        'test@test.com',
        'NA@NA.COM',
        'noname@gmail.com',
        'no@gmail.com',
        'noemail@mail.com',
        'test@email.com',
        'unknown@yahoo.com',
        'non@non.com',
        'NOEMAL@NOEMAIL.COM',
        'none@email.com',
        'noname@email.com',
        'noemail@no.com',
        'NON@NONE.COM',
        'EMAIL@EMAIL.COM',
        'nomail@gmail.com',
        'NOEMAIL@GIMAIL.COM',
        'NOEMAIL@AOL.COM',
        'na@gmail.com',
        'noemail@gmai.com',
        'Noemail@noemai.com',
        'none@aol.com',
      ];

      return (
        (blokingEmails.indexOf(input) !== -1)
        || !validateEmail(input)
      ) ? 'Email is not valid' : true;
    },
  }],
};

export default registrationSchema;
