import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

export const VerifyButton = ({ onClick, isVerified, isLoading }) => {
  const [buttonHovered, setButtonHovered] = useState(false);

  const handleMouseEnter = () => {
    setButtonHovered(true);
  };

  const handleMouseLeave = () => {
    setButtonHovered(false);
  };

  return isVerified
    ? (
      <div
        className={cn('smarty-tag-check', 'smarty-tag-green')}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        ✓
        <span className="verifyText">
          { buttonHovered ? 'Undo' : 'Verified' }
        </span>
      </div>
    ) : (
      <>
        {
          isLoading && (
            <div className="smarty-ui-loading">
              <div title="Loading..." className={cn('smarty-dots', 'smarty-addr-29102')} />
            </div>
          )
        }
        <div className={cn('smarty-tag-check')} onClick={onClick}>
          ✓
          <span className="verifyText">Verify Address</span>
        </div>
      </>
    );
};

VerifyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isVerified: PropTypes.bool,
  isLoading: PropTypes.bool,
};

VerifyButton.defaultProps = {
  isVerified: false,
  isLoading: true,
};

export default VerifyButton;
