import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const RecurringPaymentModal = ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader className="no-border" toggle={toggle}>How will my payments be applied?</ModalHeader>
    <ModalBody className="pt-3 recurring-description">
      <p>Payments are applied to your account according to the terms of your Loan Agreement. Your Loan Agreement is located in the ‘Documents’ section of this Portal.</p>
      <p>All times are Pacific Standard Time.</p>
    </ModalBody>
  </Modal>
);

RecurringPaymentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default RecurringPaymentModal;
