const phoneTypes = {
  'customer.phoneType.cell': 'Mobile',
  'customer.phoneType.cellSmartPhone': 'Mobile',
  'customer.phoneType.home': 'Home',
  'customer.phoneType.office': 'Work',
  'customer.phoneType.fax': 'Fax',
  'customer.phoneType.other': 'Other',
};

export default phoneTypes;
