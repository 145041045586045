import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Button } from 'components/Button';

const PromoPayoffModal = ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen}>
    <ModalHeader className="payoff-modal-header no-border pl-0 ml-0">Thank you for checking in!</ModalHeader>
    <ModalBody className="pt-0">
      <p style={{ fontSize: 14, color: 'black' }}>
        Your account is currently pending payoff. Your promo credit will be applied, and your account will be closed 7 days after we receive your payoff payment, subject to the final clearing of all recent payments.
      </p>
      <div className="d-flex justify-content-center">
        <Button color="primary" onClick={toggle} size="sm">
          Close
        </Button>
      </div>
    </ModalBody>
  </Modal>
);

PromoPayoffModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default PromoPayoffModal;
