import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';

import { fetchCustomerDetail } from 'actions/dashboard';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import EditEmail from './EditEmail';
import EditPhoneHome from './EditPhoneHome';
import EditPhoneMobile from './EditPhoneMobile';
import EditPhoneWork from './EditPhoneWork';
import EditMailingAddress from './EditMailingAddress';
import EditPassword from './EditPassword';
import EditPhysicalAddress from './EditPhysicalAddress';
import EditUsername from './EditUsername';

import phoneTypes from './phoneTypes';

const ConsumerSettings = (props) => {
  const [modalEmail, setModalEmail] = useState(false);
  const [modalHome, setModalHome] = useState(false);
  const [modalMobile, setModalMobile] = useState(false);
  const [modalMailingAddress, setModalMailingAddress] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [modalPhysicalAddress, setModalPhysicalAddress] = useState(false);
  const [modalUsername, setModalUsername] = useState(false);
  const [modalWork, setModalWork] = useState(false);
  const [username, setUsername] = useState('');
  const [mailAddress, setMailAddress] = useState([]);
  const [primaryAddress, setPrimaryAddress] = useState([]);
  const [phones, setPhones] = useState([]);
  const [email, setEmail] = useState('');

  useEffect(() => {
    props.fetchCustomerDetail({
      success: (customerDetail) => {
        const { accessUserName, mailAddress: newMailAddress, primaryAddress: newPrimaryAddress, email: newEmail, phones: allPhones } = customerDetail;
        const newPhones = sortBy(allPhones, (item) => !item.isPrimary);
        setUsername(accessUserName);
        setMailAddress(newMailAddress);
        setPrimaryAddress(newPrimaryAddress);
        setEmail(newEmail);
        setPhones(newPhones);
        sessionStorage.setItem('customerDetail', JSON.stringify(customerDetail));
      },
    });
  }, []);

  const openModal = (modal) => {
    switch (modal) {
      case 'setModalEmail':
        setModalEmail(true);
        break;
      case 'modalHome':
        setModalHome(true);
        break;
      case 'modalMobile':
        setModalMobile(true);
        break;
      case 'modalMailingAddress':
        setModalMailingAddress(true);
        break;
      case 'modalPassword':
        setModalPassword(true);
        break;
      case 'modalPhysicalAddress':
        setModalPhysicalAddress(true);
        break;
      case 'modalUsername':
        setModalUsername(true);
        break;
      case 'modalWork':
        setModalWork(true);
        break;
      default:
        break;
    }
  };

  const closeModal = (modal) => {
    switch (modal) {
      case 'setModalEmail':
        setModalEmail(true);
        break;
      case 'modalHome':
        setModalHome(false);
        break;
      case 'modalMobile':
        setModalMobile(false);
        break;
      case 'modalMailingAddress':
        setModalMailingAddress(false);
        break;
      case 'modalPassword':
        setModalPassword(false);
        break;
      case 'modalPhysicalAddress':
        setModalPhysicalAddress(false);
        break;
      case 'modalUsername':
        setModalUsername(false);
        break;
      case 'modalWork':
        setModalWork(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="page-documents pb-3">
      <Container fluid>

        <Row className="mb-3 align-items-center">
          <Col className="text-center text-md-left">
            <h3 className="mb-0">Account Settings</h3>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={4}>
            <Card>
              <CardHeader>Username &amp; Password</CardHeader>
              <ListGroup className="btn-list">
                <ListGroupItem>
                  <Row className="align-items-center">
                    <Col xs="auto"><h5 className="mb-0">Username</h5></Col>
                    <Col className="text-right">{username}</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="align-items-center">
                    <Col xs="auto"><h5 className="mb-0">Password</h5></Col>
                    <Col className="text-right">*************</Col>
                    <Col xs="auto" className="px-0">
                      <Button color="link" className="btn-icon" onClick={() => openModal('modalPassword')}>
                        <img src="/icons/edit.svg" alt="Edit" />
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card>
              <CardHeader>Account Addresses</CardHeader>
              <CardBody className="position-relative">
                <h6>Physical Address</h6>
                <p className="mb-0">
                  {primaryAddress.address1}
                  <br />
                  {primaryAddress.city}
                  ,
                  {' '}
                  {primaryAddress.state}
                  {' '}
                  {primaryAddress.zipcode}
                </p>
              </CardBody>
              <CardBody className="position-relative">
                <h6>Mailing Address</h6>
                <p className="mb-0">
                  {mailAddress.address1}
                  <br />
                  {mailAddress.city}
                  ,
                  {' '}
                  {mailAddress.state}
                  {' '}
                  {mailAddress.zipcode}
                </p>
              </CardBody>
            </Card>

          </Col>
          <Col md={6} lg={4}>
            <Card>
              <CardHeader>Contact Information</CardHeader>
              <ListGroup className="btn-list">
                {phones.map((phone, index) => (
                  <ListGroupItem key={`phone-${index}`}>
                    <Row className="align-items-center">
                      <Col xs="auto"><h5 className="mb-0">{phoneTypes[phone.type]}</h5></Col>
                      <Col className="text-right">
                        {phone.isPrimary === 1 && <strong className="text-primary mr-1">Primary</strong>}
                        {formatPhoneNumber(phone.phone)}
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
                <ListGroupItem>
                  <Row className="align-items-center">
                    <Col xs="auto"><h5 className="mb-0">Email</h5></Col>
                    <Col className="text-right">{email}</Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>

      </Container>

      <EditEmail isOpen={modalEmail} toggle={() => closeModal('modalEmail')} />
      <EditPhoneHome isOpen={modalHome} toggle={() => closeModal('modalHome')} />
      <EditPhoneMobile isOpen={modalMobile} toggle={() => closeModal('modalMobile')} />
      <EditPhoneWork isOpen={modalWork} toggle={() => closeModal('modalWork')} />
      <EditMailingAddress isOpen={modalMailingAddress} toggle={() => closeModal('modalMailingAddress')} />
      <EditPassword isOpen={modalPassword} toggle={() => closeModal('modalPassword')} />
      <EditPhysicalAddress isOpen={modalPhysicalAddress} toggle={() => closeModal('modalPhysicalAddress')} />
      <EditUsername isOpen={modalUsername} toggle={() => closeModal('modalUsername')} />

    </div>
  );
};

ConsumerSettings.propTypes = {
  fetchCustomerDetail: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchCustomerDetail,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(ConsumerSettings);
