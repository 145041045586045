import React, { useState } from 'react';
import validate from 'web-form-validator';
import { setDeepValue } from 'utils/objects';

const ValidatorComponent = (schema, formData) => (WrappedComponent) => {
  const Validator = (props) => {
    const [values1, setValues1] = useState(formData || {});
    const [errors1, setErrors1] = useState({});
    const [isValid1, setIsValid1] = useState(true);

    const onChangeHandler = (name, value) => {
      let tmpValues = { ...values1 };
      const tmpErrors = { ...errors1 };

      if (name.split('.').length > 1) {
        tmpValues = setDeepValue({ ...values1 }, name, value);
      } else {
        tmpValues[name] = value;
      }

      if (Object.prototype.hasOwnProperty.call(tmpErrors, name)) {
        delete tmpErrors[name];
      }

      setErrors1(tmpErrors);
      setValues1(tmpValues);
      setIsValid1(Object.keys(tmpErrors).length === 0);
    };

    // Use this method to set values on componentWillMount
    // if user already entered some data before
    const setValues = (tmpValues) => {
      typeof tmpValues === 'object' && setValues1(tmpValues);
    };

    const setErrors = (tmpErrors = {}) => {
      const newErrors = { ...errors1, ...tmpErrors };
      setErrors1(newErrors);
    };

    const resetErrors = () => {
      setIsValid1(true);
      setErrors1({});
    };

    // User this methond on detele multiple errors e.g. ['password', 'passwordConfirmation']
    // to remove errors from all related inputs on changing the only one of them
    const deleteErrors = (names = []) => {
      const tmpErrors = errors1;
      names.forEach((name) => (
        delete tmpErrors[name]
      ));

      setErrors1(tmpErrors);
      setIsValid1(Object.keys(tmpErrors).length === 0);
    };

    const validateForm = (customSchema = schema) => {
      const { errors, isValid } = validate(customSchema, values1);
      setErrors1(errors);
      setIsValid1(isValid);
      return { isValid };
    };

    const validateFields = (customSchema) => {
      const { errors } = validate(customSchema, values1);
      const { isValid } = validate(schema, values1);
      setIsValid1(isValid);
      setErrors1({ ...errors1, ...errors });
    };

    return (
      <WrappedComponent
        validator={{
          validate: validateForm,
          validateFields,
          setValues,
          setErrors,
          deleteErrors,
          resetErrors,
          onChangeHandler,
          values: values1,
          errors: errors1,
          isValid: isValid1,
        }}
        {...props}
      />
    );
  };

  return Validator || null;
};

export default ValidatorComponent;
