// @flow

/**
 * @module Constants/Auth
 * @desc Auth Constants
 */

import keyMirror from 'keymirror';

/**
 * @constant {Object} AuthConstants
 * @memberof Auth
 */
export const AuthConstants = keyMirror({
  AUTH_SIGN_IN: 'AUTH_SIGN_IN',
  AUTH_SIGN_OUT: 'AUTH_SIGN_OUT',
});
