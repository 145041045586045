import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from 'reactstrap';

import Select from 'components/Form/Select';
import Input from 'components/Form/Input';

const EditMailingAddress = (props) => {
  const [disabled, setDisabled] = useState(true);

  const handleValueChange = () => {
    setDisabled(false);
  };

  const handleInputChange = () => {
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle}>Edit Mailing Address</ModalHeader>
      <ModalBody>

        <Input
          label="Address Line 1"
          name="addressLine1"
          onChange={handleValueChange}
          isRequired
        />

        <Input
          label="Address Line 2"
          name="addressLine2"
          onChange={handleValueChange}
          isRequired
        />

        <Input
          label="City"
          name="addressCity"
          onChange={handleValueChange}
          isRequired
        />

        <Row>
          <Col xs={6}>
            <Select
              name="addressState"
              data={[
                { value: 'California', title: 'California' },
                { value: 'Illinois', title: 'Illinois' },
              ]}
              value="California"
              onChange={handleInputChange}
              label="State"
              isRequired
            />
          </Col>
          <Col xs={6} className="pl-0">
            <Input
              type="tel"
              name="addressZip"
              onChange={handleInputChange}
              label="Zip Code"
              isRequired
              mask={Number}
            />
          </Col>
        </Row>

        <Button color="primary" className="w-100 mt-1" disabled={disabled}>Save</Button>

      </ModalBody>
    </Modal>
  );
};

EditMailingAddress.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default EditMailingAddress;
