import { call, put, takeEvery, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import urls from 'config/urls';
import { awsLoginRequest, signOut as signOutRequest } from 'utils/aws';
import { DashboardConstants } from 'constants/dashboard';
import { AuthConstants } from 'constants/auth';
import { request, requestPending, requestSuccess, requestFail } from 'components/Fetch';
import { appConfig } from 'config/appConfig';

function* signIn({ payload }) {
  try {
    yield put({
      type: requestPending(AuthConstants.AUTH_SIGN_IN),
    });
    const response = yield call(awsLoginRequest, { payload: payload.data });
    if (response.error === 'New password required') {
      yield put(push('/set-new-password'));
      yield put({
        type: requestFail(AuthConstants.AUTH_SIGN_IN),
        payload: new Error('New password required'),
      });
      sessionStorage.setItem('user.username', payload.data.username);
    } else {
      yield put({
        type: requestSuccess(AuthConstants.AUTH_SIGN_IN),
        payload: response,
      });
      sessionStorage.setItem('token', response.accessToken.jwtToken);
      sessionStorage.setItem('user.username', response.idToken.payload.preferred_username);
      sessionStorage.setItem('user.firstName', response.idToken.payload['custom:first_name'] || '');
      sessionStorage.setItem('user.lastName', response.idToken.payload['custom:last_name'] || '');
      sessionStorage.setItem('idToken', response.idToken.jwtToken);
      sessionStorage.setItem('refreshToken', response.refreshToken.token);
      sessionStorage.setItem('expiryTime', Date.now() + (3600 * 1000));
      sessionStorage.setItem('isTokenRefreshing', false);

      const [customerDetail, loansList] = yield all([
        call(request({
          type: DashboardConstants.FETCH_CUSTOMER_DETAIL,
          method: 'GET',
          url: urls.fetchCustomerDetail(),
        })),
        call(request({
          type: DashboardConstants.FETCH_LOANS_LIST,
          method: 'GET',
          url: urls.fetchLoansListUrl(),
        })),
      ]);

      const primaryAddressState = customerDetail && customerDetail.primaryAddress && customerDetail.primaryAddress.state;
      const mailAddressState = customerDetail && customerDetail.mailAddress && customerDetail.mailAddress.state;

      if (primaryAddressState === 'MA' || mailAddressState === 'MA') {
        yield put({ type: AuthConstants.AUTH_SIGN_OUT });
        payload.fail({ message: `Massachusetts residents, we are unable to receive payments through this site. Your payment should be made as provided in your monthly statement. If you have questions regarding your loan or want to make payment, please call ${appConfig.massachusettsErrorPhone}` });
      } else if (!loansList || loansList.length === 0) {
        yield put({ type: AuthConstants.AUTH_SIGN_OUT });
        payload.fail({ message: `You currently do not have any active loans. If you have any questions, please call us at ${appConfig.servicingPhone}` });
      } else if (customerDetail && loansList) {
        sessionStorage.setItem('customerDetail', JSON.stringify(customerDetail));
        sessionStorage.setItem('loansList', JSON.stringify(loansList));

        yield put({
          type: requestSuccess(AuthConstants.AUTH_SIGN_IN),
          payload: response,
        });

        payload.success('success');
      } else {
        sessionStorage.clear();
        payload.fail({ message: 'Error occured, Try again!' });
      }
    }
  } catch (err) {
    yield put({
      type: requestFail(AuthConstants.AUTH_SIGN_IN),
      payload: err,
    });
    payload.fail(err);
  }
}

function* signOut() {
  signOutRequest();
  sessionStorage.clear();
  yield put({ type: requestSuccess(AuthConstants.AUTH_SIGN_OUT) });
  yield put(push('/'));
}

const authSaga = function* () {
  yield takeEvery(AuthConstants.AUTH_SIGN_IN, signIn);
  yield takeEvery(AuthConstants.AUTH_SIGN_OUT, signOut);
};

export default authSaga;
