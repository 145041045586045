export const appConfig = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.servicingusa.com/v1',
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || 'us-west-2_X4sEcCcYo',
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '70ut71g6o3lv0unvnrit70709h',
  region: process.env.REACT_APP_COGNITO_REGION || 'us-west-2',
  env: process.env.NODE_ENV,
  paymentMethodId_BankAccount: 4,
  reCaptchaKey : process.env.REACT_APP_RECAPTCHA_KEY || '6LdvJN8UAAAAAOssW77ZEXFI-VXUKWAyVp42ecf9',
  servicingPhone: '(888) 502-8082',
  collectionsPhone: '(888) 502-8085',
  massachusettsErrorPhone: '(855) 855-1626',
  sessionTimeoutInMinutes: 20,
  isInMaintenance: false,
  delay: 6,  // sec
  pageSenseId: process.env.PAGESENSE_ID || '00cca07d610f41d1881503b3014c2184',
};


