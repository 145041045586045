import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import fetch from './fetch';
import dashboard from './dashboard';

const reducers = (history) => combineReducers({
  router: connectRouter(history),
  dashboard,
  fetch,
});

export default reducers;
