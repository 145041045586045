import React from 'react';
import { Row, Col } from 'reactstrap';

import sectigoLogo from 'assets/images/sectigo-logo.png';

const Footer = () => (
  <div className="footer-customer">
    <Row className="w-100" noGutters>
      <Col className="d-flex align-items-center justify-content-center">
        <img src={sectigoLogo} alt="Secured by Sectigo" height="32" className="mr-2" />
        <div>
          Copyright &copy;
          {' '}
          {new Date().getFullYear()}
          . ServicingUSA, LLC.
          <br className="d-sm-none" />
          {' '}
          All Rights Reserved.
          {' '}
          <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">| Privacy Policy</a>
&nbsp;
          <a href="/terms-of-use/" target="_blank" rel="noopener noreferrer">| Terms of Use</a>
        </div>
      </Col>
    </Row>
  </div>
);

export default Footer;
