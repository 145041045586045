import {
  lowerHere,
  upperHere,
  numberHere,
  specialHere,
} from 'utils/helper';

const registrationConfirmSchema = {
  username: [
    {
      rule: 'required',
      error: 'Username is required',
    },
    {
      isValid: (input) => {
        if (input) {
          return /^[a-zA-Z0-9_.-]*$/.test(input)
            ? true
            : 'Only letters, number, dot (.), dash (-) and underscode (_) are allowed.';
        }
        return false;
      },
    },
  ],
  password: [
    {
      rule: 'required',
      error: 'Password is required',
    },

    {
      isValid: (input) => {
        const text = input || '';
        if (input) {
          return lowerHere(text)
            ? true
            : 'Password must contain a lower case letter';
        }

        return true;
      },
    },
    {
      isValid: (input) => {
        const text = input || '';
        if (input) {
          return upperHere(text)
            ? true
            : 'Password must contain an upper case letter';
        }

        return true;
      },
    },
    {
      isValid: (input) => {
        const text = input || '';
        if (input) {
          return numberHere(text) ? true : 'Password must contain a number';
        }

        return true;
      },
    },
    {
      isValid: (input) => {
        const text = input || '';
        if (input) {
          return specialHere(text)
            ? true
            : 'Password must contain a special character';
        }

        return true;
      },
    },
    {
      rule: 'minLength',
      option: 8,
      error: 'Password must contain at least 8 characters',
    },
  ],
  confirmPassword: [
    {
      rule: 'isEqual',
      option: {
        match: 'password',
      },
      error: 'Password confirmation should match the password',
    },
  ],
};

export default registrationConfirmSchema;
