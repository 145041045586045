import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { appConfig } from 'config/appConfig';

const ContactModal = (props) => {
  const [activeTab, setActiveTab] = useState('phone');

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const renderPhone = () => {
    const {
      loanDetail,
    } = props;
    const pastDue = Number(get(loanDetail, 'data.0.daysPastDue', 0));
    const phoneNo = pastDue > 16 ? appConfig.collectionsPhone : appConfig.servicingPhone;

    return (
      <ModalBody className="text-center">
        <h5>Call Us Toll-Free</h5>
        <h2 className="text-primary mb-3 text-center">{phoneNo}</h2>
        <p>
          Monday through Friday
          <br />
          7:00am to 6:00pm (PST)
        </p>
        <p>
          Saturday
          <br />
          7:00am to 4:00pm (PST)
        </p>
      </ModalBody>
    );
  };

  const renderEmail = () => (
    <ModalBody className="pt-0">
      <p>
        Please contact us at
        {' '}
        <a href="mailto:customerservice@servicingusa.com" target="_top">customerservice@servicingusa.com</a>
        {' '}
        and describe the issue in as much detail as possible including dates, amounts, and loan specifics. DO NOT include personal information like Social Security Number. It is not needed to identify you.
      </p>
    </ModalBody>
  );

  const renderMail = () => (
    <ModalBody className="text-center">
      <h5 className="mb-1">Please send all payment checks to:</h5>
      <p>
        LendingUSA
        <br />
        PO Box 70404
        <br />
        Philadelphia PA 19176-0404
      </p>
      <h5 className="mb-1 mt-3">Please send all mail inquiries to:</h5>
      <p>
        LendingUSA
        <br />
        15021 Ventura Blvd, #879
        <br />
        Sherman Oaks, CA 91403
      </p>
    </ModalBody>
  );

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md">

      <ModalHeader className="no-border" toggle={props.toggle}>Contact Us</ModalHeader>
      <ButtonGroup size="sm" className="d-flex ml-3 mr-3 mb-3">
        <Button color="primary" onClick={() => handleActiveTab('phone')} active={activeTab === 'phone' && true}>By Phone</Button>
        <Button color="primary" onClick={() => handleActiveTab('email')} active={activeTab === 'email' && true}>By Email</Button>
        <Button color="primary" onClick={() => handleActiveTab('mail')} active={activeTab === 'mail' && true}>By Mail</Button>
      </ButtonGroup>

      { activeTab === 'phone' && renderPhone() }
      { activeTab === 'email' && renderEmail() }
      { activeTab === 'mail' && renderMail() }

    </Modal>
  );
};

ContactModal.propTypes = {
  loanDetail: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default compose(connect((state) => ({
  loanDetail: state.dashboard.loanDetail,
})))(ContactModal);
