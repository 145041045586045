import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import Datetime from 'react-datetime';
import cn from 'classnames';
import moment from 'moment';
import {
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { capitalize, get } from 'lodash';
import Select from 'components/Form/Select';
import Input from 'components/Form/Input';
import Validator from 'components/Validator/Validator';
import { Button, ButtonLink } from 'components/Button';
import { formatCurrency } from 'utils/formatCurrency';
import { getCurrentDate } from 'utils/func';
import { floatUnmask, currencyMask } from 'utils/masks';
import { filterPaymentMethods } from 'utils/paymentMethods';

import oneTimePaymentSchema from './oneTimePaymentSchema';
import payoffSchema from './payoffSchema';

const PaymentModal = (props) => {
  const [activeTab, setActiveTab] = useState(props.activeTab || 'one-time');
  const [isOneTimeLoading, setIsOneTimeLoading] = useState(false);
  const [isAutoPayLoading, setIsAutoPayLoading] = useState(false);
  const [isPayoffLoading, setIsPayoffLoading] = useState(false);
  const dateTime = useRef(null);

  const setDefaultTab = () => {
    const { primaryPaymentMethod, activeTab: newActiveTab, loanDetail, nextPaymentDate, validator: { setValues } } = props;
    const nextPaymentAmount = props.nextPaymentAmount ? `${props.nextPaymentAmount[0]}.${props.nextPaymentAmount[1]}` : '0.00';
    const pastDueAmount = props.pastDueAmount.length > 0 ? `${props.pastDueAmount[0]}.${props.pastDueAmount[1]}` : '0.00';
    const dueFees = props.dueFees ? formatCurrency(props.dueFees, 2) : '0.00';
    const totalAmount = formatCurrency(Number(floatUnmask(nextPaymentAmount)) + Number(floatUnmask(pastDueAmount)) + Number(floatUnmask(dueFees)), 2);

    setActiveTab(newActiveTab);
    setIsOneTimeLoading(false);
    setIsAutoPayLoading(false);
    setIsPayoffLoading(false);

    let paymentAmount = '0';

    if (nextPaymentDate && pastDueAmount && pastDueAmount !== '0.00' && moment(nextPaymentDate).diff(moment(), 'days', true) > 4) {
      paymentAmount = '1';
    }

    setValues({
      paymentAmount,
      payFromAccount: primaryPaymentMethod,
      paymentAccountId: newActiveTab === 'autopay' && primaryPaymentMethod && (primaryPaymentMethod.toLowerCase().indexOf('debit') >= 0 || primaryPaymentMethod.toLowerCase().indexOf('credit')) >= 0 ? '' : primaryPaymentMethod,
      paymentDate: getCurrentDate(),
      daysPastDue: get(loanDetail, 'data.0.daysPastDue', 0),
      totalAmount,
      pastDueAmount,
    });
  };

  useEffect(() => {
    const { validator: { setValues }, primaryPaymentMethod, loanDetail, nextPaymentDate } = props;
    const nextPaymentAmount = props.nextPaymentAmount ? `${props.nextPaymentAmount[0]}.${props.nextPaymentAmount[1]}` : '0.00';
    const pastDueAmount = props.pastDueAmount.length > 0 ? `${props.pastDueAmount[0]}.${props.pastDueAmount[1]}` : '0.00';
    const dueFees = props.dueFees ? formatCurrency(props.dueFees, 2) : '0.00';
    const totalAmount = formatCurrency(Number(floatUnmask(nextPaymentAmount)) + Number(floatUnmask(pastDueAmount)) + Number(floatUnmask(dueFees)), 2);

    let paymentAmount = '0';

    if (nextPaymentDate && pastDueAmount && pastDueAmount !== '0.00' && moment(nextPaymentDate).diff(moment(), 'days', true) > 4) {
      paymentAmount = '1';
    }

    const initialFormData = {
      paymentDate: getCurrentDate(),
      paymentAmount,
      payFromAccount: primaryPaymentMethod,
      paymentAccountId: primaryPaymentMethod,
      daysPastDue: get(loanDetail, 'data.0.daysPastDue', 0),
      totalAmount,
      pastDueAmount,
    };
    setValues(initialFormData);
  }, []);

  useEffect(() => {
    const { validator: { setValues }, primaryPaymentMethod, loanDetail, nextPaymentDate } = props;
    const nextPaymentAmount = props.nextPaymentAmount ? `${props.nextPaymentAmount[0]}.${props.nextPaymentAmount[1]}` : '0.00';
    const pastDueAmount = props.pastDueAmount.length > 0 ? `${props.pastDueAmount[0]}.${props.pastDueAmount[1]}` : '0.00';
    const dueFees = props.dueFees ? formatCurrency(props.dueFees, 2) : '0.00';
    const totalAmount = formatCurrency(Number(floatUnmask(nextPaymentAmount)) + Number(floatUnmask(pastDueAmount)) + Number(floatUnmask(dueFees)), 2);

    let paymentAmount = '0';

    if (nextPaymentDate && pastDueAmount && pastDueAmount !== '0.00' && moment(nextPaymentDate).diff(moment(), 'days', true) > 4) {
      paymentAmount = '1';
    }

    setValues({
      paymentAmount,
      payFromAccount: primaryPaymentMethod,
      paymentAccountId: primaryPaymentMethod,
      paymentDate: getCurrentDate(),
      daysPastDue: get(loanDetail, 'data.0.daysPastDue', 0),
      pastDueAmount,
      totalAmount,
    });
  }, [props.loanDetail, props.primaryPaymentMethod]);

  const handleActiveTab = (tab) => {
    const { validator: { setValues, resetErrors }, primaryPaymentMethod, loanDetail, loansList } = props;

    resetErrors();
    setValues({
      paymentAccountId: tab === 'autopay' && primaryPaymentMethod && (primaryPaymentMethod.toLowerCase().indexOf('debit') >= 0 || primaryPaymentMethod.toLowerCase().indexOf('credit')) >= 0 ? '' : primaryPaymentMethod,
      payFromAccount: primaryPaymentMethod,
      paymentAmount: '0',
      paymentDate: getCurrentDate(),
      daysPastDue: get(loanDetail, 'data.0.daysPastDue', 0),
    });

    if (tab === 'payoff') {
      props.fetchCurrentPayoffInfo({
        loanId: loansList.activeLoanId,
        date: getCurrentDate('yyyy-MM-dd'),
      });

      props.fetchFuturePayoffInfo({
        loanId: loansList.activeLoanId,
        date: moment().add(14, 'days').format('YYYY-MM-DD'),
      });
    }

    setActiveTab(tab);
  };

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler }, addPaymentMethod } = props;

    switch (event.target.name) {
      case 'payFromAccount':
      case 'paymentAccountId':
        if (event.target.value === 'addPaymentMethod') {
          addPaymentMethod();
        } else {
          onChangeHandler(event.target.name, event.target.value);
        }
        break;
      default:
        onChangeHandler(event.target.name, event.target.value);
    }
  };

  const handleBlur = (event) => {
    const { validator: { onChangeHandler } } = props;
    onChangeHandler(event.target.name, formatCurrency(floatUnmask(event.target.value || '0'), 2));
  };

  const handleDateChange = (date) => {
    const { validator: { onChangeHandler } } = props;
    if (date && typeof date.toISOString === 'function') {
      onChangeHandler('paymentDate', format(new Date(date.toISOString()), 'MM/dd/yyyy'));
    } else {
      onChangeHandler('paymentDate', date);
    }
  };

  const handlePostPayment = () => {
    const { validator: { validate, values, errors } } = props;

    if (validate(oneTimePaymentSchema).isValid) {
      setIsOneTimeLoading(true);
      const selectedAccount = values.payFromAccount.split('-');
      const paymentMethodId = (selectedAccount[1] === 'savings' || selectedAccount[1] === 'checking') ? 4 : 3;

      const nextPaymentAmount = props.nextPaymentAmount ? `${props.nextPaymentAmount[0]}.${props.nextPaymentAmount[1]}` : '0.00';
      const pastDueAmount = props.pastDueAmount ? `${props.pastDueAmount[0]}.${props.pastDueAmount[1]}` : '0.00';
      const totalAmount = formatCurrency(Number(floatUnmask(nextPaymentAmount)) + Number(floatUnmask(pastDueAmount)), 2);
      const dueFees = props.dueFees ? formatCurrency(props.dueFees, 2) : '0.00';

      let paymentAmount = '';
      let fee = 0;
      switch (values.paymentAmount) {
        case '0':
          paymentAmount = totalAmount;
          fee = dueFees;
          break;
        case '1':
          paymentAmount = pastDueAmount;
          fee = dueFees;
          break;
        default:
          paymentAmount = values.customPaymentAmount;
      }

      if (moment().startOf('day').diff(moment(values.paymentDate)) === 0) {
        const data = {
          paymentMethodId,
          amount: Number(floatUnmask(paymentAmount)),
          fee: Number(floatUnmask(fee)),
          date: values.paymentDate,
          paymentAccountId: selectedAccount[0],
          isPayoff: false,
        };

        props.putMakePayment(data, () => {
          setIsOneTimeLoading(false);
        });
      } else {
        const data = {
          name: 'Regular Payment',
          type: 'autopay.type.single',
          amountType: 'autopay.amountType.static',
          amount: Number(floatUnmask(paymentAmount)),
          fee: Number(floatUnmask(fee)),
          applyDate: moment(values.paymentDate).format('YYYY-MM-DD'),
          processDate: moment(values.paymentDate).format('YYYY-MM-DD'),
          recurringFrequency: 'autopay.recurringFrequency.single',
          recurringPeriods: 1,
          primaryPaymentMethodId: selectedAccount[0], // id of the payment from account
        };

        props.putScheduleAutopay(data, () => {
          setIsOneTimeLoading(false);
        });
      }
    } else {
      console.log('api error', errors);
    }
  };

  const handleScheduleAutopay = () => {
    const { validator: { validate, values }, loanDetail, loanTerm } = props;

    if (validate(payoffSchema).isValid) {
      setIsAutoPayLoading(true);
      const selectedAccount = values.paymentAccountId.split('-');
      const paymentAmount = get(loanTerm, 'data.regularPayment', '-');
      const nextPaymentDate = loanDetail.data ? moment(get(loanDetail, 'data.0.nextPaymentDate'), 'YYYY-MM-DD') : '-';
      const periodsRemaining = get(loanDetail, 'data.0.periodsRemaining', '-');

      const data = {
        name: 'Regular Payment',
        type: 'autopay.type.recurringMatch',
        amountType: 'autopay.amountType.static',
        amount: paymentAmount,
        applyDate: nextPaymentDate,
        processDate: nextPaymentDate,
        recurringFrequency: 'autopay.recurringFrequency.monthly',
        recurringPeriods: periodsRemaining,
        primaryPaymentMethodId: selectedAccount[0], // id of the payment from account
      };

      props.putScheduleAutopay(data, () => {
        setIsAutoPayLoading(false);
      });
    } else {
      console.log('api error');
    }
  };

  const handleMakePayoff = () => {
    const { validator: { validate, values }, currentPayoffInfo } = props;

    if (validate(payoffSchema).isValid) {
      setIsPayoffLoading(true);
      const selectedAccount = values.paymentAccountId.split('-');
      const paymentMethodId = (selectedAccount[1] === 'savings' || selectedAccount[1] === 'checking') ? 4 : 3;

      const data = {
        paymentMethodId,
        amount: currentPayoffInfo.data.payoff,
        date: getCurrentDate(),
        paymentAccountId: selectedAccount[0],
        isPayoff: true,
      };

      props.putMakePayment(data, () => {
        setIsPayoffLoading(false);
      });
    } else {
      console.log('api error');
    }
  };

  const validateDate = (current) => {
    const { loanDetail } = props;

    if (Number(get(loanDetail, 'data.0.daysPastDue', 0)) > 45) {
      return current.isAfter(Datetime.moment().subtract(1, 'day')) && current.isBefore(Datetime.moment());
    }
    return current.isAfter(Datetime.moment().subtract(1, 'day')) && current.isBefore(Datetime.moment().add(45 - Number(get(loanDetail, 'data.0.daysPastDue', 0)), 'days'));
  };

  const renderOneTimePayment = () => {
    const {
      validator: {
        values,
        errors,
      },
      paymentMethods,
      toggleLoanAgreementModal,
      loanDetail,
      nextPaymentDate,
      toggleCancelPaymentModal,
      channel,
    } = props;
    const allPaymentMethods = paymentMethods ? paymentMethods.map((item) => {
      const type = Array.isArray(item.type) ? item.type[2] : item.type;
      return ({
        value: `${item.paymentAccountId}-${type.toLowerCase()}`,
        title: `${capitalize(type)} ${['debit', 'credit'].indexOf(type.toLowerCase()) !== -1 ? ' xxxx-xxxx-xxxx-' : ' xxxxxx'}${item.accountNumber.slice(-4)}`,
      });
    }) : [];
    allPaymentMethods.push({
      value: 'addPaymentMethod',
      title: '+ Add Payment Method ...',
    });

    const nextPaymentAmount = props.nextPaymentAmount ? `${props.nextPaymentAmount[0]}.${props.nextPaymentAmount[1]}` : '0.00';
    const pastDueAmount = props.pastDueAmount.length > 0 ? `${props.pastDueAmount[0]}.${props.pastDueAmount[1]}` : '0.00';
    const dueFees = props.dueFees ? formatCurrency(props.dueFees, 2) : '0.00';
    const totalAmount = formatCurrency(props.totalDueAmount || 0, 2);

    const paymentAmounts = [
      {
        title: `Total Due - $${totalAmount}`,
        value: '0',
      },
      {
        title: `Past Due (including fees) - $${formatCurrency(Number(floatUnmask(pastDueAmount)) + Number(floatUnmask(dueFees)), 2)}`,
        value: '1',
      },
      {
        title: 'Other Amount',
        value: '2',
      },
    ];

    const isSusaAccount = `${channel}`.toLowerCase() === 'bnb';

    return (
      <div>
        <Table className="mb-0" size="sm" responsive striped borderless>
          <tbody>
            <tr>
              <th scope="row" className="pl-3">Current Due</th>
              <td className="text-right pr-3">
                $
                {nextPaymentAmount}
              </td>
            </tr>
            <tr>
              <th scope="row" className="pl-3">Past Due</th>
              <td className="text-right pr-3">
                $
                {pastDueAmount}
              </td>
            </tr>
            <tr>
              <th scope="row" className="pl-3">Fees</th>
              <td className="text-right pr-3">
                $
                {dueFees}
              </td>
            </tr>
            <tr>
              <th scope="row" className="pl-3">
                <b>Total Due</b>
                {' '}
                by
                {' '}
                {nextPaymentDate}
              </th>
              <td className="text-right pr-3">
                $
                {totalAmount}
              </td>
            </tr>
          </tbody>
        </Table>
        <ModalBody>
          <Select
            name="payFromAccount"
            data={allPaymentMethods}
            value={values.payFromAccount}
            onChange={handleInputChange}
            label="Pay From Account"
            isRequired
            hasError={!!errors.payFromAccount}
          />
          <div className={cn('has-value', 'hasValue', errors.paymentDate ? 'required' : '')}>
            <span>
              Payment Date
              <em>Required</em>
            </span>
            <Datetime
              ref={dateTime}
              name="paymentDate"
              label="Payment Date"
              value={values.paymentDate}
              onChange={handleDateChange}
              closeOnSelect
              dateFormat="MM/DD/YYYY"
              timeFormat={false}
              placeHolder="MM/DD/YYYY"
              isValidDate={validateDate}
              renderInput={(propsInput) => (
                <Input
                  isMasked={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  isRequired
                  hasError={!!errors.paymentDate}
                  errorMessage={errors.paymentDate}
                  {...propsInput}
                />
              )}
            />
          </div>
          <Select
            name="paymentAmount"
            label="Payment Amount"
            data={paymentAmounts}
            value={values.paymentAmount}
            onChange={handleInputChange}
            isRequired
            hasError={!!errors.paymentAmount}
            errorMessage={errors.paymentAmount}
          />
          {
            values.paymentAmount === '2' && (
              <Input
                label="Custom Payment Amount"
                name="customPaymentAmount"
                value={values.customPaymentAmount}
                onChange={handleInputChange}
                onBlur={handleBlur}
                isMasked={currencyMask}
                isRequired
                hasError={!!errors.customPaymentAmount}
                errorMessage={errors.customPaymentAmount}
              />
            )
          }
          <small className="text-center d-block mb-1">
            By clicking &#34;Make Payment&#34;, I authorize
            {' '}
            {isSusaAccount ? 'ServicingUSA' : 'LendingUSA'}
            {' '}
            to make a one-time debit and/or charge from my account as designated above.
          </small>
          <Button
            color="primary"
            className="w-100 mt-1 mb-2"
            onClick={handlePostPayment}
            disabled={
              !paymentMethods.length
              || isOneTimeLoading
              || !loanDetail.data
              || (values.paymentAmount === '2' && !values.customPaymentAmount)
              || !values.payFromAccount
            }
            isLoading={isOneTimeLoading}
          >
            Make Payment
          </Button>
          <div style={{ textAlign: 'center', marginTop: 5 }}>
            <ButtonLink className="d-block text-center btn-link" style={{ cursor: 'pointer' }} onClick={toggleLoanAgreementModal}>How will my payment be applied?</ButtonLink>
          </div>
          <div style={{ textAlign: 'center', marginTop: 5 }}>
            <ButtonLink className="d-block text-center btn-link" style={{ cursor: 'pointer' }} onClick={toggleCancelPaymentModal}>How can I cancel my payment?</ButtonLink>
          </div>
        </ModalBody>
      </div>
    );
  };

  const renderAutoPayPayment = () => {
    const {
      loanDetail,
      loanTerm,
      validator: {
        values,
        errors,
      },
      paymentMethods,
      toggleTermsModal,
      toggleRecurringModal,
    } = props;

    const allPaymentMethods = paymentMethods ? paymentMethods.filter(filterPaymentMethods).map((item) => {
      const type = Array.isArray(item.type) ? item.type[2] : item.type;
      return ({
        value: `${item.paymentAccountId}-${type.toLowerCase()}`,
        title: `${capitalize(type)} ${['debit', 'credit'].indexOf(type.toLowerCase()) !== -1 ? ' xxxx-xxxx-xxxx-' : ' xxxxxx'}${item.accountNumber.slice(-4)}`,
      });
    }) : [];

    allPaymentMethods.push({
      value: 'addPaymentMethod',
      title: '+ Add Payment Method ...',
    });

    const frequency = moment(new Date(get(loanDetail, 'data.0.nextPaymentDate', ''))).format('Do');
    const nextDate = get(loanDetail, 'data.0.nextPaymentDate', '') ? moment(new Date(get(loanDetail, 'data.0.nextPaymentDate', ''))).format('MM/DD/YYYY').toString() : '-';
    const paymentAmount = loanTerm.data ? get(loanTerm, 'data.regularPayment', '-') : '-';
    const isDisabled = !paymentMethods.length
      || isAutoPayLoading
      || !loanDetail.data
      || nextDate === '-'
      || !values.paymentAccountId;

    return (
      <div>
        <Table className="mb-0" size="sm" responsive striped borderless>
          <tbody>
            <tr>
              <th scope="row" className="pl-3">Frequency</th>
              <td className="text-right pr-3">
                Monthly (Every
                {' '}
                {frequency}
                )
              </td>
            </tr>
            <tr>
              <th scope="row" className="pl-3">Next Date</th>
              <td className="text-right pr-3">{nextDate}</td>
            </tr>
            <tr>
              <th scope="row" className="pl-3">Payment Amount</th>
              <td className="text-right pr-3">
                $
                {formatCurrency(paymentAmount, 2)}
              </td>
            </tr>
          </tbody>
        </Table>
        <ModalBody className="pt-3">
          <Container fluid>
            <Row>
              <Col className="pl-0 pr-0">
                <Select
                  name="paymentAccountId"
                  data={allPaymentMethods}
                  value={values.paymentAccountId}
                  onChange={handleInputChange}
                  label="Pay From Account"
                  hasError={!!errors.paymentAccountId}
                  errorMessage={errors.paymentAccountId}
                  isRequired
                />
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="pl-0 pr-0">
                <p style={{ fontSize: 12 }}>
                  By clicking &#34;SCHEDULE AUTOPAY&#34;, I authorize recurring payments as designated above under these
                  {' '}
                  <ButtonLink className="d-inline btn-link" style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: isDisabled ? 'no-drop' : 'pointer' }} onClick={isDisabled ? undefined : toggleTermsModal}>terms</ButtonLink>
                  .
                </p>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col>
                <Button
                  color="primary"
                  className="w-100 mt-1"
                  onClick={handleScheduleAutopay}
                  disabled={isDisabled}
                  isLoading={isAutoPayLoading}
                >
                  SCHEDULE AUTOPAY
                </Button>
              </Col>
            </Row>
            <Row style={{ textAlign: 'center', marginTop: 5 }}>
              <Col>
                <ButtonLink className="d-inline btn-link" style={{ cursor: 'pointer' }} onClick={toggleRecurringModal}>How will my payments be applied?</ButtonLink>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </div>
    );
  };

  const renderPayoffPayment = () => {
    const {
      currentPayoffInfo,
      futurePayoffInfo,
      validator: {
        values,
        errors,
      },
      paymentMethods,
      toggleLoanAgreementModal,
      promoEligibility,
    } = props;
    const allPaymentMethods = paymentMethods ? paymentMethods.map((item) => {
      const type = Array.isArray(item.type) ? item.type[2] : item.type;
      return ({
        value: `${item.paymentAccountId}-${type.toLowerCase()}`,
        title: `${capitalize(type)} ${['debit', 'credit'].indexOf(type.toLowerCase()) !== -1 ? ' xxxx-xxxx-xxxx-' : ' xxxxxx'}${item.accountNumber.slice(-4)}`,
      });
    }) : [];

    allPaymentMethods.push({
      value: 'addPaymentMethod',
      title: '+ Add Payment Method ...',
    });

    const goodUntil = get(currentPayoffInfo, 'data.date');
    const futurePayoffDate = get(futurePayoffInfo, 'data.date');

    return (
      <div>
        <Table className="mb-0" size="sm" responsive striped borderless>
          <tbody>
            <tr>
              <th scope="row" className="pl-3">Payment Date</th>
              <td className="text-right pr-3">{getCurrentDate()}</td>
            </tr>
            <tr>
              <th scope="row" className="pl-3">
                Payoff Amount&nbsp;
                <i style={{ fontWeight: 'normal' }}>
                  (Good until
                  {' '}
                  <span>
                    {goodUntil && moment(goodUntil).isValid() ? format(new Date(goodUntil), 'MM/dd/yyyy') : ' -'}
                    )
                  </span>
                </i>
                {`${promoEligibility}`.toLowerCase() === 'yes' && (
                <div className="payoff-description">
                  This promo payoff amount reflects interest that you may have paid. Please allow 7 days once we receive your payoff payment for your promo credit to be applied and for your account to close.
                </div>
                )}
              </th>
              <td className="text-right pr-3">
                $
                {currentPayoffInfo.data ? formatCurrency(get(currentPayoffInfo, 'data.payoff', ' -'), 2) : ' -'}
              </td>
            </tr>
          </tbody>
        </Table>
        <ModalBody className="pt-2">
          <Select
            name="paymentAccountId"
            data={allPaymentMethods}
            value={values.paymentAccountId}
            onChange={handleInputChange}
            label="Pay From Account"
            hasError={!!errors.paymentAccountId}
            errorMessage={errors.paymentAccountId}
            isRequired
          />
          <p style={{ fontSize: 12 }} className="mb-0">
            <b>Want to pay by check?</b>
&nbsp;Please make check payable to LendingUSA for the amount of $
            {futurePayoffInfo.data ? formatCurrency(get(futurePayoffInfo, 'data.payoff', ' -'), 2) : ' -'}
            {' '}
            (good until
            {' '}
            {futurePayoffDate && moment(futurePayoffDate).isValid() ? format(new Date(futurePayoffDate), 'MM/dd/yyyy') : ' -'}
            ), and send it to:
          </p>
          <div style={{ fontSize: 12 }}>
            <p className="mb-0 text-center">LendingUSA</p>
            <p className="mb-0 text-center">PO Box 70404</p>
            <p className="mb-0 text-center">Philadelphia PA 19176-0404</p>
          </div>
          <small className="text-center d-block mt-0 mb-1">
            By clicking &#34;MAKE PAYMENT&#34;, I authorize a payment as designated above.
          </small>
          <Button
            color="primary"
            className="w-100 mt-1 mb-2"
            disabled={
              !currentPayoffInfo.data
              || !paymentMethods.length
              || isPayoffLoading
              || !futurePayoffInfo.data
              || !values.paymentAccountId
              || !get(currentPayoffInfo, 'data.payoff')
            }
            onClick={handleMakePayoff}
            isLoading={isPayoffLoading}
          >
            Make Payment
          </Button>
          <div style={{ textAlign: 'center', marginTop: 5 }}>
            <ButtonLink className="d-block text-center btn-link" style={{ cursor: 'pointer' }} onClick={toggleLoanAgreementModal}>How will my payment be applied?</ButtonLink>
          </div>
        </ModalBody>
      </div>
    );
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md" onOpened={setDefaultTab}>
      <ModalHeader className="no-border" toggle={props.toggle}>Make A Payment</ModalHeader>
      <ButtonGroup size="sm" className="d-flex ml-3 mr-3 mb-3">
        <Button color="primary" onClick={() => handleActiveTab('one-time')} active={activeTab === 'one-time'}>One-Time</Button>
        <Button color="primary" disabled={get(props.autopay, 'data.isAutoPayEnabled', false)} onClick={() => handleActiveTab('autopay')} active={activeTab === 'autopay'}>AutoPay</Button>
        <Button color="primary" onClick={() => handleActiveTab('payoff')} active={activeTab === 'payoff'}>Payoff</Button>
      </ButtonGroup>

      { activeTab === 'one-time' && renderOneTimePayment() }
      { activeTab === 'autopay' && renderAutoPayPayment() }
      { activeTab === 'payoff' && renderPayoffPayment() }
    </Modal>
  );
};

PaymentModal.propTypes = {
  activeTab: PropTypes.string,
  autopay: PropTypes.object.isRequired,
  validator: PropTypes.object.isRequired,
  loanDetail: PropTypes.object.isRequired,
  loansList: PropTypes.object.isRequired,
  loanTerm: PropTypes.object.isRequired,
  futurePayoffInfo: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  nextPaymentAmount: PropTypes.array.isRequired,
  pastDueAmount: PropTypes.array.isRequired,
  nextPaymentDate: PropTypes.string,
  primaryPaymentMethod: PropTypes.string,
  dueFees: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  currentPayoffInfo: PropTypes.object.isRequired,
  fetchCurrentPayoffInfo: PropTypes.func.isRequired,
  fetchFuturePayoffInfo: PropTypes.func.isRequired,
  addPaymentMethod: PropTypes.func.isRequired,
  putMakePayment: PropTypes.func.isRequired,
  putScheduleAutopay: PropTypes.func.isRequired,
  toggleLoanAgreementModal: PropTypes.func.isRequired,
  toggleTermsModal: PropTypes.func.isRequired,
  toggleRecurringModal: PropTypes.func.isRequired,
  toggleCancelPaymentModal: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  promoEligibility: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  totalDueAmount: PropTypes.number.isRequired,
};

PaymentModal.defaultProps = {
  activeTab: 'one-time',
  nextPaymentDate: '',
  primaryPaymentMethod: '',
};

export default compose(
  Validator(oneTimePaymentSchema),
  connect((state, ownProps) => ({
    currentPayoffInfo: state.dashboard.currentPayoffInfo,
    futurePayoffInfo: state.dashboard.futurePayoffInfo,
    loanDetail: state.dashboard.loanDetail,
    loansList: state.dashboard.loansList,
    loanTerm: state.dashboard.loanTerm,
    autopay: state.dashboard.autopay,
    allPaymentMethods: ownProps.paymentMethods.map((item) => ({
      value: `${item.paymentAccountId}-${Array.isArray(item.type) ? item.type[2] : `${item.type}`.toLowerCase()}`,
      title: `${item.accountName} ending ${item.accountNumber.slice(-4)}`,
    })),
    promoEligibility: state.dashboard.billingSetting.data && state.dashboard.billingSetting.data.customFields ? state.dashboard.billingSetting.data.customFields.promoEligibility : 'No',
  }))
)(PaymentModal);
