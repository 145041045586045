import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const ScrollToTop = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return props.children;
};

ScrollToTop.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.any,
};

ScrollToTop.defaultProps = {
  children: null,
};

export default withRouter(ScrollToTop);
