import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CustomInput,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import Input from 'components/Form/Input';

const EditPhoneWork = (props) => {
  const [disabled, setDisabled] = useState(true);

  const handleValueChange = () => {
    setDisabled(false);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle}>Edit Work Number</ModalHeader>
      <ModalBody>

        <Input
          type="tel"
          name="work-number"
          id="workNumber"
          label="Work Number"
          tag={NumberFormat}
          format="(###) ###-####"
          defaultValue="5551234567"
          onChange={handleValueChange}
        />
        <FormGroup className="mb-3" check>
          <CustomInput
            type="checkbox"
            name="check"
            id="Check"
            label="Set as primary number"
            onChange={handleValueChange}
          />
        </FormGroup>

        <Button color="primary" className="w-100" disabled={disabled}>Save</Button>

      </ModalBody>
    </Modal>
  );
};

EditPhoneWork.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default EditPhoneWork;
