const urls = {
  loginUrl: () => '/customers/authenticate',
  fetchLoansListUrl: () => '/customers/current/loans',
  fetchCustomerDetail: () => '/customers/current',
  fetchLoanDetail: (loanId) => `/customers/current/loans/${loanId}/status`,
  fetchLoanTerm: (loanId) => `/customers/current/loans/${loanId}/setup`,
  fetchTransactionsList: (loanId) => `/customers/current/loans/${loanId}/transactions`,
  fetchPaymentsList: (loanId) => `/customers/current/loans/${loanId}/payments`,
  fetchPaymentMethods: () => '/customers/current/paymentaccounts',
  fetchDocuments: (loanId) => `/customers/current/loans/${loanId}/documents`,
  downloadDocument : (loanId, documentId, fileName) =>`/customers/current/loans/${loanId}/documents/${documentId}/${fileName}`,
  removePaymentMethod : (paymentAccountId) => `/customers/current/paymentaccounts/${paymentAccountId}`,
  putPaymentAccount : () => '/customers/current/paymentaccounts',
  postDebitCardAccount: () => '/customers/current/paymentaccounts',
  fetchPayoffInfo: ({ loanId, date }) => `/customers/current/loans/${loanId}/payoff?asOfDate=${date}`,
  fetchScheduledPayments: (loanId) => `/customers/current/loans/${loanId}/autopays`,
  cancelAutopay: ({ loanId, id }) => `/customers/current/loans/${loanId}/autopays/${id}`,
  validateRegistration: () => '/customers/registration/validate',
  getSettings: (loanId) => `/customers/current/loans/${loanId}/settings`,
  fetchAutopayInfo: (loanId) => `/customers/current/loans/${loanId}/is-autopay-enabled`,
  postForkInfo: () => `/servicer/fork`,
  fetchLoanCharges: (loanId) => `/customers/current/loans/${loanId}/charges/summary`,
  validateRoutingNumber: ({ routingNumber }) => `/bank-accounts/query/routing-number/${routingNumber}`,
};

export default urls;
