import moment from 'moment';
import { numberUnmask, removeSpace, removeUnderline } from 'utils/masks';

const addDebitCardSchema = {
  address: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  city: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  state: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  zipcode: [{
    rule: 'required',
    error: 'The input field is required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }

      if (removeUnderline(input).length !== 5) {
        return 'Invalid zipcode';
      }
      return true;
    },
  }],
  cardHolderName: [{
    rule: 'required',
    error: 'The input field is required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }

      if (input.trim().split(' ').length < 2) {
        return 'Full name is required';
      }

      return true;
    },
  }],
  cardNumber: [{
    rule: 'required',
    error: 'The input field is required',
    isValid: (input) => {
      if (input && input.includes('X')) return true;

      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }

      if (numberUnmask(input).length < 16) {
        return 'Card number is not valid';
      }

      if (
        !numberUnmask(input).startsWith('4')
        && !numberUnmask(input).startsWith('5')
      ) {
        return 'Currently we accept only Visa or Mastercard';
      }

      return true;
    },
  }],
  expirationDate: [{
    rule: 'required',
    error: 'The input field is required',
    isValid: (input) => {
      if (input && input.includes('X')) return true;

      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }

      if (numberUnmask(input).length < 6 || !moment(input, 'MM/YYYY').isValid()) {
        return 'Expiration Date is not valid';
      }

      return moment(input, 'MM/YYYY').endOf('month').isSameOrBefore(moment().startOf('month')) ? 'Expiration Date is not valid' : true;
    },
  }],
};

export default addDebitCardSchema;
