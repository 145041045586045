import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Input from 'components/Form/Input';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changePassword } from 'utils/aws';
import Validator from 'components/Validator/Validator';
import { Button } from 'components/Button';

import schema from './changepasswordSchema';

const EditPassword = (props) => {
  const {
    validator: { values, errors },
  } = props;
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!props.isOpen) setIsLoading(false);
  }, [props.isOpen]);

  const handleInputChange = (event) => {
    setDisabled(false);
    const {
      validator: { onChangeHandler },
    } = props;
    const value = event.target.value.trim();
    onChangeHandler(event.target.name, value);
  };

  const handleSubmitFrom = (data, e) => {
    e.preventDefault();
    const {
      validator: { validate },
    } = props;

    if (validate(schema).isValid) {
      const formData = data;
      setIsLoading(true);
      changePassword(formData)
        .then(() => {
          setIsLoading(false);
          toast.success(
            (
              <div>
                <h5>Password changed successfully!</h5>
              </div>
            ),
            { autoClose: true }
          );
          props.toggle();
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(
            (
              <div>
                <h5>{err.message}</h5>
              </div>
            ),
            { autoClose: true }
          );
        });
    }
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle}>Change Password</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Form>
            <Input
              name="currentpassword"
              type="password"
              label="Current Password"
              value={values.currentpassword}
              onChange={handleInputChange}
              hasError={!!errors.password}
              hasValue
              placeHolder="password"
              isBadgeVisible={false}
              isRequired
              errorMessage={errors.password}
            />
            <Input
              name="password"
              type="password"
              label="New Password"
              value={values.password}
              onChange={handleInputChange}
              hasError={!!errors.password}
              hasValue
              placeHolder="password"
              isBadgeVisible={false}
              isRequired
              errorMessage={errors.password}
            />
            <Input
              name="confirmPassword"
              label="Retype New Password"
              onChange={handleInputChange}
              value={values.confirmPassword}
              hasError={!!errors.confirmPassword}
              hasValue
              placeHolder="Retype New Password"
              isBadgeVisible={false}
              isRequired
              type="password"
              errorMessage={errors.confirmPassword}
            />
            <Button color="primary" onClick={handleSubmitFrom.bind(null, values)} type="submit" className="w-100 mt-1" isLoading={isLoading} disabled={disabled}>Change Password</Button>
          </Form>
        </Container>
      </ModalBody>
    </Modal>
  );
};

EditPassword.propTypes = {
  validator: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  Validator(schema),
  connect(
    (state) => ({
      auth: state.auth,
    }),
    null
  )
)(EditPassword);
