import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

const TermsModal = (props) => {
  const { fee, channel } = props;
  const isSusaAccount = `${channel}`.toLowerCase() === 'bnb';
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      size="lg"
      scrollable
    >
      <ModalHeader className="no-border" toggle={props.toggle}>
        Optional Electronic Funds Transfer Authorization
      </ModalHeader>
      <ModalBody className="pt-2 recurring-description" style={{ height: '450px', overflowY: 'scroll' }}>
        {!isSusaAccount ? (
          <>
            <p>In this Optional Electronic Funds Transfer Authorization (“Authorization”), “I,” “me,” “my,” “we” and “our” refer to the Borrower and any Co-Borrower under the Loan Agreement (“Agreement”). This Authorization allows  preauthorized  remotely  created  checks  (defined  below)  and  Electronic  Fund  Transfers  Payments (defined  below)  from  my  bank  account  designated  below  (“Account”)  to  LendingUSA,  LLC  and/or  to  its affiliates, successors in interests, or assignees (collectively, “you”). I understand that any Electronic Fund Transfer Payment will appear on my Account statement as payable to “LENDINGUSA LOAN PMT”. I may choose the convenience of having my monthly loan payments under the Agreement made automatically from my financial institution.</p>
            <p className="mb-0"><b>Definitions</b></p>
            <ol>
              <li className="pb-1">Electronic  Fund  Transfers  Payments  (“EFT  Payments”)  refer  to  payments  made  using  automated clearing house transfers or debit card transactions.</li>
              <li className="pb-1">Remotely created check (“RCC”) refers to a holder of a checking account providing authorization to a payee to draw a check on that account without the holder having to sign a physical check.</li>
            </ol>
            <p className="mb-0"><b><u>By signing the Authorization, I agree to the following terms:</u></b></p>
            <ol>
              <li className="pb-1">
                <b>Authorization:</b>
                {' '}
                By signing below, I authorize you to automatically withdraw EFT Payments or draw RCCs (collectively, “Payments”) from my Account in the amount equal to the preauthorized amount on the designated date(s) and frequency. If the due date falls on a Saturday, Sunday, or bank holiday, my Payments will be deducted on the next business day following the due date, and you will credit my Payments as if it had been received on the due date.  I also authorize you to withdraw Payments for other amounts I owe under the Agreement when due, including late fee and returned item fees, subject to any notice required by the Electronic Fund Transfers Act and Regulation E and the terms of  this  Authorization.   I  agree  to  keep  sufficient  funds  in  the  Account  on  the  due  date  so  that  the Payments can be made in the required amount.   You will not attempt to withdraw Payments from my  Account  more  than  twice  for  any  single  payment  due.   I  further  authorize  you  to  credit  my Account through electronic fund transfers in the event any credit or refund is due to me under this Authorization or under the Agreement.
              </li>
              <li className="pb-1">
                <b>Rejecting Payments:</b>
                {' '}
                My failure to keep sufficient funds in the Account, or any declined debit card EFT  Payments,  will  be  an  event  of  default  under  this  Authorization  and  you  will  have  the  right  to terminate this Authorization. I will be responsible for any EFT Payments that do not clear, as well as any dishonored check fees, including those that may be discovered after the Agreement is apparently paid off such as by refinancing or otherwise.
              </li>
              <li className="pb-1">
                <b>Returned Items Fee:</b>
                {' '}
                If my Payment is returned unpaid, I authorize you to make a one-time EFT Payment from my Account to collect a fee of up to $
                {fee}
                . In the event a debit card was used to process my EFT Payment, I understand that such a returned item fee may be debited for every chargeback attempt I initiate.
              </li>
              <li className="pb-1">
                <b>Late Payment Fee:</b>
                {' '}
                If my monthly payment under the Agreement is untimely, I authorize you to make a one-time EFT Payment from my Account to collect a fee of up to $
                {fee}
                {' '}
                for each payment that is late.
              </li>
              <li className="pb-1">
                <b>Prepayment:</b>
                {' '}
                If I make a full monthly payment before the scheduled transfer date, there will still be an EFT Payment for that month.
              </li>
              <li className="pb-1">
                <b>Cancellation by Borrower:</b>
                {' '}
                I can cancel this Authorization by giving you or my financial institution oral or written notice of the revocation at least three (3) business days before the date a Payment is scheduled to occur. I can contact you at (888) 502-8085 to cancel this Authorization within the following hours: Monday – Friday 7:00am-6:00pm and Saturday 7:00am-4:00pm PST. Alternatively, I can mail in a written request of cancellation to the following address: 15021 Ventura Blvd, #879 Sherman Oaks, CA 91403.
              </li>
              <li className="pb-1">
                <b>Verification.</b>
                {' '}
                If there is any missing or erroneous information in or with my Agreement regarding my Account, I authorize you to verify and correct such information.
              </li>
              <li className="pb-1">
                <b>Rights Regarding Varying Amounts:</b>
                {' '}
                I have the right to receive notice of all EFT Payments that vary from the preauthorized amount, or from the previous EFT Payment amount. I elect to receive, and you shall provide, such notice of the amount and date of the transfer at least ten (10) days before the scheduled date of transfer only when a withdrawal exceeds the preauthorized amount, or from the previous EFT Payment amount, by more than $30.01. You may withdraw up to this amount in the event I miss a payment, which may include a combination of returned item fees, late fees and additional interest.
              </li>
              <li className="pb-1">
                <b>Procedures Upon Payment in Full:</b>
                {' '}
                If an overpayment is otherwise made, you will refund to me the amount exceeding any amount due as soon as reasonably possible upon discovery of the overpayment, and I agree that this is a reasonable procedure. If there is a balance remaining after the scheduled final due date of the Agreement, I authorize you to continue to debit my Account in the amount of the Payments at regular intervals until the unpaid balance is paid. Although you are authorized to continue these Payments, you are under no obligation to do so.
              </li>
              <li className="pb-1">
                <b>Assignment:</b>
                {' '}
                I agree that you may assign this Authorization and all rights and duties you have hereunder to any third party. I understand that my consent is not required for you to make an assignment under this Section 10.
              </li>
            </ol>
            <p><b>BY SIGNING THIS DOCUMENT, I ACKNOWLEDGE THAT I HAVE READ THE TERMS AND CONDITIONS OF THIS AUTHORIZATION ABOVE AND AGREE TO BE BOUND BY ITS TERMS. I ACKNOWLEDGE THAT I HAVE RECEIVED A COPY OF THIS AUTHORIZATION. I AM NOT REQUIRED TO SIGN THIS AUTHORIZATION TO OBTAIN A LOAN. THIS AUTHORIZATION IS FOR MY CONVENIENCE IN MAKING PAYMENTS UNDER THE AGREEMENT. BY SIGNING THIS AUTHORIZATION, I ACKNOWLEDGE THAT I VOLUNTARILY CHOOSE TO PAY ELECTRONICALLY, AND I CHOOSE ONLY TO RECEIVE NOTICE OF VARYING TRANSFERS WHEN A TRANSFER EXCEEDS THE RANGE SPECIFIED ABOVE.</b></p>
            <p>
              <b>In Case of Errors or Questions About Your EFT Payments:</b>
              {' '}
              Call us at (888) 502-8085, write us at 15021 Ventura Blvd, #879 Sherman Oaks, CA 91403 or email us at customerservice@servicingusa.com as soon as you can if you think your statement or receipt is wrong, or if you need more information about a transfer listed on the statement or receipt. We must hear from you no later than sixty (60) days after we sent the FIRST statement on which the problem or error appeared. When you contact us, please be ready to provide the following information:
            </p>
            <ol>
              <li>Tell us your name and account number (if any);</li>
              <li>Describe the error or the transfer you are unsure about, and explain as clearly as you can why you believe it is an error or why you need more information;</li>
              <li>Tell us the dollar amount of the suspected error.</li>
            </ol>
            <p>If you tell us orally, we may require that you send us your complaint or question in writing within ten (10) business days. We will determine whether an error occurred within ten (10) business days after we hear from you and will correct any error promptly. If we need more time, however, we may take up to forty-five (45) days to investigate your complaint or question. If we decide to do this, we will credit your Account within ten (10) business days for the amount you think is in error, so that you will have the use of the money during the time it takes us to complete our investigation. If we ask you to put your complaint or question in writing and we do not receive it within ten (10) business days, we may not credit your Account. For new accounts, we may take up to twenty (20) business days to credit your Account for the amount you think is in error. We will tell you the results within three (3) business days after completing our investigation. If we decide that there was no error, we will send you a written explanation. You may ask for copies of the documents that we used in our investigation. You may also request a payment in full letter when your account is paid in full. We will send the letter to your address on file.</p>
          </>
        ) : (
          <>
            <p><u><b>FOR ACH AUTHORIZATIONS</b></u></p>
            <p>In this Electronic Funds Transfer Authorization (“Authorization”), “I,” “me,” “my,” “we” and “our” refer to the Buyer and any Co-Buyer under the Retail Installment Contract (“Agreement”). This Authorization allows preauthorized Electronic Funds Transfer payments to and from my designated bank account (“Account”) to [Merchant Name] and/or ServicingUSA as its servicing agent and/or to its affiliates, successor in interests, or assignees (collectively “you”). I understand that the debit will reflect on my account as “SERVICINGUSA LOAN PMT.”</p>
            <p>By signing the Authorization, I agree to the following terms:</p>
            <ol>
              <li className="pb-1">
                <b>Authorization:</b>
                {' '}
                “Electronic Funds Transfer Authorization” means I authorize you to automatically withdraw funds from my Account (“Electronic Funds Transfer Payment”) through an automated clearing house transfer (electronic debiting of my Account) or by bank draft (remotely created check or “RCC”) in the amount equal to the preauthorized amount on the designated date(s). If the designated date falls on a Saturday, Sunday, or holiday, my payment will be deducted on the next business day following the designated date, and you will credit my payment as if it had been received on the designated date. I also authorize you to withdraw funds for other amounts I owe under the Agreement when due, subject to any notice required by the Electronic Funds Transfer Act and Regulation E and the terms of this Authorization.  I agree to keep sufficient funds in the Account on the due date so that the payment can be made in the required amount. If there are insufficient funds in my Account, you may initiate a second debit to my Account or attempt a second presentment of a remotely created check. You will not attempt to debit my Account or present a remotely created check drawn on my Account more than twice for any single payment due.
              </li>
              <li className="pb-1">
                <b>Checking Account:</b>
                {' '}
                I will keep sufficient available funds in my Account to cover all payments to you under the Agreement.
              </li>
              <li className="pb-1">
                <b>Rejecting Electronic Funds Transfers Payments:</b>
                {' '}
                My failure to keep sufficient funds in the Account will be an event of default under this Authorization and you will have the right to terminate this Authorization. I will be responsible for any Electronic Funds Transfer Payments that do not clear, as well as any dishonored check fees, including those that may be discovered after the Agreement is apparently paid off such as by refinancing or otherwise.
              </li>
              <li className="pb-1">
                <b>Returned Items Fee:</b>
                {' '}
                If my payment is returned unpaid, I authorize you to make a one-time electronic fund transfer from my account to collect a fee of up to $
                {fee}
                .
              </li>
              <li className="pb-1">
                <b>Prepayment:</b>
                {' '}
                If I make a full monthly payment at least two (2) business days before the scheduled transfer date, there will be no Electronic Funds Transfer Payment for that month.
              </li>
              <li className="pb-1">
                <b>Cancellation by Buyer:</b>
                {' '}
                I can cancel this Authorization by giving you or my Financial Institution notice of the revocation three (3) business days before the date an Electronic Funds Transfer Payment is scheduled to occur. I can contact you at 888-502-8085 to effectuate cancellation within the following hours: Monday – Friday 7:00am-6:00pm and Saturday 7:00am-4:00pm PST.
              </li>
              <li className="pb-1">
                <b>Verification.</b>
                {' '}
                If there is any missing or erroneous information in or with my credit application regarding my Bank Account, I authorize you to verify and correct such information.
              </li>
              <li className="pb-1">
                <b>Rights Regarding Varying Amounts:</b>
                {' '}
                I have the right to receive notice of all Electronic Funds Transfer Payments that vary from a preauthorized amount. I elect to receive such notice only when a withdrawal exceeds the preauthorized amount by more than $15.00. You may withdraw up to this amount in the event I miss a payment. Such withdrawal may also include additional interest.
              </li>
              <li className="pb-1">
                <b>Procedures Upon Payment in Full:</b>
                {' '}
                I understand that when my Agreement is paid off and you send notice to my Bank to cease Electronic Funds Transfer Payments, occasionally a bank fails to stop Electronic Funds Transfer Payments in a timely manner. If this occurs, or if an overpayment is otherwise made, you will refund to me the amount exceeding any amount due as soon as reasonably possible upon discovery of the overpayment, and I agree that this is a reasonable procedure. If there is a balance remaining after the scheduled final due date of the Agreement, I authorize you to continue to debit my Account in the amount of the Electronic Funds Transfer Payment at regular intervals until the unpaid balance is paid. Although you are authorized to continue these Electronic Funds Transfer Payments, you are under no obligation to do so.
              </li>
              <li className="pb-1">
                <b>Assignment:</b>
                {' '}
                I agree that you may assign this Agreement and all rights and duties you have hereunder to any third party. I understand that my consent is not required for you to make an assignment under this section 10.
              </li>
            </ol>
            <p><b>BY SIGNING THIS DOCUMENT, I ACKNOWLEDGE THAT I HAVE READ THE TERMS AND CONDITIONS OF THIS AUTHORIZATION ABOVE AND AGREE TO BE BOUND BY ITS TERMS. I ACKNOWLEDGE THAT I HAVE RECEIVED A COPY OF THIS AUTHORIZATION. THIS EFT AUTHORIZATION IS FOR MY CONVENIENCE IN MAKING PAYMENTS UNDER THE AGREEMENT. BY SIGNING THIS EFT AUTHORIZATION, I ACKNOWLEDGE THAT I VOLUNTARILY CHOOSE TO PAY ELECTRONICALLY, AND I CHOOSE ONLY TO RECEIVE NOTICE OF VARYING TRANSFERS WHEN A TRANSFER EXCEEDS THE RANGE SPECIFIED ABOVE.</b></p>
            <p><u>In Case of Errors or Questions About Your Electronic Transfers</u></p>
            <p>If you believe your statement or receipt is wrong, or if you need more information about a transfer listed on your statement or receipt, contact us as soon as you can by:</p>
            <ol>
              <li>Calling us at (888) 502-8085</li>
              <li>Writing us at 15021 Ventura Blvd, #879 Sherman Oaks, CA 91403 or</li>
              <li>Emailing us at customerservice@servicingusa.com</li>
            </ol>
            <p>We must hear from you no later than sixty (60) days after we sent the FIRST statement on which the problem or error appeared.  When you contact us, please:</p>
            <ol>
              <li>Tell us your name and account number (if any).</li>
              <li>Describe the error or the transfer you are unsure about, and explain as clearly as you can why you believe it is an error or why you need more information.</li>
              <li>Tell us the dollar amount of the suspected error.</li>
            </ol>
            <p>If you tell us orally, we may require that you send us your complaint or question in writing within ten (10) business days. We will determine whether an error occurred within ten (10) business days after we hear from you and will correct any error promptly. If we need more time, however, we may take up to forty-five (45) days to investigate your complaint or question. If we decide to do this, we will credit your account within ten (10) business days for the amount you think is in error, so that you will have the use of the money during the time it takes us to complete our investigation. If we ask you to put your complaint or question in writing and we do not receive it within ten (10) business days, we may not credit your account. For new accounts, we may take up to twenty (20) business days to credit your account for the amount you think is in error. We will tell you the results within three (3) business days after completing our investigation. If we decide that there was no error, we will send you a written explanation. You may ask for copies of the documents that we used in our investigation. You may also request a payment in full letter when your account is paid in full. We will send the letter to your address on file.</p>
            <br />
            <br />
            <p><u><b>FOR DEBIT CARD AUTHORIZATIONS</b></u></p>
            <p>In this Electronic Funds Transfer Authorization (“Authorization”), “I,” “me,” “my,” “we” and “our” refer to the Buyer and any Co-Buyer under that certain Retail Installment Contract (“Agreement”). This Authorization allows preauthorized Electronic Funds Transfer Payments (“Payments”) to and from my bank account (“Account”) to [Merchant Name] and/or ServicingUSA as its servicing agent and/or to its affiliates, successor in interests, or assignees (collectively “you”) by charging my designated debit card (“Debit Card”) that is associated with the Account. I understand that the Payment will reflect on my account as “SERVICINGUSA LOAN PMT.”</p>
            <p>By signing the Authorization, I agree to the following terms:</p>
            <ol>
              <li className="pb-1">
                <b>Authorization:</b>
                {' '}
                I authorize you to automatically withdraw Payments from my Account by charging my Debit Card associated with the Account (electronic debiting of my Account) in the amount equal to the preauthorized amount on the designated date(s). If the designated date falls on a Saturday, Sunday, or holiday, my Payment will be deducted on the next business day following the designated date, and you will credit my Payment as if it had been received on the designated date. I also authorize you to withdraw Payments for other amounts I owe under the Agreement when due, subject to any notice required by the Electronic Funds Transfer Act and Regulation E and the terms of this Authorization. I agree to keep sufficient funds in the Account on the due date so that the Payment can be made in the required amount. If a Payment from my Account is ever declined, you may initiate a second Payment attempt from my Account. You will not attempt to debit my Account more than twice for any single payment due.
              </li>
              <li className="pb-1">
                <b>Checking Account:</b>
                {' '}
                I will keep sufficient available funds in my Account to cover all Payments to you under the Agreement.
              </li>
              <li className="pb-1">
                <b>Rejected or Declined Payments:</b>
                {' '}
                Rejected or declined Payments from my Account will be an event of default under this Authorization, and you will have the right to terminate this Authorization. I will be responsible for any Payments that do not clear, including those that may be discovered after the Agreement is apparently paid off such as by refinancing or otherwise.
              </li>
              <li className="pb-1">
                <b>Prepayment:</b>
                {' '}
                If I make a full monthly payment at least two (2) business days before the designated date, there will be no Payment for that month.
              </li>
              <li className="pb-1">
                <b>Cancellation by Buyer:</b>
                {' '}
                I can cancel this Authorization by giving you notice of the revocation three (3) business days before the date a Payment is scheduled to occur. I can contact you at (888) 502-8082 to effectuate cancellation within the following hours: Monday – Friday 7:00am-6:00pm and Saturday 7:00am-4:00pm PST.
              </li>
              <li className="pb-1">
                <b>Verification.</b>
                {' '}
                If there is any missing or erroneous information in or with my credit application regarding my Account, I authorize you to verify and correct such information.
              </li>
              <li className="pb-1">
                <b>Rights Regarding Varying Amounts:</b>
                {' '}
                I have the right to receive notice of all Payments that vary from a preauthorized amount. I elect to receive such notice only when a withdrawal exceeds the preauthorized amount by more than $15.00. You may withdraw up to this amount in the event I miss a Payment. Such withdrawal may also include additional interest.
              </li>
              <li className="pb-1">
                <b>Procedures Upon Payment in Full:</b>
                {' '}
                If an overpayment is made when my Agreement is paid off, you will refund to me the amount exceeding any amount due as soon as reasonably possible upon discovery of the overpayment, and I agree that this is a reasonable procedure. If there is a balance remaining after the scheduled final due date of the Agreement, I authorize you to continue to debit my Account in the amount of the Electronic Funds Transfer Payment at regular intervals until the unpaid balance is paid. Although you are authorized to continue these Electronic Funds Transfer Payments, you are under no obligation to do so.
              </li>
              <li className="pb-1">
                <b>Assignment:</b>
                {' '}
                I agree that you may assign this Authorization and all rights and duties you have hereunder to any third party. I understand that my consent is not required for you to make an assignment under this Section 9.
              </li>
            </ol>
            <p><b>BY SIGNING THIS DOCUMENT, I ACKNOWLEDGE THAT I HAVE READ THE TERMS AND CONDITIONS OF THIS AUTHORIZATION ABOVE AND AGREE TO BE BOUND BY ITS TERMS. I ACKNOWLEDGE THAT I HAVE RECEIVED A COPY OF THIS AUTHORIZATION. THIS ELECTRONIC FUNDS TRANSFER (“EFT”) AUTHORIZATION IS FOR MY CONVENIENCE IN MAKING PAYMENTS UNDER THE AGREEMENT. BY SIGNING THIS EFT AUTHORIZATION, I ACKNOWLEDGE THAT I VOLUNTARILY CHOOSE TO PAY ELECTRONICALLY, AND I CHOOSE ONLY TO RECEIVE NOTICE OF VARYING TRANSFERS WHEN A TRANSFER EXCEEDS THE RANGE SPECIFIED ABOVE.</b></p>
            <p>In Case of Errors or Questions About Your Electronic Transfers: Call us at (888) 502-8082, write us at 15021 Ventura Blvd, #879, Sherman Oaks, CA 91403, or email us at customerservice@servicingusa.com as soon as you can if you think your statement or receipt is wrong or if you need more information about a transfer listed on the statement or receipt. We must hear from you no later than sixty (60) days after we sent the FIRST statement on which the problem or error appeared.</p>
            <ol>
              <li>Tell us your name and account number (if any).</li>
              <li>Describe the error or the transfer you are unsure about, and explain as clearly as you can why you believe it is an error or why you need more information.</li>
              <li>Tell us the dollar amount of the suspected error.</li>
            </ol>
            <p>If you tell us orally, we may require that you send us your complaint or question in writing within ten (10) business days. We will determine whether an error occurred within ten (10) business days after we hear from you and will correct any error promptly. If we need more time, however, we may take up to forty-five (45) days to investigate your complaint or question. If we decide to do this, we will credit your account within ten (10) business days for the amount you think is in error, so that you will have the use of the money during the time it takes us to complete our investigation. If we ask you to put your complaint or question in writing and we do not receive it within ten (10) business days, we may not credit your account. For new accounts, we may take up to twenty (20) business days to credit your account for the amount you think is in error. We will tell you the results within three (3) business days after completing our investigation. If we decide that there was no error, we will send you a written explanation. You may ask for copies of the documents that we used in our investigation. You may also request a payment in full letter when your account is paid in full. We will send the letter to your address on file.</p>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

TermsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  fee: PropTypes.string.isRequired,
  channel: PropTypes.string,
};

TermsModal.defaultProps = {
  channel: 'DTM',
};

export default TermsModal;
