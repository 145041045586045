import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Input from 'components/Form/Input';

const EditEmail = (props) => {
  const [disabled, setDisabled] = useState(true);

  const handleValueChange = () => {
    setDisabled(false);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle}>Edit Email Address</ModalHeader>
      <ModalBody>

        <Input
          type="email"
          name="email-address"
          label="Email Address"
          id="emailAddress"
          defaultValue="kylefoundry@gmail.com"
          onChange={handleValueChange}
        />

        <Button color="primary" className="w-100" disabled={disabled}>Save</Button>

      </ModalBody>
    </Modal>
  );
};

EditEmail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default EditEmail;
