import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { signIn } from 'actions/auth';
import Input from 'components/Form/Input';
import Validator from 'components/Validator/Validator';
import { Button, ButtonLink } from 'components/Button';
import { numberUnmask } from 'utils/masks';

import {
  Container,
  Row,
  Form,
  Col,
  Card,
  CardHeader,
  CardBody,
  Alert,
} from 'reactstrap';
import { appConfig } from 'config/appConfig';

import schema from './loginSchema';
import './style.scss';

const Login = (props) => {
  const { from } = props.location.state || { from: { pathname: '/' } };
  const { validator: { values, errors } } = props;
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler } } = props;
    const value = event.target.value.trim();
    onChangeHandler(event.target.name, value);
  };

  const handleSubmitFrom = (data, e) => {
    e.preventDefault();
    const { validator: { validate } } = props;

    if (appConfig.isInMaintenance) {
      toast.error(
        (
          <div>
            <h5>The Customer Portal is currently under maintenance. Please come back later.</h5>
          </div>
        ),
        { autoClose: true }
      );
    } else if (validate(schema).isValid) {
      const formData = {
        ...data,
        username: `${data.username}`.toLowerCase(),
      };
      setIsLoading(true);
      props.signIn({
        data: formData,
        success: () => {
          setRedirectToReferrer(true);
          setIsLoading(true);
        },
        fail: (err) => {
          setIsLoading(false);

          if (err.code === 'UserNotFoundException') {
            toast.error(
              (
                <div>
                  <h5>{err.message}</h5>
                </div>
              ),
              { autoClose: true }
            );
          } else if (err.code === 'NotAuthorizedException') {
            toast.error(
              (
                <div>
                  <h5>{err.message}</h5>
                </div>
              ),
              { autoClose: true }
            );
          } else if (err.code === 'PasswordResetRequiredException') {
            sessionStorage.setItem('reset-user', data.username);
            props.history.push('/forgot');
          } else {
            toast.error(
              (
                <div>
                  <h5>{err.message}</h5>
                </div>
              ),
              { autoClose: true }
            );
          }
        },
      });
    } else {
      console.log('api error');
    }
  };

  const forgotPassworrd = () => {
    props.history.push('/forgot');
  };

  const registUser = () => {
    props.history.push('/registration');
  };

  return redirectToReferrer
    ? <Redirect to={from} />
    : (
      <>
        {
          appConfig.isInMaintenance && (
            <Alert color="danger" className="text-center">
              The Customer Portal will be under maintenance from 3:00 AM to 5:00 AM (Pacific Time). We apologize for the inconvenience.
            </Alert>
          )
        }
        <div className="container new-design">
          <Container fluid>
            <Form onSubmit={handleSubmitFrom}>
              <Row className="align-items-center" style={{ minHeight: '500px' }}>
                <Col className="text-center">
                  <Row>
                    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                      <Card className="dark-shadow">
                        <CardHeader>Portal Account Sign In</CardHeader>
                        <CardBody>
                          <Row>
                            <Col>
                              <Input
                                name="username"
                                label="Username"
                                value={values.username}
                                onChange={handleInputChange}
                                hasError={!!errors.username}
                                hasValue
                                placeHolder="username"
                                isBadgeVisible={false}
                                isRequired
                                errorMessage={errors.username}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Input
                                name="password"
                                type="password"
                                label="Password"
                                value={values.password}
                                onChange={handleInputChange}
                                hasError={!!errors.password}
                                hasValue
                                placeHolder="password"
                                isBadgeVisible={false}
                                isRequired
                                errorMessage={errors.password}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                className={cn('w-100')}
                                onClick={handleSubmitFrom.bind(null, values)}
                                isLoading={isLoading}
                                color="primary"
                                type="submit"
                              >
                                Sign In
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Row>
                        <Col className="text-left" style={{ color: '#3989E3' }}>
                          <ButtonLink onClick={forgotPassworrd} style={{ cursor: 'pointer' }}>Forgot password?</ButtonLink>
                        </Col>
                        <Col className="text-right" style={{ color: '#3989E3' }}>
                          <ButtonLink onClick={registUser} style={{ cursor: 'pointer' }}>New User?</ButtonLink>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
        <Alert color="danger" className="text-center" style={{ color: '#d82027', backgroundColor: '#fff6f6', borderColor: '#fff6f6' }}>
          Massachusetts residents, we are unable to receive payments through this site. Your payment should be made as provided in your monthly statement. If you have questions regarding your loan or want to make a payment, please call
          {' '}
          {numberUnmask(appConfig.massachusettsErrorPhone) ? (
            <a href={`tel:+1${numberUnmask(appConfig.massachusettsErrorPhone)}`}>{appConfig.massachusettsErrorPhone}</a>
          ) : appConfig.massachusettsErrorPhone}
        </Alert>
      </>
    );
};

Login.propTypes = {
  validator: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  Validator(schema),
  connect(
    (state) => ({
      auth: state.auth,
    }),
    {
      signIn,
    }
  )
)(Login);
