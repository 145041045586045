import he from 'he';

const convertSpecialChar = (src) => {
  if (!src) {
    return '';
  }
  return src.toString().replace(/"/g, '\'');
};

const convertToCSV = (args) => {
  const data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  const columnWrapper = args.columnWrapper || '"';
  const columnDelimiter = args.columnDelimiter || ',';
  const lineDelimiter = args.lineDelimiter || '\n';

  const keys = Object.keys(data[0]);

  let result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data?.length && data.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += columnWrapper + convertSpecialChar(item[key]) + columnWrapper;
      ctr++;
    });
    result += lineDelimiter;
  });

  return he.decode(result);
};

export const downloadCSV = (csvData, csvFileName) => {
  let csv;
  if (typeof csvData === 'string') {
    csv = csvData;
  } else if (Array.isArray(csvData)) {
    csv = convertToCSV({
      data: csvData,
    });
  }
  if (csv == null) return;

  const blob = new Blob([csv], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', csvUrl);
  link.setAttribute('download', csvFileName);
  link.click();
};
