const loginSchema = {
  username: [{
    rule: 'required',
    error: 'Username is required',
  }],
  password: [{
    rule: 'required',
    error: 'Password is required',
  }],
};

export default loginSchema;
