import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './style.scss';

export const CustomizedSelect = (props) => {
  const {
    data,
    value,
    label,
    className,
    onChange,
    hasError,
    errorMessage,
    isRequired,
    isBadgeVisible,
    placeHolder,
    isDisabled,
    hasDefault,
    isErrorVisible,
    ...restProps
  } = props;
  const [isVisited, setIsVisited] = useState(false);
  const [isRequiredInternal, setIsRequiredInternal] = useState(false);

  const handleBlur = (e) => {
    const { value: targetValue } = e.target;
    setIsVisited(true);
    if (targetValue) {
      setIsRequiredInternal(false);
    } else {
      setIsRequiredInternal(isRequired);
    }
  };

  return (
    <label className={cn({ selectLabel: true, 'has-value': true, required: (isVisited && isRequiredInternal) || hasError, disabled: isDisabled })}>
      {
        label
        && (
        <span>
          {label}
          {' '}
          {
          isBadgeVisible && ((isVisited && isRequired) || hasError) && <em>Required</em>
          }
          {
            isVisited && !isRequired && <em>Optional</em>
          }
        </span>
        )
      }
      <select
        onChange={onChange}
        onBlur={handleBlur}
        className="select"
        disabled={isDisabled}
        {...restProps}
        value={value}
      >
        { hasDefault && <option value="">Select ...</option> }
        {
          data && data.map((item, key) => (<option key={key} value={item.value}>{item.title}</option>))
        }
      </select>
      {
        isErrorVisible
        && <div className="error">{errorMessage !== 'The input field is required' && errorMessage}</div>
      }
    </label>
  );
};

CustomizedSelect.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  placeHolder: PropTypes.string,
  isBadgeVisible: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasDefault: PropTypes.bool,
  isErrorVisible: PropTypes.bool,
};

CustomizedSelect.defaultProps = {
  className: '',
  hasError: false,
  errorMessage: '',
  label: null,
  name: '',
  value: '',
  isRequired: false,
  placeHolder: 'Select ...',
  data: null,
  isBadgeVisible: true,
  isDisabled: false,
  hasDefault: true,
  isErrorVisible: true,
};

export default CustomizedSelect;
