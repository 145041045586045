import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import cn from 'classnames';
import {
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import Input from 'components/Form/Input';
import Validator from 'components/Validator/Validator';
import { Button } from 'components/Button';
import { ButtonLink } from 'components/Button/ButtonLink';
import { appConfig } from 'config/appConfig';

import schema from './forgotSchema';
import emailFormSchema from './emailFormSchema';
import style from './style.scss';

const Forgot = (props) => {
  const { from } = props.location.state || { from: { pathname: '/' } };
  const { validator: { values, errors } } = props;
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  useEffect(() => {
    const resetUser = sessionStorage.getItem('reset-user');
    if (resetUser) { // reset password required
      sessionStorage.removeItem('reset-user');
      const poolData = {
        UserPoolId: appConfig.userPoolId,
        ClientId: appConfig.clientId,
      };
      const userPool = new CognitoUserPool(poolData);

      const userData = {
        Username: resetUser,
        Pool: userPool,
      };

      const cognitoUserTemp = new CognitoUser(userData);

      setIsLoading(true);
      cognitoUserTemp.forgotPassword({
        onSuccess: () => {
          setIsLoading(false);
          setStep(2);
          setCognitoUser(cognitoUserTemp);
        },
        onFailure: (err) => {
          setIsLoading(false);
          toast.error(
            (
              <div>
                <h5>{get(err, 'message', 'Error occured')}</h5>
              </div>
            ),
            { autoClose: true }
          );
        },
      });
    }
  }, []);

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler } } = props;
    onChangeHandler(event.target.name, event.target.value.trim());
  };

  const handleSubmitFrom = (e) => {
    e.preventDefault();
    const { validator: { validate } } = props;

    if (validate(emailFormSchema).isValid) {
      const poolData = {
        UserPoolId: appConfig.userPoolId,
        ClientId: appConfig.clientId,
      };
      const userPool = new CognitoUserPool(poolData);

      const userData = {
        Username: `${values.username}`.toLowerCase(),
        Pool: userPool,
      };

      const cognitoUserTemp = new CognitoUser(userData);

      setIsLoading(true);
      cognitoUserTemp.forgotPassword({
        onSuccess: () => {
          setStep(2);
          setCognitoUser(cognitoUserTemp);
          setIsLoading(false);
        },
        onFailure: (err) => {
          setIsLoading(false);
          toast.error(
            (
              <div>
                <h5>{get(err, 'message', 'Error occured')}</h5>
              </div>
            ),
            { autoClose: true }
          );
        },
      });
    } else {
      console.log('api error');
    }
  };

  const handleResetPassword = (data, e) => {
    e.preventDefault();

    const { validator: { validate } } = props;
    if (validate(schema).isValid) {
      setIsLoading(true);
      cognitoUser.confirmPassword(values.verificationCode, values.newPassword, {
        onSuccess: () => {
          toast.success(
            (
              <div>
                <h5>Great! Your password has been successfully reset. Please use the new password to login</h5>
              </div>
            ),
            { autoClose: true }
          );
          setRedirectToReferrer(true);
        },
        onFailure: (err) => {
          setIsLoading(false);
          toast.error(
            (
              <div>
                <h5>{get(err, 'message', 'Failed to reset your password.')}</h5>
              </div>
            ),
            { autoClose: true }
          );
        },
      });
    } else {
      console.log('api error');
    }
  };

  const handleVerificationCodeClick = (e) => {
    e.preventDefault();
    const { validator: { validate } } = props;

    if (validate(emailFormSchema).isValid) {
      const poolData = {
        UserPoolId: appConfig.userPoolId,
        ClientId: appConfig.clientId,
      };
      const userPool = new CognitoUserPool(poolData);

      const userData = {
        Username: `${values.username}`.toLowerCase(),
        Pool: userPool,
      };

      const cognitoUserTemp = new CognitoUser(userData);

      setStep(2);
      setCognitoUser(cognitoUserTemp);
    } else {
      console.log('api error');
    }
  };

  if (redirectToReferrer) return <Redirect to={from} />;

  return (
    <form onSubmit={handleSubmitFrom} style={{ minHeight: 650 }} className="d-flex justify-content-center align-items-center">
      <Container fluid>
        <Col sm={{ size: 6, offset: 3 }}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Card className="animated fadeInUp delay-300ms">
                    <CardHeader>Reset Password</CardHeader>
                    <CardBody>
                      {
                        step === 1
                          ? (
                            <Input
                              name="username"
                              label="Username"
                              onChange={handleInputChange}
                              value={values.username}
                              hasError={!!errors.username}
                              hasValue
                              placeHolder="username"
                              isBadgeVisible={false}
                              isRequired
                              isDisabled={isLoading}
                              errorMessage={errors.username}
                            />
                          )
                          : (
                            <>
                              <p>
                                Your request was received. If
                                {' '}
                                {values.username}
                                {' '}
                                is registered in our system, you will receive instructions to reset your password in the next few minutes. Please remember to check your spam or junk folder, too!
                              </p>
                              <Row>
                                <Col sm={12} lg={12}>
                                  <Input
                                    name="verificationCode"
                                    label="Verification Code"
                                    onChange={handleInputChange}
                                    value={values.verificationCode}
                                    hasError={!!errors.verificationCode}
                                    hasValue
                                    placeHolder="Verification Code"
                                    isBadgeVisible={false}
                                    isRequired
                                    errorMessage={errors.verificationCode}
                                  />
                                </Col>
                                <Col sm={12} lg={12}>
                                  <Input
                                    name="newPassword"
                                    type="password"
                                    label="New Password"
                                    onChange={handleInputChange}
                                    value={values.newPassword}
                                    hasError={!!errors.newPassword}
                                    hasValue
                                    placeHolder="New Password"
                                    isBadgeVisible={false}
                                    isRequired
                                    errorMessage={errors.newPassword}
                                  />
                                </Col>
                                <Col sm={12} lg={12}>
                                  <Input
                                    name="confirmPassword"
                                    type="password"
                                    label="Confirm Password"
                                    onChange={handleInputChange}
                                    value={values.confirmPassword}
                                    hasError={!!errors.confirmPassword}
                                    hasValue
                                    placeHolder="Confirm Password"
                                    isBadgeVisible={false}
                                    isRequired
                                    errorMessage={errors.confirmPassword}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <ul style={{ fontSize: 12 }}>
                                    <li>Password must contain a lower case letter</li>
                                    <li>Password must contain an upper case letter</li>
                                    <li>Password must contain a special character</li>
                                    <li>Password must contain a number</li>
                                    <li>Password must contain at least 8 characters</li>
                                  </ul>
                                </Col>
                              </Row>
                            </>
                          )
                      }
                    </CardBody>
                  </Card>
                  {
                    step === 1
                      ? (
                        <div className="text-center">
                          <Button
                            className={cn('small w-100', style.button)}
                            onClick={handleSubmitFrom}
                            isLoading={isLoading}
                            color="primary"
                          >
                            Send Verification Code
                          </Button>
                          <div className="mt-1">
                            <ButtonLink className="btn-link cursor-pointer" onClick={handleVerificationCodeClick}>Already received a verification code?</ButtonLink>
                          </div>
                        </div>
                      )
                      : (
                        <Button
                          className={cn('small w-100', style.button)}
                          onClick={handleResetPassword.bind(null, values)}
                          isLoading={isLoading}
                          color="primary"
                        >
                          Reset Password
                        </Button>
                      )
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
    </form>
  );
};

Forgot.propTypes = {
  validator: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  Validator(schema),
  connect((state) => ({
    auth: state.auth,
  }))
)(Forgot);
