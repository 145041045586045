import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import NotificationSystem from 'react-notification-system';

import {
  Container, Row, Form, Col, Card, CardHeader, CardBody,
} from 'reactstrap';
import Input from 'components/Form/Input';
import { Button } from 'components/Button';
import Validator from 'components/Validator/Validator';

import { postForkInfo } from 'actions/dashboard';

import { numberUnmask } from 'utils/masks';
import schema from './findCustomerPortalSchema';
import styles from './App.scss'; // eslint-disable-line

export const FindCustomerPortal = (props) => {
  const { validator: { values, errors } } = props;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const notification = useRef(null);

  const handleSubmitFrom = (e) => {
    e.preventDefault();

    const { validator: { validate } } = props;
    if (validate(schema).isValid) {
      setIsSubmitted(true);
      const data = {
        ssn: numberUnmask(values.fullssn),
      };

      props.postForkInfo({
        data,
        success: (res) => {
          window.top.location.href = res.url;
        },
        fail: (res) => {
          setIsSubmitted(false);
          notification.current
          && notification.current.addNotification({
            message: res.message,
            level: 'error',
            position: 'tc',
          });
        },
      });
    } else {
      console.log(errors);
    }
  };

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler } } = props;
    onChangeHandler(event.target.name, event.target.value);
  };

  return (
    <div className="App bg-light findCustomerPortal-form">
      <NotificationSystem ref={notification} />
      <Container fluid>
        <Form onSubmit={handleSubmitFrom}>
          <Row className="align-items-center" style={{ minHeight: '500px' }}>
            <Col lg={{ size: 4, offset: 4 }} md={{ size: 6, offset: 3 }}>
              <Card>
                <CardHeader>Please provide the information below to help us route you to your account.</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <Input
                        name="fullssn"
                        label="Full SSN"
                        value={values.fullssn}
                        onChange={handleInputChange}
                        hasError={!!errors.fullssn}
                        isMasked={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        hasValue
                        placeHolder="Full SSN"
                        isBadgeVisible={false}
                        isRequired
                        errorMessage={errors.fullssn}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        name="zipcode"
                        label="ZIP Code"
                        isMasked={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                        value={values.zipcode}
                        onChange={handleInputChange}
                        hasError={!!errors.zipcode}
                        hasValue
                        placeHolder="zipcode"
                        isBadgeVisible={false}
                        isRequired
                        errorMessage={errors.zipcode}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className={cn('w-100')}
                        onClick={handleSubmitFrom}
                        isLoading={isSubmitted}
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

FindCustomerPortal.propTypes = {
  postForkInfo: PropTypes.func.isRequired,
  validator: PropTypes.object.isRequired,
};

export default compose(
  Validator(schema),
  connect(
    null,
    {
      postForkInfo,
    }
  )
)(FindCustomerPortal);
