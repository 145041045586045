import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { confirmAlert } from 'react-confirm-alert';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';

import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Validator from 'components/Validator/Validator';
import { Button } from 'components/Button';
import Card from 'components/Card';
import { getCognitoUser } from 'utils/aws';

import { signOut } from 'actions/auth';
import schema from './changeSchema';

const ResetPassword = (props) => {
  const { validator: { values, errors } } = props;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const notification = useRef(null);

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler } } = props;
    onChangeHandler(event.target.name, event.target.value.trim());
  };

  const resetPassword = (data) => {
    const { signOut: signOutAction, validator: { setValues } } = props;
    const cognitoUser = getCognitoUser();
    setLoading(true);
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          setError(err.message || '');
          setLoading(false);
          return;
        }
        if (session.isValid()) {
          cognitoUser.changePassword(data.oldPassword, data.newPassword, (err1, result) => {
            if (err1) {
              setError(err1.message);
              return;
            }
            setValues({});
            setError(result);
            setLoading(false);
          });
        } else {
          signOutAction();
        }
      });
    }
  };

  const handleSubmitFrom = (data, e) => {
    e.preventDefault();
    const { validator: { validate } } = props;

    if (validate(schema).isValid) {
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to do this?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => resetPassword(data),
          },
          {
            label: 'No',
          },
        ],
      });
    } else {
      console.log('api error');
    }
  };

  return (
    <div className="changePassword-page">
      <form onSubmit={handleSubmitFrom}>
        <Container fluid>
          <Row>
            <Col sm={{ size: 10, offset: 1 }}>
              <NotificationSystem ref={notification} />
              <FormGroup className="form-group">
                <Row>
                  <Col sm={12} md={12} className="positionRelative">
                    <label className="error mb-1 text-center">{error}</label>
                    <Row>
                      <Col sm={12} md={12}>
                        <Card
                          title="Reset Password"
                          isSectionWrapped
                          className="animated fadeInUp delay-100ms"
                        >
                          <Row>
                            <Col sm={12} lg={12}>
                              <Input
                                name="oldPassword"
                                label="Old Password"
                                onChange={handleInputChange}
                                value={values.oldPassword}
                                hasError={!!errors.oldPassword}
                                hasValue
                                placeHolder="Old Password"
                                isBadgeVisible={false}
                                isRequired
                                type="password"
                                errorMessage={errors.oldPassword}
                              />
                            </Col>
                            <Col sm={12} lg={12}>
                              <Input
                                name="newPassword"
                                label="New Password"
                                onChange={handleInputChange}
                                value={values.newPassword}
                                hasError={!!errors.newPassword}
                                hasValue
                                placeHolder="New Password"
                                isBadgeVisible={false}
                                isRequired
                                type="password"
                                errorMessage={errors.newPassword}
                              />
                            </Col>
                            <Col sm={12} lg={12}>
                              <Input
                                name="confirmPassword"
                                label="Confirm Password"
                                onChange={handleInputChange}
                                value={values.confirmPassword}
                                hasError={!!errors.confirmPassword}
                                hasValue
                                placeHolder="Confirm Password"
                                isBadgeVisible={false}
                                isRequired
                                type="password"
                                errorMessage={errors.confirmPassword}
                              />
                            </Col>
                            <Col sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4 }}>
                              <Button
                                className="small w-100"
                                onClick={handleSubmitFrom.bind(null, values)}
                                color="primary"
                                isLoading={loading}
                              >
                                Reset Password
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

ResetPassword.propTypes = {
  signOut: PropTypes.func.isRequired,
  validator: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  Validator(schema),
  connect(
    (state) => ({
      auth: state.auth,
    }),
    {
      signOut,
    }
  )
)(ResetPassword);
