import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const CancelPaymentModal = ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader className="no-border" toggle={toggle}>How can I cancel my payment?</ModalHeader>
    <ModalBody className="pt-3 recurring-description">
      <p>If this is not a same-day payment, I can cancel this payment through the &apos;Scheduled Payments&apos; section of this portal. I understand that no payment can be cancelled on or after the Payment Date shown above.</p>
    </ModalBody>
  </Modal>
);

CancelPaymentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default CancelPaymentModal;
