export function validateEmail(email) {
  // eslint-disable-next-line
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
}

export const lowerHere = (str) => str.match(/[a-z]/);
export const upperHere = (str) => str.match(/[A-Z]/);
export const numberHere = (str) => str.match(/[0-9]/);

const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
export const specialHere = (str) => str.match(specialChars);
