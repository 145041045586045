import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CustomInput,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import NumberFormat from 'react-number-format';

import Input from 'components/Form/Input';

const EditPhoneMobile = (props) => {
  const [disabled, setDisabled] = useState(true);

  const handleValueChange = () => {
    setDisabled(false);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle}>Edit Mobile Number</ModalHeader>
      <ModalBody>
        <Input
          label="Mobile Number"
          type="tel"
          name="mobile-number"
          id="mobileNumber"
          tag={NumberFormat}
          format="(###) ###-####"
          defaultValue="5551234567"
          onChange={handleValueChange}
        />
        <FormGroup className="mb-3" check>
          <CustomInput
            type="checkbox"
            name="check"
            id="Check"
            label="Set as primary number"
            onChange={handleValueChange}
            defaultChecked
          />
        </FormGroup>

        <h5>How can we contact you?</h5>

        <FormGroup className="mb-4">
          <CustomInput type="radio" id="contactPreference1" name="contactRadio" label="Call me or text me through automated means" onChange={handleValueChange} defaultChecked />
          <CustomInput type="radio" id="contactPreference2" name="contactRadio" label="Only contact me through non-automated means" onChange={handleValueChange} />
        </FormGroup>

        <Button color="primary" className="w-100" disabled={disabled}>Save</Button>

      </ModalBody>
    </Modal>
  );
};

EditPhoneMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default EditPhoneMobile;
