import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { appConfig } from 'config/appConfig';
import App from './containers/App';
import FindCustomerPage from './containers/FindCustomerPortal';
import ScrollToTop from './components/ScrollToTop';
import configureStore from './store';
import { unregister } from './registerServiceWorker';

import './assets/styles/global.scss';

const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);

const renderApp = () => {
  createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey={appConfig.reCaptchaKey}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/container">
                <FindCustomerPage />
              </Route>
              <Route path="/" exact={false}>
                <ScrollToTop>
                  <App />
                </ScrollToTop>
              </Route>
            </Switch>
          </ConnectedRouter>
        </Provider>
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  );
};

setTimeout(renderApp, 300);

unregister();
