import { call, takeEvery } from 'redux-saga/effects';
import { DashboardConstants } from 'constants/dashboard';
import { request } from 'components/Fetch';
import urls from 'config/urls';

function* fetchLoanDetail(action) {
  yield call(request({
    type: DashboardConstants.FETCH_LOAN_DETAIL,
    method: 'GET',
    url: urls.fetchLoanDetail(action.payload.loanId),
    delay: action.payload.delay ? action.payload.delay * 1000 : null,
  }), action);
}

function* fetchLoanTerm(action) {
  yield call(request({
    type: DashboardConstants.FETCH_LOAN_TERM,
    method: 'GET',
    url: urls.fetchLoanTerm(action.payload),
  }), action);
}

function* fetchTransactionsList(action) {
  yield call(request({
    type: DashboardConstants.FETCH_TRANSACTIONS_LIST,
    method: 'GET',
    url: urls.fetchTransactionsList(action.payload),
  }), action);
}

function* fetchPaymentsList(action) {
  yield call(request({
    type: DashboardConstants.FETCH_PAYMENTS_LIST,
    method: 'GET',
    url: urls.fetchPaymentsList(action.payload),
  }), action);
}

function* fetchDocuments(action) {
  yield call(request({
    type: DashboardConstants.FETCH_DOCUMENTS,
    method: 'GET',
    url: urls.fetchDocuments(action.payload),
  }), action);
}

function* fetchPaymentMethods(action) {
  yield call(request({
    type: DashboardConstants.FETCH_PAYMENT_METHODS,
    method: 'GET',
    url: urls.fetchPaymentMethods(),
  }), action);
}

function* downloadDocument(action) {
  const params = action.payload.split('&');
  const downloadData = yield call(request({
    type: DashboardConstants.DOWNLOAD_DOCUMENT,
    method: 'GET',
    url: urls.downloadDocument(params[0], params[1], params[2]),
    responseType: 'arraybuffer',
  }), action);
  const url = window.URL.createObjectURL(new Blob([downloadData], { type: 'application/pdf' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', params[2]);
  document.body.appendChild(link);
  link.click();
}

function* removePaymentMethod(action) {
  yield call(request({
    type: DashboardConstants.REMOVE_PAYMENTACCOUNT,
    method: 'DELETE',
    url: urls.removePaymentMethod(action.payload.paymentAccountId),
  }), action);
}

function* postPaymentAccount(action) {
  yield call(request({
    type: DashboardConstants.POST_PAYMENT_ACCOUNT,
    method: 'POST',
    url: urls.putPaymentAccount(),
  }), action);
}

function* postDebitCardAccount(action) {
  yield call(request({
    type: DashboardConstants.POST_DEBIT_CARD_ACCOUNT,
    method: 'POST',
    url: urls.postDebitCardAccount(),
  }), action);
}

function* putPaymentAccount(action) {
  yield call(request({
    type: DashboardConstants.PUT_PAYMENT_ACCOUNT,
    method: 'PUT',
    url: urls.putPaymentAccount(),
  }), action);
}

function* makePayment(action) {
  yield call(request({
    type: DashboardConstants.MAKE_PAYMENT,
    method: 'PUT',
    url: urls.fetchPaymentsList(action.payload.loanId),
  }), action);
}

function* fetchCurrentPayoffInfo(action) {
  yield call(request({
    type: DashboardConstants.FETCH_CURRENT_PAYOFF_INFO,
    method: 'GET',
    url: urls.fetchPayoffInfo(action.payload),
  }), action);
}

function* fetchFuturePayoffInfo(action) {
  yield call(request({
    type: DashboardConstants.FETCH_FUTURE_PAYOFF_INFO,
    method: 'GET',
    url: urls.fetchPayoffInfo(action.payload),
  }), action);
}

function* fetchScheduledPayment(action) {
  yield call(request({
    type: DashboardConstants.FETCH_SCHEDULED_PAYMENT,
    method: 'GET',
    url: urls.fetchScheduledPayments(action.payload),
  }), action);
}

function* cancelAutopay(action) {
  yield call(request({
    type: DashboardConstants.CANCEL_AUTOPAY,
    method: 'DELETE',
    url: urls.cancelAutopay(action.payload.data),
  }), action);
}

function* putScheduleAutopay(action) {
  yield call(request({
    type: DashboardConstants.PUT_SCHEDULE_AUTOPAY,
    method: 'PUT',
    url: urls.fetchScheduledPayments(action.payload.loanId),
  }), action);
}

function* getBillingSetting(action) {
  yield call(request({
    type: DashboardConstants.GET_BILLING_SETTING,
    method: 'GET',
    url: urls.getSettings(action.payload),
  }), action);
}

function* putBillingSetting(action) {
  yield call(request({
    type: DashboardConstants.PUT_BILLING_SETTING,
    method: 'PUT',
    url: urls.getSettings(action.payload.loanId),
  }), action);
}

function* fetchCustomerDetail(action) {
  yield call(request({
    type: DashboardConstants.FETCH_CUSTOMER_DETAIL,
    method: 'GET',
    url: urls.fetchCustomerDetail(),
  }), action);
}

function* validateRegistration(action) {
  yield call(request({
    type: DashboardConstants.VALIDATE_REGISTRATION,
    method: 'POST',
    url: urls.validateRegistration(),
  }), action);
}

function* fetchAutopayInfo(action) {
  yield call(request({
    type: DashboardConstants.FETCH_AUTOPAY_INFO,
    method: 'GET',
    url: urls.fetchAutopayInfo(action.payload),
  }), action);
}

function* postForkInfo(action) {
  yield call(request({
    type: DashboardConstants.POST_FORK_INFO,
    method: 'POST',
    url: urls.postForkInfo(action.payload),
  }), action);
}

function* fetchAutopays(action) {
  yield call(request({
    type: DashboardConstants.FETCH_AUTOPAYS,
    method: 'GET',
    url: urls.fetchScheduledPayments(action.payload.loanId),
  }), action);
}

function* fetchLoanCharges(action) {
  yield call(request({
    type: DashboardConstants.FETCH_LOAN_CHARGES,
    method: 'GET',
    url: urls.fetchLoanCharges(action.payload),
  }), action);
}

function* validateBankRoutingNumber(action) {
  const { routingNumber } = action.payload;
  yield call(request({
    type: DashboardConstants.VALIDATE_BANK_ROUTING_NUMBER,
    method: 'GET',
    url: urls.validateRoutingNumber({ routingNumber }),
  }), action);
}

const dashboardSaga = function* () {
  yield takeEvery(DashboardConstants.FETCH_LOAN_DETAIL, fetchLoanDetail);
  yield takeEvery(DashboardConstants.FETCH_TRANSACTIONS_LIST, fetchTransactionsList);
  yield takeEvery(DashboardConstants.FETCH_PAYMENTS_LIST, fetchPaymentsList);
  yield takeEvery(DashboardConstants.FETCH_LOAN_TERM, fetchLoanTerm);
  yield takeEvery(DashboardConstants.FETCH_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeEvery(DashboardConstants.FETCH_DOCUMENTS, fetchDocuments);
  yield takeEvery(DashboardConstants.DOWNLOAD_DOCUMENT, downloadDocument);
  yield takeEvery(DashboardConstants.REMOVE_PAYMENTACCOUNT, removePaymentMethod);
  yield takeEvery(DashboardConstants.PUT_PAYMENT_ACCOUNT, putPaymentAccount);
  yield takeEvery(DashboardConstants.POST_PAYMENT_ACCOUNT, postPaymentAccount);
  yield takeEvery(DashboardConstants.POST_DEBIT_CARD_ACCOUNT, postDebitCardAccount);
  yield takeEvery(DashboardConstants.MAKE_PAYMENT, makePayment);
  yield takeEvery(DashboardConstants.FETCH_CURRENT_PAYOFF_INFO, fetchCurrentPayoffInfo);
  yield takeEvery(DashboardConstants.FETCH_FUTURE_PAYOFF_INFO, fetchFuturePayoffInfo);
  yield takeEvery(DashboardConstants.FETCH_SCHEDULED_PAYMENT, fetchScheduledPayment);
  yield takeEvery(DashboardConstants.CANCEL_AUTOPAY, cancelAutopay);
  yield takeEvery(DashboardConstants.PUT_SCHEDULE_AUTOPAY, putScheduleAutopay);
  yield takeEvery(DashboardConstants.VALIDATE_REGISTRATION, validateRegistration);
  yield takeEvery(DashboardConstants.GET_BILLING_SETTING, getBillingSetting);
  yield takeEvery(DashboardConstants.PUT_BILLING_SETTING, putBillingSetting);
  yield takeEvery(DashboardConstants.FETCH_CUSTOMER_DETAIL, fetchCustomerDetail);
  yield takeEvery(DashboardConstants.FETCH_AUTOPAY_INFO, fetchAutopayInfo);
  yield takeEvery(DashboardConstants.POST_FORK_INFO, postForkInfo);
  yield takeEvery(DashboardConstants.FETCH_AUTOPAYS, fetchAutopays);
  yield takeEvery(DashboardConstants.FETCH_LOAN_CHARGES, fetchLoanCharges);
  yield takeEvery(DashboardConstants.VALIDATE_BANK_ROUTING_NUMBER, validateBankRoutingNumber);
};

export default dashboardSaga;
