// @flow

/**
 * @module Constants/Dashboard
 * @desc Dashboard Constants
 */

import keyMirror from 'keymirror';

/**
 * @constant {Object} DashboardConstants
 * @memberof Dashboard
 */
export const DashboardConstants = keyMirror({
  FETCH_LOANS_LIST: undefined,
  FETCH_LOAN_DETAIL: undefined,
  FETCH_LOAN_TERM: undefined,
  FETCH_TRANSACTIONS_LIST: undefined,
  FETCH_PAYMENTS_LIST: undefined,
  SELECT_LOAN_ID: undefined,
  FETCH_PAYMENT_METHODS: undefined,
  FETCH_DOCUMENTS: undefined,
  DOWNLOAD_DOCUMENT: undefined,
  REMOVE_PAYMENTACCOUNT: undefined,
  PUT_PAYMENT_ACCOUNT: undefined,
  POST_PAYMENT_ACCOUNT: undefined,
  MAKE_PAYMENT: undefined,
  FETCH_CURRENT_PAYOFF_INFO: undefined,
  FETCH_FUTURE_PAYOFF_INFO: undefined,
  FETCH_SCHEDULED_PAYMENT: undefined,
  CANCEL_AUTOPAY: undefined,
  PUT_SCHEDULE_AUTOPAY: undefined,
  VALIDATE_REGISTRATION: undefined,
  GET_BILLING_SETTING: undefined,
  PUT_BILLING_SETTING: undefined,
  FETCH_CUSTOMER_DETAIL: undefined,
  FETCH_AUTOPAY_INFO: undefined,
  POST_FORK_INFO: undefined,
  FETCH_AUTOPAYS: undefined,
  FETCH_LOAN_CHARGES: undefined,
  POST_DEBIT_CARD_ACCOUNT: undefined,
  VALIDATE_BANK_ROUTING_NUMBER: undefined,
});
