import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';

import {
  ButtonGroup,
  Modal,
  ModalHeader,
  Container,
  Row,
  Col,
} from 'reactstrap';
import Input from 'components/Form/Input';
import { Button } from 'components/Button';
import Select from 'components/Form/Select';
import Validator from 'components/Validator/Validator';
import { states } from 'utils/states';
import { cardNumberMask, numberMask, numberUnmask } from 'utils/masks';
import masterCardIcon from 'assets/icons/mastercard.svg';
import visaCardIcon from 'assets/icons/visa.svg';
import amxIcon from 'assets/icons/amex.svg';
import discoverIcon from 'assets/icons/discover.svg';
import VerifiedIcon from 'assets/images/correct.png';
import WarningIcon from 'assets/images/warning.png';
import schema from './addPaymentMethodSchema';
import debitCardSchema from './addDebitCardSchema';

const INITIAL_VALIDATION_STATE = {
  loading: false,
  verified: false,
  checkedFirstTime: false,
  data: null,
};

const AddPaymentMethods = (props) => {
  const { validator: { values, errors, validate, setValues, setErrors, deleteErrors, resetErrors }, paymentModalType, currentPaymentMethod, validateBankRoutingNumber } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [allowExpirationDate, setAllowExpirationDate] = useState(true);
  const [isAddingPayment, setIsAddingPayment] = useState(false);
  const [routingValidateStatus, setRoutingValidateStatus] = useState(INITIAL_VALIDATION_STATE);

  const validateRoutingNo = (routingNumber) => {
    const newValue = routingNumber.replace(/[^\d]/g, '');
    if (newValue.length === 9) {
      return true;
    }
    return false;
  };

  const validateRoutingNumber = (routingNo) => {
    let routingNumberUnMask;
    if (routingNo && routingNo.length === 9) {
      routingNumberUnMask = routingNo.replace(/[^\d]/g, '');
    }
    if (!routingNumberUnMask && routingNumberUnMask?.length !== 9) return;
    setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, loading: true, data: null });
    setValues({ ...values, bankName: '', isValidRoutingNumber: false });
    if (routingNo) {
      validateBankRoutingNumber({
        routingNumber: routingNo,
        success: (res) => {
          const validateRespose = res && res.length > 0 ? res[0] : null;
          const validateStatus = validateRespose && validateRespose.status;
          const validateData = validateRespose && validateRespose.data;

          if (validateStatus && validateStatus === 'success') {
            setValues({ ...values, bankName: validateData.name, routingNumber: routingNo, isValidRoutingNumber: true });
            deleteErrors(['bankName', 'routingNumber']);
            setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, verified: true, loading: false, data: validateData });
          }
          if (validateStatus && validateStatus === 'fail') {
            setErrors({ ...errors, routingNumber: 'Routing Number is not valid' });
            setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, verified: false, loading: false, data: null });
          }
        },
        fail: (err) => {
          console.log('err', err);
          toast.error(
            (
              <div>
                <h5>An error occurred.</h5>
              </div>
            ),
            { autoClose: true }
          );
          setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, verified: false, loading: false, data: null });
        },
      });
    }
  };

  const handleAddDebitCard = () => {
    if (validate(debitCardSchema).isValid) {
      setIsAddingPayment(true);
      let data;
      if (currentPaymentMethod && currentPaymentMethod.paymentAccountId) {
        data = {
          address: get(values, 'address', ''),
          city: get(values, 'city', ''),
          state: get(values, 'state', ''),
          zipcode: get(values, 'zipcode', ''),
          cardHolderName: values.cardHolderName !== get(currentPaymentMethod, 'accountholderName') ? values.cardHolderName.trim() : null,
          expirationDate: values.expirationDate !== get(currentPaymentMethod, 'cardExpiration') ? {
            month: parseInt(values.expirationDate.split('/')[0]),
            year: parseInt(values.expirationDate.split('/')[1]),
          } : null,
          accounttype: 'debit',
          country: 'USA',
          token: currentPaymentMethod.token,
          paymentAccountId: currentPaymentMethod.paymentAccountId,
        };
        console.log('data', data);
      } else {
        const date = values.expirationDate.split('/');
        data = {
          ...values,
          cardHolderName: values.cardHolderName.trim(),
          cardNumber: numberUnmask(values.cardNumber),
          expirationDate: {
            month: parseInt(date[0]),
            year: parseInt(date[1]),
          },
          accounttype: 'debit',
          country: 'USA',
          title: `AutoPay ${moment().format('MM/DD/YYYY, h:mm:ss')}`,
        };
      }
      props.saveDebitCardToken(data, (isSucceed = false) => {
        if (isSucceed) {
          setValues({});
        }
        setIsAddingPayment(false);
      });
    }
  };

  useEffect(() => {
    const isCard = currentPaymentMethod && currentPaymentMethod.type && typeof currentPaymentMethod.type === 'string' && ['debit', 'credit'].includes(currentPaymentMethod.type.toLowerCase());
    const newActiveTab = isCard ? 1 : 0;

    if (newActiveTab !== activeTab) setActiveTab(newActiveTab);

    if (currentPaymentMethod && !isCard) {
      setValues({
        accountType: currentPaymentMethod.type[2] || '',
        bankName: currentPaymentMethod.accountName || '',
        routingNumber: currentPaymentMethod.routingNumber || '',
        accountNumber: currentPaymentMethod.accountNumber || '',
        accountholderName: currentPaymentMethod.accountholderName || '',
        address: get(currentPaymentMethod, 'address.address') || '',
        city: get(currentPaymentMethod, 'address.city') || '',
        state: get(currentPaymentMethod, 'address.state') || '',
        zipcode: get(currentPaymentMethod, 'address.zipCode') || '',
        isValidRoutingNumber: true,
      });
    } else if (currentPaymentMethod && isCard) {
      setAllowExpirationDate(false);
      const state = get(currentPaymentMethod, 'address.state', '').split('.');
      setValues({
        cardNumber: currentPaymentMethod.accountNumber ? currentPaymentMethod.accountNumber.match(/.{1,4}/g).join('-') : '',
        cardHolderName: currentPaymentMethod.accountholderName ? currentPaymentMethod.accountholderName : '',
        address: get(currentPaymentMethod, 'address.address') || '',
        city: get(currentPaymentMethod, 'address.city') || '',
        state: state[state.length - 1] || '',
        zipcode: get(currentPaymentMethod, 'address.zipCode') || '',
        expirationDate: currentPaymentMethod.cardExpiration ? currentPaymentMethod.cardExpiration : '',
      });
    }
  }, [props.currentPaymentMethod]);

  const resetExpDate = () => {
    setAllowExpirationDate(true);
    props.validator.onChangeHandler('expirationDate', '');
  };

  const handleActiveTab = (tab) => {
    if (tab !== activeTab) {
      props.validator.setValues({});
      setActiveTab(tab);
    }
  };

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler } } = props;

    if (event.target.name === 'accountholderName' || event.target.name === 'cardHolderName') {
      onChangeHandler(event.target.name, event.target.value.replace(/[^a-zA-Z '-]/g, ''));
    } else if (event.target.name === 'routingNumber' && !routingValidateStatus.loading && validateRoutingNo(event.target.value) && event.target.value !== values.routingNumber) {
      validateRoutingNumber(event.target.value);
      onChangeHandler(event.target.name, event.target.value);
    } else {
      onChangeHandler(event.target.name, event.target.value);
    }
  };

  const handleAddPayment = () => {
    if (validate(schema).isValid) {
      setIsAddingPayment(true);

      let data = {
        ...values,
        accountholderName: `${values.accountholderName}`.replace(/^\s*\/*\s*|\s*\/*\s*$/gm, '').trim(),
        country: 'USA',
        title: `AutoPay ${moment().format('MM/DD/YYYY, h:mm:ss')}`,
      };

      if (paymentModalType === 'save') {
        data = {
          ...data,
          token: currentPaymentMethod.token,
          paymentAccountId: currentPaymentMethod.paymentAccountId,
        };
      }

      props.saveToken(data, (isSucceed = false) => {
        if (isSucceed) {
          setValues({});
        }
        setIsAddingPayment(false);
      });
    } else {
      console.log('api error', errors);
    }
  };

  const toggleModal = () => {
    setActiveTab(0);
    setRoutingValidateStatus(INITIAL_VALIDATION_STATE);
    setValues({});
    resetErrors({});
    props.toggle(0);
  };

  const renderBankAccount = () => (
    <Container fluid className="pl-3 pr-3">
      <Row>
        <Col sm={6}>
          <div>
            <Input
              name="routingNumber"
              label="Routing Number"
              value={values.routingNumber}
              isMasked={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
              onChange={handleInputChange}
              placeHolder="Routing Number"
              isRequired
              hasError={!!errors.routingNumber}
              errorMessage={errors.routingNumber}
              isDisabled={paymentModalType !== 'add'}
            />
            {routingValidateStatus.loading
              ? <span className="input-loader" />
              : routingValidateStatus.checkedFirstTime
                ? (
                  <span className="input-status">
                    <img
                      src={`${routingValidateStatus.verified ? VerifiedIcon : WarningIcon}`}
                      alt="input-status"
                    />
                  </span>
                )
                : null}
          </div>
        </Col>
        <Col sm={6}>
          <Input
            name="accountNumber"
            label="Account Number"
            value={values.accountNumber}
            onChange={handleInputChange}
            placeHolder="Account Number"
            isMasked={numberMask}
            isRequired
            hasError={!!errors.accountNumber}
            errorMessage={errors.accountNumber}
            isDisabled={paymentModalType !== 'add'}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Input
            name="bankName"
            label="Bank Name"
            value={values.bankName}
            onChange={handleInputChange}
            placeHolder="Bank Name"
            isRequired
            hasError={!!errors.bankName}
            errorMessage={errors.bankName}
            isDisabled={paymentModalType !== 'add'}
          />
        </Col>
        <Col sm={6}>
          <Select
            name="accountType"
            data={[
              { value: 'checking', title: 'Checking' },
              { value: 'savings', title: 'Savings' },
            ]}
            value={values.accountType}
            onChange={handleInputChange}
            label="Account Type"
            isRequired
            hasError={!!errors.accountType}
            errorMessage={errors.accountType}
            isDisabled={paymentModalType !== 'add'}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Input
            name="accountholderName"
            label="Account Holder Name"
            value={values.accountholderName}
            onChange={handleInputChange}
            placeHolder="Account Holder Name"
            isRequired
            hasError={!!errors.accountholderName}
            errorMessage={errors.accountholderName}
          />
        </Col>
        <Col sm={6}>
          <Input
            name="address"
            label="Address"
            value={values.address}
            onChange={handleInputChange}
            placeHolder="Address"
            isRequired
            hasError={!!errors.address}
            errorMessage={errors.address}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Input
            name="city"
            label="City"
            value={values.city}
            onChange={handleInputChange}
            placeHolder="City"
            isRequired
            hasError={!!errors.city}
            errorMessage={errors.city}
          />
        </Col>
        <Col sm={6}>
          <Row>
            <Col sm={7}>
              <Select
                name="state"
                data={states}
                value={values.state}
                onChange={handleInputChange}
                label="State"
                isRequired
                hasError={!!errors.state}
                errorMessage={errors.state}
              />
            </Col>
            <Col sm={5}>
              <Input
                name="zipcode"
                label="Zip"
                isMasked={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                value={values.zipcode}
                onChange={handleInputChange}
                placeHolder="Zip"
                isRequired
                hasError={!!errors.zipcode}
                errorMessage={errors.zipcode}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary"
            className="w-100 mt-1 mb-2"
            onClick={handleAddPayment}
            isLoading={isAddingPayment}
          >
            {paymentModalType === 'add' ? 'Add new payment' : 'Edit payment'}
          </Button>
        </Col>
      </Row>
    </Container>
  );

  const cardNumber = numberUnmask(get(values, 'cardNumber', ''));
  const brand = get(currentPaymentMethod, 'brand');
  let cardImg = null;

  let errorCardMessage = '';
  if (`${cardNumber}`.startsWith('3')) {
    cardImg = amxIcon;
    errorCardMessage = 'Currently we accept only Visa or Mastercard';
  } else if (`${cardNumber}`.startsWith('4') || brand === 'VISA') {
    cardImg = visaCardIcon;
    errorCardMessage = '';
  } else if (`${cardNumber}`.startsWith('5') || brand === 'MASTERCARD') {
    cardImg = masterCardIcon;
    errorCardMessage = '';
  } else if (`${cardNumber}`.startsWith('6')) {
    cardImg = discoverIcon;
    errorCardMessage = 'Currently we accept only Visa or Mastercard';
  } else if (`${cardNumber}`.length > 3 && values.isEditingDebitCard) {
    errorCardMessage = 'Currently we accept only Visa or Mastercard';
  }

  const renderDebtCard = () => (
    <Container fluid className="pl-3 pr-3">
      <Row>
        <Col sm={6}>
          <Row>
            <Col>
              <Input
                name="cardHolderName"
                label="Cardholder Name"
                value={get(values, 'cardHolderName') || ''}
                onChange={handleInputChange}
                placeHolder="Cardholder Name"
                isRequired
                hasError={!!get(errors, 'cardHolderName')}
                errorMessage={get(errors, 'cardHolderName')}
              />
            </Col>
          </Row>
          <Row>
            <Col className="payment-logo">
              <Input
                name="cardNumber"
                label="Card Number"
                value={get(values, 'cardNumber') || ''}
                isMasked={paymentModalType === 'add' ? cardNumberMask : false}
                onChange={handleInputChange}
                placeHolder="Card Number"
                isRequired
                isDisabled={paymentModalType === 'save'}
                hasError={!!get(errors, 'cardNumber')}
                errorMessage={get(errors, 'cardNumber') || errorCardMessage}
              />
              <div className="card-container">
                {(cardNumber.length > 3 && cardImg) && <img src={cardImg} height="28px" alt="card" />}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                name="expirationDate"
                label="Expiration Date"
                onChange={handleInputChange}
                value={get(values, 'expirationDate') || ''}
                onClick={paymentModalType === 'save' && !allowExpirationDate ? resetExpDate : null}
                isMasked={allowExpirationDate ? [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : false}
                isRequired
                hasError={!!get(errors, 'expirationDate')}
                errorMessage={get(errors, 'expirationDate')}
                placeHolder="MM/YYYY"
              />
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <Row>
            <Col>
              <Input
                name="address"
                label="Address"
                value={get(values, 'address') || ''}
                onChange={handleInputChange}
                placeHolder="Address"
                isRequired
                hasError={!!get(errors, 'address')}
                errorMessage={get(errors, 'address')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                name="city"
                label="City"
                value={get(values, 'city') || ''}
                onChange={handleInputChange}
                placeHolder="City"
                isRequired
                hasError={!!get(errors, 'city')}
                errorMessage={get(errors, 'city')}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={7}>
              <Select
                name="state"
                data={states}
                value={get(values, 'state') || ''}
                onChange={handleInputChange}
                label="State"
                isRequired
                hasError={!!get(errors, 'state')}
                errorMessage={get(errors, 'state')}
              />
            </Col>
            <Col sm={5}>
              <Input
                name="zipcode"
                label="Zip"
                isMasked={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                value={get(values, 'zipcode') || ''}
                onChange={handleInputChange}
                placeHolder="Zip"
                isRequired
                hasError={!!get(errors, 'zipcode')}
                errorMessage={get(errors, 'zipcode')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary"
            className="w-100 mt-1 mb-2"
            onClick={handleAddDebitCard}
            isLoading={isAddingPayment}
          >
            {paymentModalType === 'add' ? 'Add new payment' : 'Edit payment'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
  const renderTabContent = () => (activeTab === 0 ? renderBankAccount() : renderDebtCard());

  return (
    <Modal isOpen={props.isOpen} toggle={toggleModal} size="lg" style={{ maxWidth: 760 }}>
      <ModalHeader className="no-border" toggle={toggleModal}>
        {paymentModalType === 'add' ? 'Add A Payment Method' : 'Edit Payment Method'}
      </ModalHeader>
      <ButtonGroup size="sm" className="d-flex ml-3 mr-3 mb-3">
        <Button
          color="primary"
          disabled={paymentModalType === 'save' && activeTab === 1}
          onClick={handleActiveTab.bind(null, 0)}
          active={activeTab === 0}
          style={{ maxWidth: '50%' }}
        >
          Bank Account
        </Button>
        <Button
          color="primary"
          disabled={paymentModalType === 'save' && activeTab === 0}
          onClick={handleActiveTab.bind(null, 1)}
          active={activeTab === 1}
        >
          Debit Card
        </Button>
      </ButtonGroup>
      {renderTabContent()}
    </Modal>
  );
};

AddPaymentMethods.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  validator: PropTypes.object.isRequired,
  paymentModalType: PropTypes.string.isRequired,
  currentPaymentMethod: PropTypes.object,
  saveToken: PropTypes.func.isRequired,
  saveDebitCardToken: PropTypes.func.isRequired,
  validateBankRoutingNumber: PropTypes.func.isRequired,
};

AddPaymentMethods.defaultProps = {
  currentPaymentMethod: null,
};

export default Validator(schema)(AddPaymentMethods);
