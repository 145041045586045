import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import {
  fetchDocuments,
  downloadDocument,
  selectLoanId,
} from 'actions/dashboard';

import { IconDocument } from 'components/Icons';
import Loader from 'components/Loader';
import { ButtonLink } from 'components/Button';
import './styles.scss';

const ConsumerDocuments = (props) => {
  const { loansList, documents } = props;
  const [activeTab, setActiveTab] = useState('statements');
  const documentslist = useMemo(() => get(documents, 'data'), [documents]);

  useEffect(() => {
    props.fetchDocuments(loansList.activeLoanId);
  }, []);

  const handleChange = (e) => {
    props.selectLoanId(e.target.value);
    props.fetchDocuments(e.target.value);
  };

  const download = ({ id: fileId, fileAttachment: { fileName } }) => {
    props.downloadDocument(`${loansList.activeLoanId}&${fileId}&${fileName}`);
  };

  const handleTabChange = (newActiveTab, e) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
  };

  return (
    <div className="page-documents pb-3">
      <Container fluid>
        <Row className="mb-3 align-items-center">
          <Col className="text-center text-md-left">
            <h3 className="mb-0">Documents</h3>
          </Col>
          <Col xs={12} md="auto" className="text-right justify-content-end ml-md-3 mt-3 mt-md-0">
            <FormGroup className="dropdown-toggle mb-0 input-inline pb-0">
              <Input
                type="select"
                name="select"
                id="loanSelection"
                onChange={handleChange}
                value={`${loansList.activeLoanId}`}
                style={{
                  padding: '.675rem 1.125rem',
                  height: 'auto',
                  paddingRight: '45px',
                }}
              >
                <option disabled>Select</option>
                {
                  loansList.data && loansList.data.map((item, index) => (
                    <option value={item.id} key={`loanlist_${index}`}>{item.displayId}</option>
                  ))
                }
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={0} lg={12}>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink className={activeTab === 'statements' ? 'active' : ''} onClick={handleTabChange.bind(null, 'statements')}>
                    <span>Your </span>
                    Statements
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={activeTab === 'agreements' ? 'active' : ''} onClick={handleTabChange.bind(null, 'agreements')}>Agreements</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={activeTab === 'receipts' ? 'active' : ''} onClick={handleTabChange.bind(null, 'receipts')}>Receipts</NavLink>
                </NavItem>
              </Nav>
              {
                activeTab === 'statements'
                  ? (
                    documentslist === undefined
                      ? <Loader className="height-2" />
                      : (
                        <CardBody style={{ height: 595 }}>
                          <Table className="mb-0" size="sm" responsive striped borderless>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                              documentslist.length > 0 && documentslist.filter((document) => document.sectionId === 9).length > 0 ? documentslist.filter((document) => document.sectionId === 9).map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <IconDocument className="mr-1" />
                                    {' '}
                                    {item.fileAttachment.fileOriginalName}
                                  </td>
                                  <td>{moment(new Date(item.created)).format('MM/DD/YYYY')}</td>
                                  <td>
                                    <ButtonLink onClick={download.bind(null, item)} className="btn-icon" style={{ cursor: 'pointer' }}>
                                      <img src="/icons/download.svg" alt="download" />
                                    </ButtonLink>
                                  </td>
                                </tr>
                              )) : (
                                <tr>
                                  <td colSpan={4} align="center" className="pt-3 pb-3">No data</td>
                                </tr>
                              )
                            }
                            </tbody>
                          </Table>
                        </CardBody>
                      )
                  )
                  : activeTab === 'agreements' ? (
                    documentslist === undefined
                      ? <Loader className="height-2" />
                      : (
                        <CardBody style={{ height: 595 }}>
                          <Table className="mb-0" size="sm" responsive striped borderless>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                              documentslist.length > 0 && documentslist.filter((document) => document.sectionId === 3).length > 0 ? documentslist.filter((document) => document.sectionId === 3).map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <IconDocument className="mr-1" />
                                    {' '}
                                    {item.fileAttachment.fileOriginalName}
                                  </td>
                                  <td>{moment(new Date(item.created)).format('MM/DD/YYYY')}</td>
                                  <td>
                                    <ButtonLink onClick={download.bind(null, item)} className="btn-icon" style={{ cursor: 'pointer' }}>
                                      <img src="/icons/download.svg" alt="download" />
                                    </ButtonLink>
                                  </td>
                                </tr>
                              )) : (
                                <tr>
                                  <td colSpan={4} align="center" className="pt-3 pb-3">No data</td>
                                </tr>
                              )
                            }
                            </tbody>
                          </Table>
                        </CardBody>
                      )
                  )
                    : activeTab === 'receipts' ? (
                      documentslist === undefined
                        ? <Loader className="height-2" />
                        : (
                          <CardBody style={{ height: 595 }}>
                            <Table className="mb-0" size="sm" responsive striped borderless>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Date</th>
                                  <th>Download</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                              documentslist.length > 0 && documentslist.filter((document) => document.sectionId === 6).length > 0 ? documentslist.filter((document) => document.sectionId === 6).map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <IconDocument className="mr-1" />
                                    {' '}
                                    {item.fileAttachment.fileOriginalName}
                                  </td>
                                  <td>{moment(new Date(item.created)).format('MM/DD/YYYY')}</td>
                                  <td>
                                    <ButtonLink onClick={download.bind(null, item)} className="btn-icon" style={{ cursor: 'pointer' }}>
                                      <img src="/icons/download.svg" alt="download" />
                                    </ButtonLink>
                                  </td>
                                </tr>
                              )) : (
                                <tr>
                                  <td colSpan={4} align="center" className="pt-3 pb-3">No data</td>
                                </tr>
                              )
                            }
                              </tbody>
                            </Table>
                          </CardBody>
                        )
                    )
                      : (
                        <tr>
                          <td colSpan={4} align="center" className="pt-3 pb-3 pl-2">No data</td>
                        </tr>
                      )
              }
            </Card>
          </Col>
        </Row>

      </Container>

    </div>
  );
};

ConsumerDocuments.propTypes = {
  selectLoanId: PropTypes.func.isRequired,
  fetchDocuments: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  loansList: PropTypes.object.isRequired,
  documents: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loansList: state.dashboard.loansList,
  documents: state.dashboard.documents,
});

const mapDispatchToProps = {
  fetchDocuments,
  downloadDocument,
  selectLoanId,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConsumerDocuments);
