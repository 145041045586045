import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import get from 'lodash/get';
import uuidv4 from 'uuid/v4';

import Input from 'components/Form/Input';
import {
  Container,
  Row,
  Form,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { Button, ButtonLink } from 'components/Button';
import Validator from 'components/Validator/Validator';

import { signUp } from 'utils/aws';
import { numberUnmask } from 'utils/masks';
import schema from './registrationConfirmSchema';

const RegistrationConfirm = (props) => {
  const { validator: { values, errors } } = props;
  const [isLoading, setIsLoading] = useState(false);
  const notification = useRef(null);

  useEffect(() => {
    if (!get(props, 'location.state.email', '') || !get(props, 'location.state.ssn', '')) {
      props.history.push('/registration');
    }
  }, []);

  const handleInputChange = (event) => {
    const {
      validator: { onChangeHandler },
    } = props;

    const value = event.target.value.trim();
    onChangeHandler(event.target.name, value);
  };

  const handleSubmitFrom = (data, e) => {
    e.preventDefault();
    const {
      validator: { validate },
      history,
    } = props;

    if (validate(schema).isValid) {
      setIsLoading(true);

      const loweredUsername = `${data.username}`.toLowerCase();
      const username = uuidv4();

      const userCred = {
        payload: {
          username,
          password: data.password,
          attributes: {
            email: get(props, 'location.state.email', ''),
            'custom:data': `{ "CustomUserName": "${loweredUsername}" }`,
          },
          clientMetadata: {
            ssn: numberUnmask(get(props, 'location.state.ssn', '')),
            preferred_username: loweredUsername,
          },
        },
      };

      signUp(userCred)
        .then((res) => {
          setIsLoading(false);
          history.push(
            '/registration-success',
            {
              email: get(res, 'codeDeliveryDetails.Destination', ''),
              ssn: numberUnmask(get(props, 'location.state.ssn', '')),
              userName: username,
            }
          );
        })
        .catch((err) => {
          setIsLoading(false);
          notification.current
          && notification.current.addNotification({
            message: err.message,
            level: 'error',
            position: 'tc',
          });
        });
    }
  };

  const signin = () => {
    props.history.push('/');
  };

  return (
    <div className="registration-page">
      <NotificationSystem ref={notification} />
      <Container fluid>
        <Form>
          <Row className="align-items-center" style={{ minHeight: '500px' }}>
            <Col lg={{ size: 4, offset: 4 }} md={{ size: 6, offset: 3 }}>
              <Card>
                <CardHeader>User Registration</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <h5 className="mb-2 text-center">Create your username and password</h5>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col>
                      <Input
                        name="username"
                        label="Username"
                        value={values.username}
                        onChange={handleInputChange}
                        hasError={!!errors.username}
                        hasValue
                        placeHolder="username"
                        isBadgeVisible={false}
                        isRequired
                        errorMessage={errors.username}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        name="password"
                        type="password"
                        label="Password"
                        value={values.password}
                        onChange={handleInputChange}
                        hasError={!!errors.password}
                        hasValue
                        placeHolder="Password"
                        isBadgeVisible={false}
                        isRequired
                        errorMessage={errors.password}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Input
                        name="confirmPassword"
                        label="Re-Enter Password"
                        onChange={handleInputChange}
                        value={values.confirmPassword}
                        hasError={!!errors.confirmPassword}
                        hasValue
                        placeHolder="Confirm Password"
                        isBadgeVisible={false}
                        isRequired
                        type="password"
                        errorMessage={errors.confirmPassword}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul style={{ fontSize: 12 }}>
                        <li>Password must contain a lower case letter</li>
                        <li>Password must contain an upper case letter</li>
                        <li>Password must contain a special character</li>
                        <li>Password must contain a number</li>
                        <li>Password must contain at least 8 characters</li>
                      </ul>
                    </Col>
                  </Row>
                  <Row className="pt-1">
                    <Col>
                      <Button
                        className="w-100"
                        onClick={handleSubmitFrom.bind(null, values)}
                        isLoading={isLoading}
                        color="primary"
                        type="submit"
                      >
                        Register
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-1 text-center">
                    <Col style={{ color: '#3989E3' }}>
                      <ButtonLink onClick={signin} style={{ cursor: 'pointer' }}>Sign In</ButtonLink>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

RegistrationConfirm.propTypes = {
  validator: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  Validator(schema)
)(RegistrationConfirm);
