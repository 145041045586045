import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Button } from 'components/Button';
import { ToastContainer } from 'react-toastify';
import {
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import { Header } from 'components/Header';
import Footer from 'components/Footer';
import { signOut } from 'actions/auth';
import { appConfig } from 'config/appConfig';
import Login from './pages/Auth/Login';
import SetNewPassword from './pages/Auth/SetNewPassword';
import Forgot from './pages/Auth/Forgot';
import ChangePassword from './pages/Auth/ChangePassword';
import Registration from './pages/Registration/Registration';
import RegistrationConfirm from './pages/Registration/RegistrationConfirm';
import RegistrationSuccess from './pages/Registration/RegistrationSuccess';
import RegistrationCompleted from './pages/Registration/RegistrationCompleted';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Dashboard from './pages/Dashboard';
import MyLoans from './pages/MyLoans';
import Documents from './pages/Documents';
import Settings from './pages/Settings';

import './App.scss'; // eslint-disable-line

const PrivateRoute = ({ component: InternalComponent, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = sessionStorage.getItem('idToken');

      return token
        ? <InternalComponent {...props} />
        : (
          <Redirect
            to={{
              pathname: '/',
              // eslint-disable-next-line
              state: { from: props.location },
            }}
          />
        );
    }}
  />
);

const PublicRoute = ({ component: InternalComponent, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = sessionStorage.getItem('idToken');

      return !token
        ? <InternalComponent {...props} />
        : (
          <Redirect
            to={{
              pathname: '/consumer/dashboard',
              // eslint-disable-next-line
              state: { from: props.location },
            }}
          />
        );
    }}
  />
);

const MultiRoute = ({ component: InternalComponent, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <InternalComponent {...props} />}
  />
);

const routes = [
  {
    name: 'Login',
    pathname: '/',
    component: Login,
    exact: true,
    wrapper: PublicRoute,
  },
  {
    name: 'Forgot',
    pathname: '/forgot',
    component: Forgot,
    exact: true,
    wrapper: PublicRoute,
  },
  {
    name: 'New Password',
    pathname: '/set-new-password',
    component: SetNewPassword,
    exact: true,
    wrapper: PublicRoute,
  },

  {
    name: 'Registration',
    pathname: '/registration',
    component: Registration,
    exact: true,
    wrapper: PublicRoute,
  },
  {
    name: 'RegistrationConfirm',
    pathname: '/registration-confirm',
    component: RegistrationConfirm,
    exact: true,
    wrapper: PublicRoute,
  },
  {
    name: 'RegistrationSuccess',
    pathname: '/registration-success',
    component: RegistrationSuccess,
    exact: true,
    wrapper: PublicRoute,
  },
  {
    name: 'RegistrationCompleted',
    pathname: '/registration-completed',
    component: RegistrationCompleted,
    exact: true,
    wrapper: PublicRoute,
  },
  {
    name: 'TermsOfUse',
    pathname: '/terms-of-use',
    component: TermsOfUse,
    exact: true,
    wrapper: PublicRoute,
  },
  {
    name: 'PrivacyPolicy',
    pathname: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
    wrapper: PublicRoute,
  },
  // end Internal pages
  {
    name: 'Dashboard',
    pathname: '/consumer/dashboard',
    component: Dashboard,
    wrapper: PrivateRoute,
  },
  {
    name: 'My Loans',
    pathname: '/consumer/loans',
    component: MyLoans,
    wrapper: PrivateRoute,
  },
  {
    name: 'Documents',
    pathname: '/consumer/documents',
    component: Documents,
    wrapper: PrivateRoute,
  },
  {
    name: 'Settings',
    pathname: '/consumer/settings',
    component: Settings,
    wrapper: PrivateRoute,
  },
  {
    name: 'Reset Password',
    pathname: '/dashboard/reset-password',
    component: ChangePassword,
    exact: true,
    wrapper: PrivateRoute,
  },
];

const extendedFooters = [
  '/dashboard/text-apply',
  '/applications/dtm/application',
];

export const ParentRoute = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const idleTimer = useRef(null);

  const handleLogoutModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const signout = () => {
    sessionStorage.getItem('idToken') && props.signOut();
  };

  const onIdle = () => {
    if (sessionStorage.getItem('idToken')) {
      const newTimeoutId = setTimeout(
        () => {
          handleLogoutModal();
          signout();
        },
        2 * 60 * 1000
      );

      setIsModalOpen(true);
      setTimeoutId(newTimeoutId);
    }
  };

  const addPageSenseScript = () => {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://cdn.pagesense.io/js/lendingusa/${appConfig.pageSenseId}.js`;
    document.head.appendChild(scriptTag);
  };

  useEffect(() => {
    if (appConfig.pageSenseId) {
      addPageSenseScript();
    }
  }, []);

  const renderFooter = () => {
    const { location: { pathname } } = props;

    return <Footer isContentVisible={extendedFooters.indexOf(pathname) !== -1} />;
  };

  return (
    <IdleTimer
      ref={idleTimer}
      element={document}
      onIdle={onIdle}
      timeout={1000 * 60 * (appConfig.sessionTimeoutInMinutes - 2)}
    >
      <div className="App bg-light">
        <Header />
        <div className={cn('container-body corporate')}>
          <Switch>
            {routes.map((route, index) => (
              <route.wrapper
                component={route.component}
                key={index}
                path={route.pathname}
                exact={route.exact || false}
              />
            ))}
            <Redirect to="/" />
          </Switch>
        </div>
        {renderFooter()}
        <Modal isOpen={isModalOpen} toggle={handleLogoutModal} size="md">
          <ModalHeader toggle={handleLogoutModal}>Your session is about to expire</ModalHeader>
          <ModalBody className="text-center">
            <p className="mb-3">You will be automatically logged off after 2 minutes. If you would like to continue click on the Continue button.</p>
            <Button
              onClick={() => {
                clearTimeout(timeoutId);
                signout();
                handleLogoutModal();
              }}
              color="danger"
              className="w-100 mb-1"
            >
              Logout
            </Button>
            <Button
              onClick={() => {
                clearTimeout(timeoutId);
                handleLogoutModal();
              }}
              className="w-100"
            >
              Continue
            </Button>
          </ModalBody>
        </Modal>
        <ToastContainer position="top-center" />
      </div>
    </IdleTimer>
  );
};

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

MultiRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

ParentRoute.propTypes = {
  signOut: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      auth: state.auth,
    }),
    {
      signOut,
    }
  )
)(ParentRoute);
