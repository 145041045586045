import React from 'react';
import PropTypes from 'prop-types';

const IconDocument = ({ width, className }) => (
  <svg
    width={width}
    height={width * (20 / 15)}
    viewBox="0 0 15 20"
    fill="#B0ACBE"
    className={className}
  >
    <g stroke="none">
      <g transform="translate(-75.000000, -372.000000)">
        <g transform="translate(50.000000, 186.000000)">
          <g transform="translate(25.000000, 171.000000)">
            <path d="M8.75,20.3125 L8.75,15 L0.9375,15 C0.41796875,15 0,15.4179688 0,15.9375 L0,34.0625 C0,34.5820312 0.41796875,35 0.9375,35 L14.0625,35 C14.5820312,35 15,34.5820312 15,34.0625 L15,21.25 L9.6875,21.25 C9.171875,21.25 8.75,20.828125 8.75,20.3125 Z M15,19.7617188 L15,20 L10,20 L10,15 L10.2382812,15 C10.4882812,15 10.7265625,15.0976562 10.9023438,15.2734375 L14.7265625,19.1015625 C14.9023438,19.2773438 15,19.515625 15,19.7617188 Z" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconDocument.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

IconDocument.defaultProps = {
  width: 15,
  className: '',
};

export default IconDocument;
