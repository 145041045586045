import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './style.scss';

const TermsOfUse = () => (
  <div className="terms-of-use-page">
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
          <h1>Terms of Use</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
          <p><strong>Terms of Use – Consumer</strong></p>
          <p><strong>Rev. 12/2021</strong></p>
          <p>This Terms of Use (“ToU”) governs the use of LendingUSA, LLC’s web-based portal (“Portal”) and/or any of its affiliated websites or platforms (collectively with “Portal”, “Websites”) that are managed by LendingUSA, LLC, a Delaware limited liability company having a principal place of business at 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403 which, together with its subsidiaries, affiliates, assignees and its and their respective officers, directors, employees and agents, shall be referred to in this ToU as “LUSA”, “We”, “Our” or “Us.”</p>
          <p>“You”, “Your” or “Yours” means the consumer using the Websites.&nbsp; You should regularly review this ToU, as it is subject to change.</p>
          <ol>
            <li><strong> GENERAL TERMS AND CONDITIONS</strong></li>
          </ol>
          <p>All uses of the Websites are subject to this ToU. By visiting the Websites, You acknowledge, accept, and agree to all the terms, conditions, and privacy policies described or incorporated by reference below. This ToU represent a binding contract between You and Us, and is in addition to any other agreements (i) between You and Us, (ii) between You and any other Merchant or lender, if any, and (iii) that govern Your use of loan financing, products, services, content, tools, and information available on the Websites. If You do not agree with anything contained in this ToU, please do not submit information to, access information from, or otherwise utilize the Websites.</p>
          <p>You agree that We may modify this ToU from time to time. Any modification is effective immediately upon posting on the Websites. Your continued use of the Websites following modification of this ToU, in addition to Your affirmative consent to such modifications, will be conclusively deemed to signify Your acceptance of the modification. We reserve the right, at any time, to modify or discontinue, temporarily or permanently the Websites (or any part thereof) without notice.</p>
          <p>You agree to keep business information and trade secrets of LUSA including, but not limited to, terms and pricing that are not publicly disclosed and any user account information, confidential until such information becomes known to the public generally and except to the extent that disclosure may be required by law, regulation or legal process.</p>
          <ol start="2">
            <li><strong> PRIVACY</strong></li>
          </ol>
          <p>
            Your privacy is very important to Us. Our Privacy Policy explains how We treat Your personal information and protect Your privacy when You are using the Websites. To view LUSA’s Privacy Policy, please click
            {' '}
            <a href="https://lendingusa.com/privacy-policy/">here</a>
            . By using the Websites, You agree that We may use Your information as set forth in the Privacy Policy. You are subject to the LUSA’s Privacy Policy that appears
            {' '}
            <a href="https://lendingusa.com/privacy-policy/">here</a>
            {' '}
            and is incorporated herein by reference.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ol start="3">
            <li><strong> PORTAL REGISTRATION ONLY</strong></li>
          </ol>
          <p>To use Our Portal, You must complete the registration process. You agree that the registration information You provide is accurate, complete and current, and You further agree to promptly update that information to keep it accurate, complete and current. Once You have registered, You will receive or will be able to create or input a user identification (“User ID”) and password in order to access the Portal. You are responsible for maintaining the confidentiality of Your User ID and password, and are responsible for all activities that occur using Your User ID and password, whether or not expressly authorized by You. LUSA will not be responsible for any damages resulting from the unauthorized use of Your User ID or password. You agree to notify Us immediately of any such unauthorized use or any other breach of security. You are responsible for all transactions entered into on the Portal under your User ID, and are responsible for preventing any unauthorized use of Your User ID and password. Individuals and entities whose privilege to use the Portal has previously been terminated by LUSA may not register for the Portal, nor may You designate any of those individuals to use Your User ID on Your behalf.</p>
          <ol start="4">
            <li><strong> USE OF THE PORTAL; LIMITATIONS; ACCEPTABLE USE</strong></li>
          </ol>
          <p>The Portal is designed to connect You with potential lenders to enable You to finance the purchase of goods or services offered to You by a merchant.</p>
          <p>You understand, acknowledge and agree that (i) LUSA is not involved in the purchase of goods or services between You and the merchant or their respective practices or stores (collectively “Merchant”), (ii) LUSA does not provide the services of any lender, (iii) LUSA is not liable for Your repayment of any loan once You enter into a loan transaction with a lender to finance the purchase of goods or services from a Merchant, (iv) LUSA does not make lending decisions on behalf of any Merchant or lender, (v) LUSA does not offer or make solicitations to lend, and (vi) LUSA is not an agent or representative of the Merchant or lender.</p>
          <p>You understand, acknowledge and agree that LUSA makes no representations or warranties regarding, among other things without limitation, any information contained on an application that was provided by You, or any results from any Merchant’s or lender’s use of the nonpublic personal information provided on the Portal, including, without limitation, whether or not You will be approved for a financing transaction by a Merchant or a lender, or that a certain percentage of consumers will be so approved. You confirm, acknowledge and agree that You will provide true and accurate information about Yourself, and to update and maintain such information.</p>
          <p>Your use of the Portal is conditioned upon the following representations being true: (i) You are of the age of majority in your state of resident; (ii) You are able to form a binding contract with Us; (iii) You are not prohibited by law from accessing the Portal or have not previously been banned, terminated or otherwise denied access to the Portal; and (iv) You are not acting on behalf of a person whose access to the Portal has been previously terminated or otherwise denied by Us.</p>
          <p>As conditions of Your use of the Portal, You agree to comply with this ToU and all applicable laws and regulations in connection with Your use of the Portal. You will not violate, attempt to violate, or knowingly facilitate the violation of, the security (including access control or authentication systems) or integrity of the Portal. Without limiting the foregoing, You agree not to (i) attempt, or enable others to attempt, to gain unauthorized access to any other accounts, computer systems or networks connected to any LUSA server or to any of the content provided through the Portal (the “Materials”), through hacking, password theft or any other means, (ii) impersonate another user of the Portal, (iii) attempt, or enable others to attempt, to obtain any Materials through any means that LUSA has not intentionally made available on the Portal including using any automatic or manual process to search or harvest information from the Portal, or (iv) use the Portal or the Materials in any manner that could damage, disable, overburden or impair any LUSA server or the network(s) connected to any LUSA server, or that might interfere with any other person’s access to or use or enjoyment of any Materials.</p>
          <p>Without limiting the above, You are expressly prohibited from (i) any resale or commercial use of the Portal, (ii) any collection and use of any product or service listings, descriptions, prices or any other information posted on the Portal for any purpose other than Your own personal use or as otherwise permitted in an agreement between LUSA and Yourself (“Permitted Use”), including, without limitation, any purpose competitive to LUSA or any commercial purpose, including marketing, (iii) any downloading or copying of any materials contained in the Portal for any reason other than for a Permitted Use, or any use of data mining, robots or similar data gathering and extraction tools, or (iv) using the Portal to access or collect any personally identifiable information, including account names, email addresses or other such information for any purpose, including, without limitation, commercial purposes. LUSA does not have any part in the provision of information that You provide and LUSA cannot and does not confirm the accuracy of information provided, if any. If LUSA permits You to input information into the Portal, You may not input any information or content that:</p>
          <ul>
            <li>is promotional in nature, including solicitations for funds or businesses, without the prior written authorization of LUSA;</li>
            <li>constitutes junk mail, unsolicited commercial messages, spam, chain letters, pyramid schemes or the like;</li>
            <li>is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, racially, ethnically, religiously or otherwise objectionable, or otherwise violates the legal rights of others;</li>
            <li>You do not have the right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</li>
            <li>infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party; or</li>
            <li>contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware, or telecommunications equipment.</li>
          </ul>
          <p>LUSA reserves the right to edit, restrict or remove any content You provide for any reason at any time. The information and materials made available through the Portal may contain typographical errors or inaccuracies. In addition, LUSA does not control the information provided by other users that is made available through the Portal. LUSA reserves the right to refuse service, terminate relationships, and/or cancel orders in its discretion. You agree that You will have no claim against LUSA, its affiliates, successors, assigns, shareholders, partners, members, officers, directors, managers, licensors, employees and advisors in their individual and representative capacities, and LUSA’s affiliate’s respective successors, assigns, shareholders, partners, members, officers, directors, managers, licensors, employees and advisors in their individual and representative capacities (collectively, the “Released Parties”), for any actual or alleged infringement of any proprietary rights, rights of privacy or publicity, moral rights or rights of attribution in connection with Our use of any content You provide.</p>
          <ol start="5">
            <li><strong> E-MAIL</strong></li>
          </ol>
          <p>YOU MAY NOT SEND UNSOLICITED E-MAILS OR E-MAILS THAT INCLUDES FALSE OR MISLEADING INFORMATION IN THE RETURN ADDRESS OR IN THE SUBJECT LINE TO ANYONE WHOSE E-MAIL ADDRESS INCLUDES THE DOMAIN NAME LENDINGUSA.COM. YOU MAY NOT USE OUR DOMAIN NAME AS A PSEUDONYMOUS RETURN E-MAIL ADDRESS FOR ANY COMMUNICATIONS THAT YOU TRANSMIT FROM ANOTHER LOCATION OR THROUGH ANOTHER SERVICE. YOU MAY NOT PRETEND TO BE SOMEONE ELSE – OR SPOOF THEIR IDENTITY – WHEN USING THE PORTAL OR THE WEBSITES.</p>
          <ol start="6">
            <li><strong> INTELLECTUAL PROPERTY; COPYRIGHT</strong></li>
          </ol>
          <p>The contents of the Websites and any products or services provided or sold by LUSA, including the likeness, text, graphics, logos, button icons, images, audio and video clips (if any) and software, as well as the compilation of businesses, lenders and opportunities listed on the Websites, are the property of LUSA or it otherwise has the right to use them as part of the Websites, and are subject to the copyright or other intellectual property rights of LUSA and/or to the terms of licenses held by LUSA. Such intellectual property is protected by federal and state law. Without LUSA’s prior written consent, You may not reproduce, modify, distribute, transmit, republish, display or perform the content and software on the Websites, or of any products or services sold by LUSA. You may copy information from the Websites only as may be strictly necessary for Your own use to view, save, print, or transmit it. The commercial use or public dissemination of any information and data gathered from LUSA is strictly prohibited, unless specifically authorized by LUSA in writing. Any violation of the foregoing clause may subject You to compensatory and punitive damages, and shall specifically also entitle LUSA to equitable relief, in addition to any other available remedies.</p>
          <p>By submitting information or other material to Us, You grant LUSA a perpetual, worldwide, royalty-free, irrevocable, non-exclusive right to use, reproduce, modify, adapt, publish, create derivative works, and distribute such materials or portions of such materials, in any form or medium known or later developed, in furtherance of the terms of this ToU and the actions and transactions contemplated hereby. Nothing in the Websites or elsewhere shall be construed as granting any license or right to use, implied or otherwise, any mark displayed on the Websites without the written permission of LUSA or the third-party owner of the mark. You agree that You will not, and You will not allow others on Your behalf to, decompile, disassemble, reverse engineer, copy, use, merge, disclose, sell or transfer the underlying source code, structure or sequence of LUSA’s technology, or delete, alter, author attributes or copyright notices. You are limited to use the Websites to submit loan applications under the Merchant’s account and You shall use the Portal solely for Your own use and shall not allow others to use the Portal under or through Your account.</p>
          <ol start="7">
            <li><strong> DIGITAL MILLENNIUM COPYRIGHT ACT</strong></li>
          </ol>
          <p>LUSA expects its users to respect the intellectual property rights of others. In Our sole discretion, We may remove Materials that appear to infringe upon the intellectual property rights of others, and it is Our policy to restrict the access rights of repeat infringers. If You believe a work protected by a U.S. copyright You own has been posted without authorization, You may notify the LUSA copyright agent at the address below, and provide the following information:</p>
          <ul>
            <li>A physical or electronic signature of the person authorized to act on behalf of the copyright owner;</li>
            <li>Identification of the copyrighted work(s) claimed to have been infringed;</li>
            <li>A detailed description of the material You claim is infringing, together with information sufficient to enable Us to locate it, including the URL where the infringing material appears;</li>
            <li>Your address, telephone number and e-mail address; and</li>
            <li>
              A statement by You that:
              <ul>
                <li>You, in good faith, believe that the copyrighted material identified is being used in a manner that is not authorized by the copyright owner, its agent or the law; and</li>
                <li>The above information is accurate and, under penalty of perjury, that You are authorized to act on behalf of the owner of the copyright allegedly infringed.</li>
              </ul>
            </li>
          </ul>
          <p>LUSA’s copyright agent can be reached at:</p>
          <p>
            Archer Norris, PLC
            <br />
            333 South Grand Ave Suite 1700
            <br />
            Los Angeles, CA 90071
          </p>
          <p>IMPORTANT NOTE: THE PRECEDING INFORMATION IS PROVIDED EXCLUSIVELY FOR NOTIFYING LUSA THAT YOUR COPYRIGHTED MATERIAL MAY HAVE BEEN INFRINGED. ALL OTHER INQUIRIES AND REQUESTS, OR QUESTIONS ON PRIVACY, WILL NOT RECEIVE A RESPONSE THROUGH THIS PROCESS.</p>
          <ol start="8">
            <li><strong> ELECTRONIC COMMUNICATION</strong></li>
          </ol>
          <p>The communications between You and LUSA are electronic. You must consent to receive communications, including electronic disclosures required by applicable federal or state law, from LUSA in an electronic form, including email. You must consent in order to use the Portal. The terms and conditions for consent to receive electronic communications appears in the loan application, and You agree that all terms and conditions, agreements, notices, disclosures and other communications that We provide to You electronically satisfy any legal requirement that such communications would satisfy if they were in writing. LUSA will use reasonable efforts to honor any request You may have to opt out from receiving electronic communications. You hereby confirm and agree that Your sole remedy in connection with any electronic communication sent by LUSA to You shall be stopping the use of the Portal.</p>
          <ol start="9">
            <li><strong> HYPERLINK POLICY</strong></li>
          </ol>
          <p>The Websites may contain links to third party websites. Any such link is provided only as a convenience. The inclusion of any link does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring by LUSA of any information contained in any third-party website. In no event shall LUSA be responsible for the information contained on any third-party website or Your use of or inability to use such website. You should also be aware that the terms and conditions of such website and the website’s privacy policy may be different from those applicable to Your use of the LUSA Websites. You should read such terms and conditions and privacy policies carefully before using any such third-party website.</p>
          <p>YOUR CORRESPONDENCE AND BUSINESS DEALINGS WITH OTHERS FOUND ON OR THROUGH THE THIRD-PARTY WEBSITES AND/OR SERVICES, INCLUDING, WITHOUT LIMITATION, THE PAYMENT AND DELIVERY OF PRODUCTS AND SERVICES, AND ANY TERMS, CONDITIONS, WARRANTIES AND REPRESENTATIONS ASSOCIATED WITH ANY SUCH DEALINGS, ARE SOLELY BETWEEN YOU AND THE THIRD-PARTY. YOU HEREBY IRREVOCABLY WAIVE ALL RIGHTS RELATED TO, AND RELEASE THE RELEASED PARTIES FROM AND AGAINST, ANY LIABILITIES ARISING FROM OR RELATED TO THE CONTENTS OF ANY THIRD-PARTY WEBSITE.</p>
          <ol start="10">
            <li><strong> THIRD PARTY CONTENT</strong></li>
          </ol>
          <p>Certain portions of the Websites may contain unedited or third-party content. All postings, messages, text, images, links to third-party websites or other materials published or otherwise made available through these sections (the “Content”) are the sole responsibility of the person(s) who originated such Content. LUSA does not control such Content, does not investigate or validate such Content, and is under no obligation to monitor such Content; provided that LUSA reserves the right at all times (but will not have an obligation) to remove any Content in its sole discretion. By using this Content, You understand that You may be exposed to Content that is, without limitation, inaccurate, inappropriate, misleading, unlawful, offensive or otherwise objectionable, and that LUSA makes no representations or warranties regarding the Content and is not responsible or liable in any manner for the Content or the conduct, whether online or offline, of any user. The Content does not constitute legal or financial advice and must not be used in the place of legal counsel or financial due diligence. You should independently evaluate and verify all Content.</p>
          <ol start="11">
            <li><strong> TERMINATION</strong></li>
          </ol>
          <p>If you want to terminate Your agreement with LUSA with respect to this ToU, You may do so by (i) notifying LUSA at 1-800-994-6177 or (ii) closing Your account.&nbsp; Terminating Your agreement with respect to this ToU does not terminate any other agreement(s) You may have with LUSA.</p>
          <p>LUSA may terminate Your right to use the Portal at any time in its sole discretion, with or without cause, and without notice to You. Some circumstances in which LUSA may exercise this right to terminate Your right to use the Portal include, without limitation, (i) You have breached any provision of this ToU, (ii) You have engaged in conduct which LUSA, in its sole discretion, considers to be unacceptable, (iii) LUSA is required by law to do so, or (iv) LUSA no longer provides the Portal.</p>
          <p>The provisions relating to Intellectual Property, Disclaimer of Warranties and Limitation of Liability, Indemnification, Data Storage, Access outside the United States and General shall survive any termination.</p>
          <ol start="12">
            <li><strong> NO WARRANTY; LIMITATION OF LIABILITY</strong></li>
          </ol>
          <p>Any material You access, download, or otherwise obtain through the Websites is obtained at Your own discretion and risk, and You will be solely responsible for any damage to Your computer system or loss of data that results therefrom.</p>
          <p>LUSA AND ALL OF ITS THIRD-PARTY LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING ANY (A) WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE AS TO THE WEBSITES, INCLUDING THE INFORMATION, DATA, SOFTWARE, OR PRODUCTS CONTAINED THEREIN, OR THE RESULTS OBTAINED BY THEIR USE OR AS TO THE PERFORMANCE THEREOF, (B) WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING, (C) WARRANTIES OR CONDITIONS OF UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE ACCESS OR USE, AND (D) ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITES OR ANY PART THEREOF. THE WEBSITES AND ALL COMPONENTS THEREOF ARE PROVIDED ON AN “AS IS” BASIS AND YOUR USE OF THE WEBSITES IS AT YOUR OWN RISK.</p>
          <p>LUSA makes no guarantees or warranties of any kind as to the adequacy, completeness, sufficiency, timeliness or accuracy of any material available in or through the Websites.</p>
          <p>NEITHER LUSA NOR ANY OF THE RELEASED PARTIES SHALL BE LIABLE FOR ANY DAMAGES, INCLUDING BUT NOT LIMITED TO INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR OTHER DAMAGES, INCLUDING LOSS OF EARNINGS, GOODWILL OR DATA, WORK STOPPAGE, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION OR OTHER INTANGIBLE LOSSES (EVEN IF THE PARTIES ENUMERATED ABOVE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF (I) THE USE OR INABILITY TO USE THE WEBSITES OR ANY PART THEREOF, OR (II) ERRORS, OMISSIONS, VIRUSES AND MALICIOUS CODE OR OTHER INACCURACIES IN THE WEBSITES OR ANY PART THEREOF, OR&nbsp; &nbsp;ACCESSIBLE THROUGH THE WEBSITES OR ANY PART THEREOF. THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. TO THE EXTENT THE ABOVE DISCLAIMER OR THE LIMITATIONS SET FORTH HEREIN ARE PROHIBITED BY LAW, THEIR APPLICABILITY SHALL BE LIMITED TO THE MINIMUM PERMITTED BY LAW.</p>
          <p>This limitation on liability includes, but is not limited to, the transmission of any viruses which may infect Your equipment, failure of mechanical or electronic equipment or communication lines, telephone or other interconnect problems (e.g., You cannot access Your internet provider), unauthorized access, theft, operator errors, strikes or other labor problems or any force majeure.</p>
          <p>YOU HEREBY AGREE THAT LUSA’S TOTAL AGGREGATE LIABILITY AND YOUR SOLE REMEDY AGAINST LUSA ARISING OUT OF OR RELATING TO THE USE OF THE PORTAL OR WEBSITE SHALL NOT EXCEED THE TOTAL AMOUNT YOU (OR ANY OTHER USER) PAID, IF ANYTHING, TO LUSA FOR THE PORTAL OR WEBSITE FROM THE EFFECTIVE DATE OF THIS AGREEMENT DURING THE TERM OF THIS AGREEMENT</p>
          <p><strong>13.INDEMNITY</strong></p>
          <p>You agree to indemnify and hold LUSA and Released Parties harmless from and against any suit, action, claim, demand, penalty or loss, including reasonable attorneys’ fees, made by or resulting from any third party due to or arising out of Your use of the Websites, any materials contained in the Websites, any breach of this ToU or the materials it incorporates by reference, including, without limitation, the LUSA Privacy Policy, or Your violation of any law, regulation, order or other legal mandate, or the rights of a third party.</p>
          <ol start="14">
            <li><strong> CHOICE OF LAW; DISPUTES</strong></li>
          </ol>
          <p>The construction, validity and performance of this ToU and all non-contractual obligations arising from or connected with this ToU shall be governed by the laws of the State of California without giving effect to conflict of laws principles thereof. Each party irrevocably agrees to submit to the exclusive jurisdiction of the state and federal courts sitting in Los Angeles County any claim or matter arising under or in connection with this ToU, provided that the limitation set forth herein shall not prevent LUSA from seeking injunctive relief in any other jurisdiction. EACH PARTY, TO THE EXTENT PERMITTED BY LAW, KNOWINGLY, VOLUNTARILY, UNCONDITIONALLY AND INTENTIONALLY WAIVES ITS RIGHT TO A TRIAL BY JURY IN ANY ACTION OR LEGAL PROCEEDING ARISING OUT OF OR RELATING TO THIS TOU. The services of the Websites are intended for viewing and use only within the 50 U.S. states and the District of Columbia, and shall be governed solely by U.S. law.</p>
          <ol start="15">
            <li><strong> DATA STORAGE; ACCESS OUTSIDE OF THE UNITED STATES</strong></li>
          </ol>
          <p>Your personal information will be processed for LUSA by TransUnion, Equifax, financing institutions or other third party providers whose data protection and privacy protections may not afford the same level of protection as required by laws of certain countries, such as the member nations of the European Union. We make no claims that the Websites or any of its contents are appropriate or may be downloaded or accessed outside of the United States. If you access the Websites from outside the United States, You do so at Your own risk and are responsible for compliance with the laws of Your country. You may not use or export any content of the Websites in violation of U.S. export laws and regulations.</p>
          <p>&nbsp;</p>
          <ol start="16">
            <li><strong> GENERAL</strong></li>
          </ol>
          <p>All rights not otherwise expressly granted to You by this ToU are reserved to LUSA. You agree that no joint venture, partnership, employment, or agency relationship exists between You and LUSA as a result of this ToU or any use of the Websites. The failure of LUSA to exercise or enforce any right or provision of this ToU shall not constitute a waiver of such right or provision. If any provision of this ToU is found by a court of competent jurisdiction to be invalid or unenforceable, the parties agree that the court should give effect to the parties’ intentions as reflected in such provision, and the other provisions of this ToU shall remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Websites or this ToU must be filed within one (1) year after such claim or cause of action arose or be forever barred. The section titles in this ToU are for convenience only and have no legal or contractual effect. Neither the course of conduct between You and LUSA, nor trade practice shall act to modify any provision of this ToU. This ToU are not assignable, transferable or sub-licensable by You.</p>
          <ol start="17">
            <li><strong> ARBITRATION CLAUSE</strong></li>
          </ol>
          <p>BY CONSENTING TO THIS TOU, YOU ACKNOWLEDGE (I) HAVING READ THIS ARBITRATION CLAUSE, (II) CONSULTING OR HAVING THE FREEDOM TO CONSULT COUNSEL OF YOUR CHOOSING TO EXPLAIN THIS ARBITRATION CLAUSE TO YOU, (III) THAT THIS ARBITRATION CLAUSE LIMITS YOUR RIGHTS IN THE EVENT OF A DISPUTE BETWEEN YOU AND US, AND (IV) YOU WAIVE YOUR RIGHTS TO A JURY TRIAL AS WELL AS TO PARTICIPATE IN CLASS ACTION LITIGATION, AND YOU AGREE TO ARBITRATE DISPUTES.</p>
          <p>YOU UNDERSTAND THAT YOU HAVE THE RIGHT TO REJECT THIS PROVISION AS PROVIDED IN PARAGRAPH (e) BELOW.&nbsp; IF YOU DO NOT REJECT THIS AGREEMENT TO ARBITRATE, YOU GIVE UP YOUR RIGHT TO GO TO COURT AND CONTROVERSIES OR DISPUTES BETWEEN US WILL BE RESOLVED BY A NEUTRAL ARBITRATOR INSTEAD OF A JUDGE OR JURY, USING RULES THAT ARE SIMPLER AND MORE LIMITED THAN IN A COURT.&nbsp; ARBITRATOR DECISIONS ARE SUBJECT TO VERY LIMITED REVIEW BY A COURT.&nbsp; ARBITRATION WILL PROCEED INDIVIDUALLY – CLASS ACTIONS AND SIMILAR PROCEDURES WILL NOT BE AVAILABLE TO YOU.</p>
          <ol>
            <li>
              <u>Agreement to Arbitrate</u>
              : Either party to this ToU may, at its sole election, require that the sole and exclusive forum and remedy for resolution of a Claim (defined below) be final and binding arbitration pursuant to this Arbitration Clause unless You opt out as provided in paragraph (e) below. This agreement to arbitrate is made pursuant to a transaction involving interstate commerce, and shall be governed by, and enforceable under, the Federal Arbitration Act (the “FAA”), 9 U.S.C. §1 et seq., and (to the extent State law is applicable), the laws of the State of California.
            </li>
            <li>
              <u>Covered Claims</u>
              : As used in this Arbitration Clause, “Claim” shall include but is not limited to any past, present, or future claim, dispute, or controversy involving You (or persons claiming through or connected with You), on the one hand, and Us (or persons claiming through or connected with Us), on the other hand, relating to or arising out of this ToU, the Websites, and/or the activities or relationships that involve, lead to, or result from any of the foregoing, including (except to the extent provided otherwise in the last sentence of (d) below) the validity or enforceability of this Arbitration Clause, any part thereof, or any loan transaction entered into in connection with the use of the Websites. Claims are subject to arbitration regardless of whether they arise from contract, tort (intentional or otherwise), a constitution, statute, common law, or principles of equity, or otherwise. Claims include matters arising as initial claims, counter-claims, cross-claims, third-party claims, or otherwise. The scope of this Arbitration Clause is to be given the broadest possible interpretation that is enforceable.
            </li>
            <li>
              <u>Claims Not Covered</u>
              : Claims (whether brought initially or by counter- or cross-claim) are not subject to arbitration if they are filed by You or Us in a small claims court, so long as the case remains in such court and only individual claims for relief are advanced in the case.
            </li>
            <li>
              <u>No Class Arbitration or Consolidation or Joinder of Parties</u>
              : NO ARBITRATION SHALL PROCEED ON A CLASS, REPRESENTATIVE, OR COLLECTIVE BASIS (INCLUDING AS PRIVATE ATTORNEY GENERAL ON BEHALF OF OTHERS), EVEN IF THE CLAIM(S) THAT ARE THE SUBJECT OF THE ARBITRATION HAD PREVIOUSLY BEEN ASSERTED (OR COULD HAVE BEEN ASSERTED) IN A COURT AS CLASS REPRESENTATIVE OR COLLECTIVE ACTIONS IN A COURT. Unless consented to in writing by all parties to the arbitration, no party to the arbitration may join, consolidate, or otherwise bring claims for or on behalf of two or more individuals or unrelated corporate entities in the same arbitration unless those persons are parties to a single transaction. Unless consented to in writing by all parties to the arbitration, an award in arbitration shall determine the rights and obligations of the named parties only, and only with respect to the claims in arbitration, and shall not (i) determine the rights, obligations, or interests of anyone other than a named party, or resolve any Claim of anyone other than a named party, nor (ii) make an award for the benefit of, or against, anyone other than a named party. No administrator or arbitrator shall have the power or authority to waive, modify, or fail to enforce this section (d), and any attempt to do so, whether by rule, policy, arbitration decision or otherwise, shall be invalid and unenforceable. Any challenge to the validity of this section (d) shall be determined exclusively by a court and not by the administrator or any arbitrator.
            </li>
            <li>
              <u>Opting Out</u>
              : You may opt out of this Arbitration Clause for all purposes by sending an arbitration opt-out notice to 15303 Ventura Blvd., Suite 850, Sherman Oaks CA 91403, which is received at the specified address within thirty (30) days of the date of Your electronic acceptance of the terms of this ToU. The opt-out notice must (i) clearly state that You are rejecting arbitration, (ii) identify this ToU to which the opt-out applies by date, (iii) provide your name and address, and (iv) be signed by You. You may send the opt-out notice in any manner You see fit as long as it is received at the specified address within the specified time. No other methods can be used to opt out of this Arbitration Clause. If the opt-out notice is sent on Your behalf by a third party, such third party must include evidence of his or her authority to submit the opt out notice on Your behalf.
            </li>
            <li>
              <u>Initiation of Arbitration</u>
              : The arbitration shall be administered by the American Arbitration Association (“AAA”) before a single arbitrator under the AAA’s Consumer Arbitration Rules, or by a mutually agreeable administrator before a single arbitrator, as modified by this Arbitration Clause. Information about the arbitration process for AAA can be obtained from the AAA website at
              {' '}
              <a href="http://www.adr.org">adr.org</a>
              .&nbsp; The arbitrator shall be selected from the AAA (or mutually agreeable administrator) panel of neutrals then active on the roster maintained by the AAA (or mutually agreeable administrator) office located in the city of or nearest to Your billing address and, unless otherwise mutually agreed, the arbitrator shall be a retired federal judge, a retired state appellate judge, a retired state trial judge or a lawyer with at least fifteen (15) years of experience, in that order of preference.&nbsp; In the event that the AAA or mutually agreeable administrator is unable or unwilling to handle the Claim for any reason, then the matter shall be arbitrated instead by a neutral arbitrator selected by agreement of the parties (or, if the parties cannot agree, selected by a court in accordance with the FAA).&nbsp; In the case of a conflict between the rules and policies of the administrator and this Arbitration Clause, this Arbitration Clause shall control, unless all parties to the arbitration consent to have the rules and policies of the arbitration administrator apply.
            </li>
            <li>
              <u>Arbitration Procedures; Applicable Law; Arbitrator’s Decision</u>
              : Disputes in arbitration are decided by a neutral arbitrator instead of a judge or jury. You may represent yourself in arbitration, or You may be represented by a lawyer.&nbsp; Under this agreement to arbitrate, a single arbitrator designated by the arbitration administrator will decide the Claim under applicable law.&nbsp; The arbitrator is bound by the terms of this agreement to arbitrate. The AAA or mutually agreeable administrator will apply its code of procedures in effect at the time the arbitration is filed.&nbsp; If there is a conflict between that code of procedures and this arbitration agreement, this arbitration provision will control.&nbsp; The arbitrator will honor all claims of privilege recognized by law.&nbsp; The arbitrator shall take steps to reasonably protect confidential information.&nbsp; Subject to the prohibition on class, representative, and consolidation procedures, the arbitrator will have the power to award to a party any damages or other relief (including relief in equity) provided for under appliable law.&nbsp; That is, a party will be entitled to recover in arbitration any damages or other relief that it could recover if it prevailed in a court proceeding.&nbsp; An arbitrator’s award shall consist of a written statement setting forth the disposition of each Claim.&nbsp; At the request of any party, the arbitrator shall also set forth a written explanation of the essential findings and conclusions on which the award is based.&nbsp; An arbitration award shall decide the rights and obligations only of the parties named in the arbitration, and shall not have any bearing on another party or dispute.&nbsp; Any in-person hearing will take place in the federal judicial district that includes Your billing address at the time the Claim is filed, unless the parties agree to a different place. &nbsp;The arbitrator’s decision will be final and binding on the parties.
            </li>
            <li>
              <u>Appeals</u>
              : A party can file a written appeal to the arbitration administrator within thirty (30) days after an award is issued. In the event of such an appeal, any opposing party may cross-appeal within thirty (30) days after notice of the appeal. The appeal will proceed before a panel of three (3) neutral arbitrators designated by the same arbitration administrator. The panel will decide anew (de novo) all factual and legal issues following the same rules of procedure, by majority vote, any aspect of the original decision objected to.&nbsp; The costs of such an appeal will be borne in accordance with the paragraph (i) below.&nbsp; Any review by a court shall be governed by the FAA.&nbsp; Any final arbitration award will be binding on the named parties and enforceable by any court having jurisdiction.
            </li>
            <li>
              <u>Fees and Costs</u>
              : We will pay any costs that are required to be paid by Us under the arbitration administrator’s rules of procedure.&nbsp; If You file the arbitration, You will pay Your share of the filing fee unless You seek and qualify for a fee waiver under the applicable rules of the arbitration administrator.&nbsp; We shall pay the administrator’s hearing fees for one (1) full day of arbitration hearings. Fees for hearings that exceed one (1) day will be paid by the party requesting the hearing, unless the administrator’s rules or applicable law require otherwise, or You request that We pay them and We agree to do so. The party filing an appeal shall be responsible for all fees and costs of the appeal unless prohibited by administrator’s rules of procedure.&nbsp; All parties are responsible for their own attorney’s fees, expert fees, and any other expenses, unless the arbitrator awards such fees or expenses to a party if allowed by applicable law.
            </li>
            <li>
              <u>Survival</u>
              : This Arbitration Clause shall survive (i) suspension, termination, revocation, closure, or amendments to this ToU and the relationship of the parties, (ii) the bankruptcy or insolvency of any party or other person, and (iii) any transfer of any loan or note or any other promissory note(s) which You owe, or any amounts owed on such loans or notes, to any other person or entity.
            </li>
            <li>
              <u>Severability</u>
              : If any portion of this Arbitration Clause other than sections (c) and (d) is deemed invalid or unenforceable, the remaining portions of this Arbitration Provision shall nevertheless remain valid and in force. If an arbitration is brought on a class, representative, or collective basis, and the limitations on such proceedings in section (c) and (d) are finally adjudicated pursuant to the last sentence of section (d) to be unenforceable, then no arbitration shall be had. In no event shall any invalidation be deemed to authorize an arbitrator to determine Claims or make awards beyond those authorized in this Arbitration Clause.
            </li>
            <li>
              <u>Amendments</u>
              : This Arbitration Clause may not be amended, severed, or waived, except as expressly provided in this Arbitration Clause or in a written agreement between You and Us.
            </li>
            <li>
              <u>Notice to California Account Holders</u>
              : This agreement to arbitrate shall not be construed to prevent You from seeking in the arbitration the remedy of public injunctive relief if (i) You reside in California, (ii) You resided in California at the time You entered into this agreement to arbitrate, or (iii) the billing address for Your account is a California address.
            </li>
          </ol>
          <p>THE PARTIES ACKNOWLEDGE THAT THEY HAVE A RIGHT TO LITIGATE CLAIMS THROUGH A COURT BEFORE A JUDGE OR JURY, BUT WILL NOT HAVE THAT RIGHT IF ANY PARTY ELECTS ARBITRATION PURSUANT TO THIS ARBITRATION CLAUSE. THE PARTIES HEREBY KNOWINGLY AND VOLUNTARILY WAIVE THEIR RIGHTS TO LITIGATE SUCH CLAIMS IN A COURT BEFORE A JUDGE OR JURY UPON ELECTION OF ARBITRATION BY ANY PARTY.</p>
          <ol start="18">
            <li><strong> TELEPHONE COMMUNICATIONS</strong></li>
          </ol>
          <p>By accepting this ToU, You are providing express written permission authorizing LUSA and Our agents, servicers, contractors, subsidiaries, affiliates, officers, and other partners and employees to contact You at any phone number (including mobile, cellular/wireless, or similar devices) or email address You provide at an time, for any lawful purpose.&nbsp; Such lawful purposes include, but are not limited to, obtaining information, verification and identification purposes, account transactions or servicing related matters, suspected fraud or identity theft, collection on the account, and providing information about special products and services.&nbsp; The ways in which We may contact You include live operator, automatic telephone dialing system (auto-dialer), prerecorded and artificial voice message, text/SMS message or email.&nbsp; Phone numbers and email addresses You provide include those You give to Us, those from which You or someone acting on Your behalf contact Us, or which We obtain through other means (such as skip tracing and caller ID capture).&nbsp; If You provide a phone number for which You are not the owner, You confirm You are authorized to provide that number.&nbsp; You agree to pay any fee(s) or charge(s) that You may incur for incoming communications from Us or outgoing communications to Us, to or from any such number or email address, without reimbursement from Us.&nbsp; If one of Our agents or representatives calls, s/he may also leave a message on Your answering machine, voicemail, or through a text message.&nbsp; You agree to alert us whenever you stop using a particular telephone number.</p>
          <p>Indemnification: If You provide telephone number(s) for which You are not the subscriber, or fail to notify Us when You cease to be the subscriber for a telephone number You previously gave Us, You agree to indemnify Us, Our subsidiaries, affiliates, officers, agents, servicers, contractors and other partners and employees for any costs and expenses, including reasonable attorneys’ fees, incurred as a result of Us contacting or attempting to contact You at the number(s).&nbsp; Your obligation under this paragraph shall survive termination of this ToU.</p>
          <p>Communication Revocation: You may revoke Your authorization for most communications.&nbsp; However, You cannot revoke authorization for fraud related communications or legally required communications.&nbsp; To change Your communication preferences or to revoke consent, You must write Us.&nbsp; Please (i) include Your name, mailing address and the last four (4) digits of Your account number, (ii) specify whether You are changing Your preferences regarding mail, telephone calls, emails and/or text or SMS messages, (iii) provide the specific phone number(s) and/or email address for which You are requesting communications to cease, and (iv) send this written notice to LendingUSA, LLC, 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403.</p>
          <p>
            Marketing Calls and Texts: We will not use autodialed or prerecorded calls or texts to contact You for marketing purposes unless You provide us with prior express written consent. If You have provided such consent to receive marketing communications, You agree that (i) lender, (ii) LUSA, (iii) lenders and/or LUSA’s subsidiaries, affiliates, officers, agents, servicers, contractors, other partners and employees, representatives, or anyone calling or texting on Lender or LUSA’s behalf, may use autodialed or prerecorded phone calls and autodialed SMS text messages to contact You at the number(s) You provide for the purpose of describing goods and services that may be of interest to You, whether offered by Lender, LUSA or third parties. If provided, Your consent will be effective even if the number(s) You have provided is registered on any state or federal Do Not Call list. This consent for telemarketing communications shall remain in effect until You withdraw it. Your consent to receive marketing communications is not a condition of obtaining a loan and may be withdrawn at any time by providing written notice to LUSA, c/o Compliance Department, at 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403, or by e-mailing&nbsp;
            <a href="mailto:customercare@lendingusa.com">customercare@lendingusa.com</a>
            . You may also reply “STOP” to opt out of receiving autodialed text messages only. These telephone calls and text messages may incur message, data and access fees from Your telephone provider, and You agree to pay any fee(s) or charge(s) that You may incur for incoming communications from Us or outgoing communications to Us, to or from any such number(s) or email address, without reimbursement from Us.
          </p>
          <p><strong>&nbsp;</strong></p>
          <p><strong>QUESTIONS OR COMMENTS</strong></p>
          <p>LUSA welcomes questions and comments about this ToU. Please direct questions or comments to the email address or phone number below:</p>
          <p>
            <a href="mailto:CustomerCare@LendingUSA.com">CustomerCare@LendingUSA.com</a>
            {' '}
            or (800) 994-6177
          </p>
          <p><strong>&nbsp;</strong></p>
          <p><strong>Terms of Use – Service Provider (“Merchant”)</strong></p>
          <p><strong>Rev. 12/2021</strong></p>
          <p>This Terms of Use (“ToU”) governs the use of LendingUSA, LLC’s web-based portal (“Portal”) and/or any of its affiliated websites or platforms (collectively with “Portal”, “Websites”) that are managed by LendingUSA, LLC, a Delaware limited liability company having a principal place of business at 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403 which, together with its subsidiaries, affiliates, assignees and its and their respective officers, directors, employees and agents, shall be referred to in this agreement as “LUSA”, “We”, “Our” or “Us.”</p>
          <p>“You”, “Your” or “Yours” means the service provider using the Portal.&nbsp; You should regularly review this ToU, as it is subject to change.</p>
          <p>
            This ToU applies only to service providers (“Merchants”) and does not govern LUSA’s relationship with consumers.&nbsp; Please see LUSA’s ToU that relates to consumers
            {' '}
            <a href="https://lendingusa.com/terms-of-use/">here</a>
            .
          </p>
          <ol>
            <li><strong> GENERAL TERMS AND CONDITIONS</strong></li>
          </ol>
          <p>All uses of the Websites are subject to this ToU. By visiting the Websites, You acknowledge, accept, and agree to all the terms, conditions, and privacy policies described or incorporated by reference below. This ToU represent a binding contract between You and Us, and is in addition to any other agreements (i) between You and Us, (ii) between You and any other Merchant or lender, if any, and (iii) that govern Your use of loan financing, products, services, content, tools, and information available on the Websites. If You do not agree with anything contained in this ToU, please do not submit information to, access information from, or otherwise utilize the Websites.</p>
          <p>You agree that We may modify this ToU from time to time. Any modification is effective immediately upon posting on the Websites. Your continued use of the Websites following modification of this ToU, in addition to Your affirmative consent to such modifications, will be conclusively deemed to signify Your acceptance of the modification. We reserve the right, at any time, to modify or discontinue, temporarily or permanently, the Websites (or any part thereof) without notice.</p>
          <p>You agree to keep business information and trade secrets of LUSA including, but not limited to, terms and pricing that are not publicly disclosed and any user account information, confidential until such information becomes known to the public generally and except to the extent that disclosure may be required by law, regulation or legal process.</p>
          <ol start="2">
            <li><strong> PRIVACY</strong></li>
          </ol>
          <p>
            Your privacy is very important to Us. Our Privacy Policy explains how We treat Your personal information and protect Your privacy when You are using the Websites. To view LUSA’s Privacy Policy, please click
            {' '}
            <a href="https://lendingusa.com/privacy-policy/">here</a>
            . By using the Websites, You agree that We may use Your information as set forth in the Privacy Policy. You are subject to the LUSA’s Privacy Policy that appears
            {' '}
            <a href="https://lendingusa.com/privacy-policy/">here</a>
            {' '}
            and is incorporated herein by reference.
          </p>
          <ol start="3">
            <li><strong> REGISTRATION</strong></li>
          </ol>
          <p>To use Our Portal, You must complete the registration process. You agree that the registration information You provide is accurate, complete and current, and You further agree to promptly update that information to keep it accurate, complete and current.&nbsp; If You register on behalf of a business entity or other organization, You represent and warrant that You have the authority to provide the information required and to bind the organization to this ToU.</p>
          <p>Once You have registered, You will receive or will be able to create or input a user identification (“User ID”) and password in order to access the Portal. You are responsible for maintaining the confidentiality of Your User ID and password, and are responsible for all activities that occur using Your User ID and password, whether or not expressly authorized by You. LUSA will not be responsible for any damages resulting from the unauthorized use of Your User ID or password. You agree to notify Us immediately of any such unauthorized use or any other breach of security. You are responsible for all transactions entered into on the Portal under your User ID and are responsible for preventing any unauthorized use of Your User ID and password. Individuals and entities whose privilege to use the Portal has previously been terminated by LUSA may not register for the Portal, nor may You designate any of those individuals to use Your User ID on Your behalf.</p>
          <ol start="4">
            <li><strong> USE OF THE PORTAL; LIMITATIONS; ACCEPTABLE USE</strong></li>
          </ol>
          <p>The Portal is designed to connect Your potential customers with potential lenders to enable Your potential customers to finance the purchase of goods or services offered by You.</p>
          <p>You understand, acknowledge and agree that (i) LUSA is not involved in the actual transaction between You and Your customer, (ii) LUSA is not providing the services of any lender, (iii) LUSA is not liable for a customer’s repayment of any loan once a transaction among a customer, a Merchant and lender has been completed, (iv) LUSA does not make lending decisions on behalf of any Merchant or lender, (v) LUSA does not offer or make solicitations to lend, and (vi) LUSA is not an agent or representative of any Merchant or lender.</p>
          <p>You understand, acknowledge and agree that LUSA makes no representations or warranties, including without limitation, as to any information contained on an application which was provided by You, or as to any results from any Merchant’s or lender’s use of the personal nonpublic financial information provided on the Portal, including without limitation, whether or not a consumer will be approved for a financing transaction by a Merchant or a lender, or that a certain percentage of consumers will be so approved.&nbsp; You confirm, acknowledge and agree that You will provide true and accurate information about Yourself and/or Your businesses, and to update and maintain such information.</p>
          <p>Your use of the Portal is conditioned upon the following representations being true: (i) You are of the age of majority in your state of residence; (ii) You are able to form a binding contract with Us; (iii) You are not prohibited by law from accessing the Portal or have not previously been banned, terminated or otherwise denied access to the Portal; and (iv) You are not acting on behalf of a person whose access to the Portal has been previously terminated or otherwise denied by Us.</p>
          <p>As conditions of Your use of the Portal, You agree to comply with this ToU and all applicable laws and regulations in connection with Your use of the Portal. You will not violate, attempt to violate or knowingly facilitate the violation of the security (including access control or authentication systems) or integrity of the Portal. Without limiting the foregoing, You agree not to (i) attempt to or enable others to attempt to gain unauthorized access to any other accounts, computer systems or networks connected to any LUSA server or to any of the content provided through the Portal (the “Materials”), through hacking, password theft or any other means, (ii) impersonate another user of the Portal, (iii) attempt, or enable others to attempt, to obtain any Materials through any means that LUSA has not intentionally made available on the Portal including using any automatic or manual process to search or harvest information from the Portal, or (iv) use the Portal or the Materials in any manner that could damage, disable, overburden or impair any LUSA server or the network(s) connected to any LUSA server, or that might interfere with any other person’s access to or use or enjoyment of any Materials.</p>
          <p>Without limiting the above, You are expressly prohibited from (i) any resale or commercial use of the Portal, (ii) any collection and use of any product or service listings, descriptions, prices or any other information posted on the Portal for any purpose other than Your own personal use or as otherwise permitted in an agreement between LUSA and yourself (“Permitted Use”), including, without limitation, any purpose competitive to LUSA or any commercial purpose, including marketing, (iii) any downloading or copying of any materials contained in the Portal for any reason other than for a Permitted Use, or any use of data mining, robots or similar data gathering and extraction tools, or (iv) using the Portal to access or collect any personally identifiable information, including account names, email addresses or other such information for any purpose, including, without limitation, commercial purposes.</p>
          <p>LUSA does not have any part in the creation of the Materials and information which You provide and LUSA cannot and does not confirm the accuracy of information provided and/or information contained in the written Materials provided, if any. If LUSA permits You to input information into the Portal, You may not input any information or content that:</p>
          <ul>
            <li>is promotional in nature, including solicitations for funds or businesses, without the prior written authorization of LUSA;</li>
            <li>constitutes junk mail, unsolicited commercial messages, spam, chain letters, pyramid schemes or the like;</li>
            <li>is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, is racially, ethnically, religiously or otherwise objectionable, or otherwise violates the legal rights of others;</li>
            <li>You do not have the right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</li>
            <li>infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party; or</li>
            <li>contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware, or telecommunications equipment.</li>
          </ul>
          <p>LUSA reserves the right to edit, restrict or remove any content You provide for any reason at any time. The information and materials made available through the Portal may contain typographical errors or inaccuracies. In addition, LUSA does not control the information provided by other users that is made available through the Portal. LUSA reserves the right to refuse service, terminate relationships, and/or cancel orders in its discretion. You agree that You will have no claim against LUSA, its affiliates, successors, assigns, shareholders, partners, members, officers, directors, managers, licensors, employees and advisors in their individual and representative capacities, and LUSA’s affiliate’s respective successors, assigns, shareholders, partners, members, officers, directors, managers, licensors, employees and advisors in their individual and representative capacities (collectively, the “Released Parties”), for any actual or alleged infringement of any proprietary rights, rights of privacy or publicity, moral rights or rights of attribution in connection with Our use of any content You provide.</p>
          <ol start="5">
            <li><strong> E-MAIL</strong></li>
          </ol>
          <p>YOU MAY NOT SEND UNSOLICITED E-MAILS OR E-MAILS THAT INCLUDE FALSE OR MISLEADING INFORMATION IN THE RETURN ADDRESS OR IN THE SUBJECT LINE TO ANYONE WHOSE E-MAIL ADDRESS INCLUDES THE DOMAIN NAME LENDINGUSA.COM. YOU MAY NOT USE OUR DOMAIN NAME AS A PSEUDONYMOUS RETURN E-MAIL ADDRESS FOR ANY COMMUNICATIONS THAT YOU TRANSMIT FROM ANOTHER LOCATION OR THROUGH ANOTHER SERVICE. YOU MAY NOT PRETEND TO BE SOMEONE ELSE – OR SPOOF THEIR IDENTITY – WHEN USING THE PORTAL OR THE WEBSITES.</p>
          <ol start="6">
            <li><strong> INTELLECTUAL PROPERTY; COPYRIGHT</strong></li>
          </ol>
          <p>The contents of the Websites and any products or services provided or sold by LUSA, including the likeness, text, graphics, logos, button icons, images, audio and video clips (if any) and software, as well as the compilation of businesses, lenders and opportunities listed on the Websites, are the property of LUSA or it otherwise has the right to use them as part of the Websites, and are subject to the copyright or other intellectual property rights of LUSA and/or to the terms of licenses held by LUSA. Such intellectual property is protected by federal and state law. Without LUSA’s prior written consent You may not reproduce, modify, distribute, transmit, republish, display or perform the content and software on the Websites, or of any products or services sold by LUSA. You may copy information from the Websites only as may be strictly necessary for Your own use to view, save, print, or transmit it. The commercial use or public dissemination of any information and data gathered from LUSA is strictly prohibited, unless specifically authorized by LUSA in writing. Any violation of the foregoing clause may subject You to compensatory and punitive damages, and shall specifically also entitle LUSA to equitable relief, in addition to any other available remedies.</p>
          <p>By submitting information or other material to Us, You grant LUSA a perpetual, worldwide, royalty-free, irrevocable, non-exclusive right to use, reproduce, modify, adapt, publish, create derivative works, and distribute such materials or portions of such materials, in any form or medium known or later developed, in furtherance of the terms of this ToU and the actions and transactions contemplated hereby. Nothing in the Websites or elsewhere shall be construed as granting any license or right to use, implied or otherwise, any mark displayed on the Websites without the written permission of LUSA or the third-party owner of the mark. You agree that You will not, and You will not allow others on your behalf to, decompile, disassemble, reverse engineer, copy, use, merge, disclose, sell or transfer the underlying source code, structure or sequence of LUSA’s technology, or delete, alter, author attributes or copyright notices. You are limited to use the Websites to submit loan applications on behalf of consumers under Your account or to carry out any of the transactions anticipated by Your merchant agreement or program participation agreement and to fulfill your obligations under them. You shall use the Portal solely for Your own use and shall not allow others to use the Portal under or through Your account.</p>
          <ol start="7">
            <li><strong> DIGITAL MILLENNIUM COPYRIGHT ACT</strong></li>
          </ol>
          <p>LUSA expects its users to respect the intellectual property rights of others. In Our sole discretion, We may remove Materials that appear to infringe upon the intellectual property rights of others, and it is Our policy to restrict the access rights of repeat infringers. If You believe a work protected by a U.S. copyright You own has been posted without authorization, You may notify the LUSA copyright agent at the address below, and provide the following information:</p>
          <ul>
            <li>A physical or electronic signature of the person authorized to act on behalf of the copyright owner;</li>
            <li>Identification of the copyrighted work or works claimed to have been infringed;</li>
            <li>A detailed description of the material You claim is infringing, together with information sufficient to enable Us to locate it, including the URL where the infringing material appears;</li>
            <li>Your address, telephone number and e-mail address; and</li>
            <li>
              A statement by You that:
              <ul>
                <li>You, in good faith, believe that the copyrighted material identified is being used in a manner that is not authorized by the copyright owner, its agent or the law; and</li>
                <li>The above information is accurate and, under penalty of perjury, that You are authorized to act on behalf of the owner of the copyright allegedly infringed.</li>
              </ul>
            </li>
          </ul>
          <p>LUSA’s copyright agent can be reached at:</p>
          <p>
            Archer Norris, PLC
            <br />
            333 South Grand Ave Suite 1700
            <br />
            Los Angeles, CA 90071
          </p>
          <p>IMPORTANT NOTE: THE PRECEDING INFORMATION IS PROVIDED EXCLUSIVELY FOR NOTIFYING LUSA THAT YOUR COPYRIGHTED MATERIAL MAY HAVE BEEN INFRINGED. ALL OTHER INQUIRIES AND REQUESTS, OR QUESTIONS ON PRIVACY, WILL NOT RECEIVE A RESPONSE THROUGH THIS PROCESS.</p>
          <p><strong>8.ELECTRONIC COMMUNICATION</strong></p>
          <p>The communications between You and LUSA are electronic. You consent to receive communications, including electronic disclosures required by applicable federal or state law, from LUSA in an electronic form, including email. You also consent to the use of electronic signatures. You must consent to electronic communication and electronic signatures in order to use the Portal. You agree that all terms and conditions, agreements, notices, disclosures and other communications that We provide to You electronically satisfy any legal requirement that such communications would satisfy if they were in writing. LUSA will use reasonable efforts to honor any request You may have to opt out from receiving electronic communications. You hereby confirm and agree that Your sole remedy in connection with any electronic communication sent by LUSA to You shall be stopping the use of the Portal. You understand by clicking links or buttons assenting to our terms, you agree that you received this E-Consent and that you consent to using electronic signatures and communications.</p>
          <p>To access and retain the disclosures electronically, you will need to use a computer or device capable of accessing the Internet and an Internet Browser software program that supports at least 256-bit encryption, such as Microsoft® Internet Explorer 11+, Safari 7+ and the evergreen Chrome, Firefox or Edge. To read some documents, you may need a PDF file reader like Adobe® Acrobat Reader, Xpdf® or Foxit®. If these requirements change while you are maintaining an active relationship with Us, and the change creates a material risk that you may not be able to receive disclosures electronically, We will notify you of these changes. You may also mail your written request to LendingUSA, at the following address: 15303 Ventura Blvd. Suite 850, Sherman Oaks, CA 91403. We will provide paper copies at no charge.</p>
          <ol start="9">
            <li><strong> HYPERLINK POLICY</strong></li>
          </ol>
          <p>The Websites may contain links to third party websites. Any such link is provided only as a convenience. The inclusion of any link does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring by LUSA of any information contained in any third-party website. In no event shall LUSA be responsible for the information contained on any third-party website or Your use of or inability to use such website. You should also be aware that the terms and conditions of such website and the website’s privacy policy may be different from those applicable to Your use of the LUSA Websites. You should read such terms and conditions and privacy policies carefully before using any such third-party website.</p>
          <p>YOUR CORRESPONDENCE AND BUSINESS DEALINGS WITH OTHERS FOUND ON OR THROUGH THE THIRD-PARTY WEBSITES AND/OR SERVICES, INCLUDING, WITHOUT LIMITATION, THE PAYMENT AND DELIVERY OF PRODUCTS AND SERVICES, AND ANY TERMS, CONDITIONS, WARRANTIES AND REPRESENTATIONS ASSOCIATED WITH ANY SUCH DEALINGS, ARE SOLELY BETWEEN YOU AND THE THIRD-PARTY. YOU HEREBY IRREVOCABLY WAIVE ALL RIGHTS RELATED TO, AND RELEASE THE RELEASED PARTIES FROM AND AGAINST, ANY LIABILITIES ARISING FROM OR RELATED TO THE CONTENTS OF ANY THIRD-PARTY WEBSITE.</p>
          <ol start="10">
            <li><strong> THIRD PARTY CONTENT</strong></li>
          </ol>
          <p>Certain portions of the Websites may contain unedited or third-party content. All postings, messages, text, images, links to third-party websites or other materials published or otherwise made available through these sections (the “Content”) are the sole responsibility of the person(s) who originated such Content. LUSA does not control such Content, does not investigate or validate such Content, and is under no obligation to monitor such Content; provided that LUSA reserves the right at all times (but will not have an obligation) to remove any Content in its sole discretion. By using this Content, You understand that You may be exposed to Content that is, without limitation, inaccurate, inappropriate, misleading, unlawful, offensive or otherwise objectionable, and that LUSA makes no representations or warranties regarding the Content and is not responsible or liable in any manner for the Content or the conduct, whether online or offline, of any user. The Content does not constitute legal or financial advice and must not be used in the place of legal counsel or financial due diligence. You should independently evaluate and verify all Content.</p>
          <ol start="11">
            <li><strong> TERMINATION</strong></li>
          </ol>
          <p>If you want to terminate Your agreement with LUSA with respect to this ToU, You may do so by (i) notifying LUSA at 1-800-994-6177 or (ii) closing Your account.&nbsp; Terminating Your agreement with respect to this ToU does not terminate any other agreement(s) You may have with LUSA.</p>
          <p>LUSA may terminate Your right to use the Portal at any time in its sole discretion, with or without cause, and without notice to You. Some circumstances in which LUSA may exercise this right to terminate Your right to use the Portal include, without limitation, (i) You have breached any provision of this ToU, (ii) You have engaged in conduct which LUSA, in its sole discretion, considers to be unacceptable, (iii) LUSA is required by law to do so, or (iv) LUSA no longer provides the Portal.</p>
          <p>The provisions relating to Intellectual Property, Disclaimer of Warranties and Limitation of Liability, Indemnification, Data Storage, Access outside the United States and General shall survive any termination.</p>
          <ol start="12">
            <li><strong> NO WARRANTY; LIMITATION OF LIABILITY</strong></li>
          </ol>
          <p>Any material You access, download, or otherwise obtain through the Websites is obtained at Your own discretion and risk, and You will be solely responsible for any damage to Your computer system or loss of data that results therefrom.</p>
          <p>LUSA AND ALL OF ITS THIRD-PARTY LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING ANY (A) WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE AS TO THE WEBSITES, INCLUDING THE INFORMATION, DATA, SOFTWARE, OR PRODUCTS CONTAINED THEREIN, OR THE RESULTS OBTAINED BY THEIR USE OR AS TO THE PERFORMANCE THEREOF, (B) WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING, (C) WARRANTIES OR CONDITIONS OF UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE ACCESS OR USE, AND (D) ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITES OR ANY PART THEREOF. THE WEBSITES AND ALL COMPONENTS THEREOF ARE PROVIDED ON AN “AS IS” BASIS AND YOUR USE OF THE WEBSITES IS AT YOUR OWN RISK.</p>
          <p>LUSA makes no guarantees or warranties of any kind as to the adequacy, completeness, sufficiency, timeliness or accuracy of any material available in or through the Portal.</p>
          <p>NEITHER LUSA NOR ANY OF THE RELEASED PARTIES SHALL BE LIABLE FOR ANY DAMAGES, INCLUDING BUT NOT LIMITED TO INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR OTHER DAMAGES, INCLUDING LOSS OF EARNINGS, GOODWILL OR DATA, WORK STOPPAGE, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION OR OTHER INTANGIBLE LOSSES (EVEN IF THE PARTIES ENUMERATED ABOVE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF (I) THE USE OR INABILITY TO USE THE WEBSITES OR ANY PART THEREOF, OR (II) ERRORS, OMISSIONS, VIRUSES AND MALICIOUS CODE OR OTHER INACCURACIES IN THE WEBSITES OR ANY PART THEREOF, OR&nbsp;&nbsp; ACCESSIBLE THROUGH THE WEBSITES OR ANY PART THEREOF. THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. TO THE EXTENT THE ABOVE DISCLAIMER OR THE LIMITATIONS SET FORTH HEREIN ARE PROHIBITED BY LAW, THEIR APPLICABILITY SHALL BE LIMITED TO THE MINIMUM PERMITTED BY LAW.</p>
          <p>This limitation on liability includes, but is not limited to, the transmission of any viruses which may infect Your equipment, failure of mechanical or electronic equipment or communication lines, telephone or other interconnect problems (e.g., You cannot access Your internet provider), unauthorized access, theft, operator errors, strikes or other labor problems or any force majeure.</p>
          <p>YOU HEREBY AGREE THAT LUSA’S TOTAL AGGREGATE LIABILITY AND YOUR SOLE REMEDY AGAINST LUSA ARISING OUT OF OR RELATING TO THE USE OF THE PORTAL OR WEBSITES SHALL NOT EXCEED THE TOTAL AMOUNT YOU (OR ANY OTHER USER) PAID, IF ANYTHING, TO LUSA FOR THE PORTAL OR WEBSITES FROM THE EFFECTIVE DATE OF THIS AGREEMENT DURING THE TERM OF THIS AGREEMENT.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ol start="13">
            <li><strong> INDEMNITY</strong></li>
          </ol>
          <p>You agree to indemnify and hold LUSA and Related Parties harmless from and against any suit, action, claim, demand, penalty or loss, including reasonable attorneys’ fees, made by or resulting from any third party due to or arising out of Your use of the Websites, any materials contained in the Websites, any breach of this ToU or the materials it incorporates by reference, including, without limitation, the LUSA Privacy Policy, or Your violation of any law, regulation, order or other legal mandate, or the rights of a third party.</p>
          <ol start="14">
            <li><strong> CHOICE OF LAW; DISPUTES</strong></li>
          </ol>
          <p>The construction, validity and performance of this ToU and all non-contractual obligations arising from or connected with this ToU shall be governed by the laws of the State of California without giving effect to conflict of laws principles thereof. Each party irrevocably agrees to submit to the exclusive jurisdiction of the state and federal courts sitting in Los Angeles County any claim or matter arising under or in connection with this ToU, provided that the limitation set forth herein shall not prevent LUSA from seeking injunctive relief in any other jurisdiction. EACH PARTY, TO THE EXTENT PERMITTED BY LAW, KNOWINGLY, VOLUNTARILY, UNCONDITIONALLY AND INTENTIONALLY WAIVES ITS RIGHT TO A TRIAL BY JURY IN ANY ACTION OR LEGAL PROCEEDING ARISING OUT OF OR RELATING TO THIS ToU. The services of the Websites are intended for viewing and use only within the 50 U.S. states and the District of Columbia, and shall be governed solely by U.S. law.</p>
          <ol start="15">
            <li><strong> DATA STORAGE; ACCESS OUTSIDE OF THE UNITED STATES</strong></li>
          </ol>
          <p>Your personal information will be processed for LUSA by Dunn &amp; Bradstreet, TransUnion or other similar third-party providers whose data protection and privacy protections may not afford the same level of protection as required by laws of certain countries, such as the member nations of the European Union. We make no claims that the Websites or any of its contents are appropriate or may be downloaded or accessed outside of the United States. If You access the Websites from outside the United States, You do so at Your own risk and are responsible for compliance with the laws of Your country. You may not use or export any content of the Websites in violation of U.S. export laws and regulations.</p>
          <ol start="16">
            <li><strong> GENERAL</strong></li>
          </ol>
          <p>All rights not otherwise expressly granted to You by this ToU are reserved to LUSA. You agree that no joint venture, partnership, employment, or agency relationship exists between You and LUSA as a result of this ToU or any use of the Websites. The failure of LUSA to exercise or enforce any right or provision of this ToU shall not constitute a waiver of such right or provision. If any provision of this ToU is found by a court of competent jurisdiction to be invalid or unenforceable, the parties agree that the court should give effect to the parties’ intentions as reflected in such provision, and the other provisions of this ToU shall remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Websites or this ToU must be filed within one (1) year after such claim or cause of action arose or be forever barred. The section titles in this ToU are for convenience only and have no legal or contractual effect. Neither the course of conduct between You and LUSA, nor trade practice shall act to modify any provision of this ToU. This ToU are not assignable, transferable or sub-licensable by You.</p>
          <ol start="17">
            <li><strong> TELEPHONE COMMUNICATIONS</strong></li>
          </ol>
          <p>By accepting this ToU, You expressly consent to be contacted by (i) lender, (ii) LUSA, (iii) lender’s and/or LUSA’s representatives, agents, servicers, contractors, subsidiaries, affiliates, officers and other partners and employees, or anyone calling on lender’s or LUSA’s behalf at any phone number (including mobile, cellular/wireless, or similar devices) or email address You provide at any time, for any lawful purpose.&nbsp; Such lawful purposes include, but are not limited to, obtaining information, verification and identification purposes, account transactions or servicing related matters, and suspected fraud or identity theft.&nbsp; The ways in which We may contact You include live operator, automatic telephone dialing system (auto-dialer), prerecorded and artificial voice message, text/SMS message or email.&nbsp; Phone numbers and email addresses You provide include those You give to Us, those from which You or someone acting on Your behalf contact Us, or which We obtain through other means (such as skip tracing and caller ID capture).&nbsp; If You provide a phone number for which You are not the owner, You confirm You are authorized to provide that number. &nbsp;You agree to pay any fee(s) or charge(s) that You may incur for incoming communications from Us or outgoing communications to Us, to or from any such number or email address, without reimbursement from Us.&nbsp; If one of Our agents or representatives calls, s/he may also leave a message on Your answering machine, voicemail, or through a text message.&nbsp; You agree to alert Us whenever you stop using a particular telephone number.</p>
          <p>Indemnification: If You provide telephone number(s) for which You are not the subscriber, or fail to notify Us when You cease to be the subscriber for a telephone number You previously gave Us, You agree to indemnify Us, Our subsidiaries, affiliates, officers, agents, servicers, contractors and other partners and employees for any costs and expenses, including reasonable attorneys’ fees, incurred as a result of Us contacting or attempting to contact You at the number(s).&nbsp; Your obligation under this paragraph shall survive termination of this ToU.</p>
          <p>Communication Revocation: You may revoke Your authorization for most communications.&nbsp; However, You cannot revoke authorization for fraud related communications or legally required communications.&nbsp; To change Your communication preferences or to revoke consent, You must write Us.&nbsp; Please (i) include Your name and mailing address, (ii) specify whether You are changing Your preferences regarding mail, telephone calls, emails and/or text or SMS messages, (iii) provide the specific phone number(s) and/or email address for which You are requesting communications to cease, and (iv) send this written notice to LendingUSA, LLC, 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403.</p>
          <p>
            Marketing Calls and Texts: We will not use autodialed or prerecorded calls or texts to contact You for marketing purposes unless You provide us with prior express written consent. If You have provided such consent to receive marketing communications, You agree that (i) lender, (ii) LUSA, (iii) lenders and/or LUSA’s subsidiaries, affiliates, officers, agents, servicers, contractors, other partners and employees, representatives, or anyone calling or texting on Lender or LUSA’s behalf, may use autodialed or prerecorded phone calls and autodialed SMS text messages to contact You at the number(s) You provide for the purpose of describing goods and services that may be of interest to You, whether offered by lender, LUSA or third parties. If provided, Your consent will be effective even if the number(s) You have provided is registered on any state or federal Do Not Call list. This consent for telemarketing communications shall remain in effect until You withdraw it. Your consent to receive marketing communications is not a condition of obtaining a loan and may be withdrawn at any time by providing written notice to LUSA, c/o Compliance Department, at 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403, or by e-mailing&nbsp;
            <a href="mailto:customercare@lendingusa.com">customercare@lendingusa.com</a>
            <u>.</u>
            {' '}
            You may also reply “STOP” to opt out of receiving autodialed text messages only. These telephone calls and text messages may incur message, data and access fees from Your telephone provider, and You agree to pay any fee(s) or charge(s) that You may incur for incoming communications from Us or outgoing communications to Us, to or from any such number(s) or email address, without reimbursement from Us.
          </p>
          <ol start="18">
            <li><strong> QUESTIONS OR COMMENTS</strong></li>
          </ol>
          <p>LUSA welcomes questions and comments about this ToU. Please direct questions or comments to the email address or phone number below:</p>
          <p>
            <a href="mailto:CustomerCare@LendingUSA.com">CustomerCare@LendingUSA.com</a>
            {' '}
            or (800) 994-6177
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default TermsOfUse;
