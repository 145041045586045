// @flow
/**
 * @module Reducers/Dashboard
 * @desc Dashboard Reducer
 */

import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import { DashboardConstants } from 'constants/dashboard';
import { AuthConstants } from 'constants/auth';
import { requestPending, requestSuccess, requestFail } from 'components/Fetch';

const loansList = sessionStorage.getItem('loansList');
const initialState = {
  loansList: {
    isLoading: false,
    activeLoanId: (loansList !== null && JSON.parse(loansList).length > 0) ? JSON.parse(loansList)[0].id : null,
    data: loansList !== null ? JSON.parse(loansList) : null,
  },
  customerDetail: { isLoading: false },
  loanDetail: { isLoading: false },
  loanTerm: { isLoading: false },
  transactionsList: { isLoading: false },
  paymentsList: { isLoading: false },
  paymentMethods: { isLoading: false },
  documents: { isLoading: false },
  donwload: { isLoading: false },
  remove: { isLoading: false },
  putPaymentMethod: { isLoading: false },
  makePaymentMessage: { isLoading: false },
  currentPayoffInfo: { isLoading: false },
  futurePayoffInfo: { isLoading: false },
  scheduledPaymentsList: { isLoading: false },
  billingSetting: { isLoading: false },
  autopay: { isLoading: false },
  loanCharges: { isLoading: false },
};

export default handleActions({
  [requestPending(DashboardConstants.FETCH_LOANS_LIST)]: (state) => ({
    ...state,
    loansList: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_LOANS_LIST)]: (state, action) => ({
    ...state,
    loansList: {
      data: action.payload,
      activeLoanId: get(action, 'payload.0.id', null),
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_LOANS_LIST)]: (state, action) => ({
    ...state,
    loansList: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [DashboardConstants.SELECT_LOAN_ID]: (state, action) => ({
    ...state,
    loansList: {
      ...state.loansList,
      activeLoanId: action.payload,
    },
  }),

  [requestPending(DashboardConstants.FETCH_CUSTOMER_DETAIL)]: (state) => ({
    ...state,
    customerDetail: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_CUSTOMER_DETAIL)]: (state, action) => ({
    ...state,
    customerDetail: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_CUSTOMER_DETAIL)]: (state, action) => ({
    ...state,
    customerDetail: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.FETCH_LOAN_DETAIL)]: (state) => ({
    ...state,
    loanDetail: {
      ...state.loanDetail,
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_LOAN_DETAIL)]: (state, action) => ({
    ...state,
    loanDetail: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_LOAN_DETAIL)]: (state, action) => ({
    ...state,
    loanDetail: {
      ...state.loanDetail,
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.FETCH_LOAN_TERM)]: (state) => ({
    ...state,
    loanTerm: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_LOAN_TERM)]: (state, action) => ({
    ...state,
    loanTerm: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_LOAN_TERM)]: (state, action) => ({
    ...state,
    loanTerm: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.FETCH_TRANSACTIONS_LIST)]: (state) => ({
    ...state,
    transactionsList: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_TRANSACTIONS_LIST)]: (state, action) => ({
    ...state,
    transactionsList: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_TRANSACTIONS_LIST)]: (state, action) => ({
    ...state,
    transactionsList: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.FETCH_DOCUMENTS)]: (state) => ({
    ...state,
    documents: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_DOCUMENTS)]: (state, action) => ({
    ...state,
    documents: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_DOCUMENTS)]: (state, action) => ({
    ...state,
    documents: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.FETCH_PAYMENTS_LIST)]: (state) => ({
    ...state,
    paymentsList: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_PAYMENTS_LIST)]: (state, action) => ({
    ...state,
    paymentsList: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_PAYMENTS_LIST)]: (state, action) => ({
    ...state,
    paymentsList: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.FETCH_PAYMENT_METHODS)]: (state) => ({
    ...state,
    paymentMethods: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_PAYMENT_METHODS)]: (state, action) => ({
    ...state,
    paymentMethods: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_PAYMENT_METHODS)]: (state, action) => ({
    ...state,
    paymentMethods: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.DOWNLOAD_DOCUMENT)]: (state) => ({
    ...state,
    donwload: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.DOWNLOAD_DOCUMENT)]: (state, action) => ({
    ...state,
    donwload: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.DOWNLOAD_DOCUMENT)]: (state, action) => ({
    ...state,
    donwload: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.REMOVE_PAYMENTACCOUNT)]: (state) => ({
    ...state,
    remove: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.REMOVE_PAYMENTACCOUNT)]: (state, action) => ({
    ...state,
    remove: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.REMOVE_PAYMENTACCOUNT)]: (state, action) => ({
    ...state,
    remove: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.PUT_PAYMENT_ACCOUNT)]: (state) => ({
    ...state,
    putPaymentMethod: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.PUT_PAYMENT_ACCOUNT)]: (state, action) => ({
    ...state,
    putPaymentMethod: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.PUT_PAYMENT_ACCOUNT)]: (state, action) => ({
    ...state,
    putPaymentMethod: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.MAKE_PAYMENT)]: (state) => ({
    ...state,
    makePaymentMessage: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.MAKE_PAYMENT)]: (state, action) => ({
    ...state,
    makePaymentMessage: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.MAKE_PAYMENT)]: (state, action) => ({
    ...state,
    makePaymentMessage: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.FETCH_CURRENT_PAYOFF_INFO)]: (state) => ({
    ...state,
    currentPayoffInfo: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_CURRENT_PAYOFF_INFO)]: (state, action) => ({
    ...state,
    currentPayoffInfo: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_CURRENT_PAYOFF_INFO)]: (state, action) => ({
    ...state,
    currentPayoffInfo: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.FETCH_FUTURE_PAYOFF_INFO)]: (state) => ({
    ...state,
    futurePayoffInfo: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_FUTURE_PAYOFF_INFO)]: (state, action) => ({
    ...state,
    futurePayoffInfo: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_FUTURE_PAYOFF_INFO)]: (state, action) => ({
    ...state,
    futurePayoffInfo: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestPending(DashboardConstants.FETCH_SCHEDULED_PAYMENT)]: (state) => ({
    ...state,
    scheduledPaymentsList: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_SCHEDULED_PAYMENT)]: (state, action) => ({
    ...state,
    scheduledPaymentsList: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_SCHEDULED_PAYMENT)]: (state, action) => ({
    ...state,
    scheduledPaymentsList: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.GET_BILLING_SETTING)]: (state) => ({
    ...state,
    billingSetting: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.GET_BILLING_SETTING)]: (state, action) => ({
    ...state,
    billingSetting: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.GET_BILLING_SETTING)]: (state, action) => ({
    ...state,
    billingSetting: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.PUT_BILLING_SETTING)]: (state) => ({
    ...state,
    billingSetting: {
      isLoading: true,
      ...state.billingSetting,
    },
  }),
  [requestSuccess(DashboardConstants.PUT_BILLING_SETTING)]: (state) => ({
    ...state,
    billingSetting: {
      data: {
        eBilling: !state.billingSetting.data.eBilling,
      },
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.PUT_BILLING_SETTING)]: (state, action) => ({
    ...state,
    billingSetting: {
      error: action.payload,
      isLoading: false,
      ...state.billingSetting,
    },
  }),

  [requestPending(DashboardConstants.FETCH_AUTOPAY_INFO)]: (state) => ({
    ...state,
    autopay: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_AUTOPAY_INFO)]: (state, action) => ({
    ...state,
    autopay: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_AUTOPAY_INFO)]: (state, action) => ({
    ...state,
    autopay: {
      error: action.payload,
      isLoading: false,
    },
  }),

  [requestPending(DashboardConstants.FETCH_LOAN_CHARGES)]: (state) => ({
    ...state,
    loanCharges: {
      isLoading: true,
    },
  }),
  [requestSuccess(DashboardConstants.FETCH_LOAN_CHARGES)]: (state, action) => ({
    ...state,
    loanCharges: {
      data: action.payload,
      isLoading: false,
    },
  }),
  [requestFail(DashboardConstants.FETCH_LOAN_CHARGES)]: (state, action) => ({
    ...state,
    loanCharges: {
      error: action.payload,
      isLoading: false,
    },
  }),
  [requestSuccess(AuthConstants.AUTH_SIGN_OUT)]: () => ({
    ...initialState,
  }),
}, initialState);
