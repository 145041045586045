import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { ButtonLink } from 'components/Button';
import { resendSignUp } from 'utils/aws';

const RegistrationSuccess = (props) => {
  const { location } = props;
  const notification = useRef(null);

  const handleResendClick = () => {
    const username = get(location, 'state.userName', '');
    const ssn = get(location, 'state.ssn', '');
    const email = get(location, 'state.email', '');
    const clientMetadata = {
      ssn,
      email,
    };

    resendSignUp(username, clientMetadata)
      .then(() => {
        notification.current
        && notification.current.addNotification({
          message: 'Verification email has been resend to your email',
          level: 'success',
          position: 'tc',
        });
      })
      .catch((err) => {
        notification.current
        && notification.current.addNotification({
          message: err.message,
          level: 'error',
          position: 'tc',
        });
      });
  };

  const handleSignInClick = () => props.history.push('/');

  return (
    <div className="registration-page">
      <NotificationSystem ref={notification} />
      <Container fluid>
        <Row style={{ minHeight: 600 }}>
          <Col className="d-flex text-center flex-column col-4 offset-4 pt-2">
            <h2>Next Step: Verify your email</h2>
            <h3 className="mt-3">
              Verification email was sent to
              {' '}
              {get(location, 'state.email', '')}
            </h3>
            <p className="text-center mt-4 mb-0">You must verify your email before you can sign in.</p>
            <p className="text-center mt-4 mb-0"><b>Did not receive the email?</b></p>
            <ButtonLink className="btn-link cursor-pointer" style={{ cursor: 'pointer' }} onClick={handleResendClick}>
              Click here to resend the verification email
            </ButtonLink>

            <p className="text-center mt-4"><b>Completed email verification? Proceed to Sign In</b></p>
            <div className="d-flex justify-content-center">
              <Button
                style={{ width: 350 }}
                onClick={handleSignInClick}
                color="primary"
                type="submit"
              >
                Sign In
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

RegistrationSuccess.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(RegistrationSuccess);
