import moment from 'moment';
import get from 'lodash/get';
import { numberUnmask, floatUnmask } from 'utils/masks';

const oneTimePaymentSchema = {
  payFromAccount: [{
    rule: 'required',
    error: 'This field is required',
  }],
  paymentDate: [{
    rule: 'required',
    isValid: (input, err, opt, formData) => {
      if (!input) {
        return 'The input field is required';
      }

      if (numberUnmask(input).length < 8) {
        return 'Date of Service is not valid';
      }

      if (!moment(input, 'MM/DD/YYYY').isValid()) {
        return 'Date of Service is not valid';
      }

      if ((moment(input, 'MM/DD/YYYY').isBefore(moment().subtract(1, 'day')) || moment(input, 'MM/DD/YYYY').isAfter(moment().add(45 - Number(formData.daysPastDue), 'days'))) && Number(formData.daysPastDue) <= 45) {
        return `Payments can only be scheduled between ${moment().format('MM/DD/YYYY')} and ${moment().add(45 - Number(formData.daysPastDue), 'days').format('MM/DD/YYYY')}`;
      }

      return true;
    },
  }],
  paymentAmount: [{
    isValid: (input, err, opt, formData) => {
      const paymentAmount = get(formData, 'paymentAmount');
      if (!input) {
        return 'The input field is required';
      }

      if (paymentAmount === '2') {
        return true;
      }

      if (
        (paymentAmount === '0' && Number(floatUnmask(formData.totalAmount)) < 1)
        || (paymentAmount === '1' && Number(floatUnmask(formData.pastDueAmount)) < 1)
      ) {
        return 'Payment amount cannot be less than $1.00';
      }

      return true;
    },
  }],
  customPaymentAmount: [{
    isValid: (input, err, opt, formData) => {
      const paymentAmount = get(formData, 'paymentAmount');
      if (paymentAmount !== '2') {
        return true;
      }

      if (!input) {
        return 'The input field is required';
      }

      return Number(floatUnmask(input)) < 1 ? 'Payment amount cannot be less than $1.00' : true;
    },
  }],
};

export default oneTimePaymentSchema;
