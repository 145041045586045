import { DashboardConstants } from 'constants/dashboard';
import { createAction } from 'redux-actions';

export const fetchLoansList = createAction(DashboardConstants.FETCH_LOANS_LIST);
export const fetchLoanDetail = createAction(DashboardConstants.FETCH_LOAN_DETAIL);
export const fetchLoanTerm = createAction(DashboardConstants.FETCH_LOAN_TERM);
export const fetchTransactionsList = createAction(DashboardConstants.FETCH_TRANSACTIONS_LIST);
export const fetchPaymentsList = createAction(DashboardConstants.FETCH_PAYMENTS_LIST);
export const selectLoanId = createAction(DashboardConstants.SELECT_LOAN_ID);
export const fetchPaymentMethods = createAction(DashboardConstants.FETCH_PAYMENT_METHODS);
export const fetchDocuments = createAction(DashboardConstants.FETCH_DOCUMENTS);
export const downloadDocument = createAction(DashboardConstants.DOWNLOAD_DOCUMENT);
export const removePaymentMethod = createAction(DashboardConstants.REMOVE_PAYMENTACCOUNT);
export const putPaymentAccount = createAction(DashboardConstants.PUT_PAYMENT_ACCOUNT);
export const postPaymentAccount = createAction(DashboardConstants.POST_PAYMENT_ACCOUNT);
export const postDebitCardAccount = createAction(DashboardConstants.POST_DEBIT_CARD_ACCOUNT);
export const makePayment = createAction(DashboardConstants.MAKE_PAYMENT);
export const fetchCurrentPayoffInfo = createAction(DashboardConstants.FETCH_CURRENT_PAYOFF_INFO);
export const fetchFuturePayoffInfo = createAction(DashboardConstants.FETCH_FUTURE_PAYOFF_INFO);
export const fetchScheduledPayment = createAction(DashboardConstants.FETCH_SCHEDULED_PAYMENT);
export const cancelAutopay = createAction(DashboardConstants.CANCEL_AUTOPAY);
export const putScheduleAutopay = createAction(DashboardConstants.PUT_SCHEDULE_AUTOPAY);
export const validateRegistration = createAction(DashboardConstants.VALIDATE_REGISTRATION);
export const getBillingSetting = createAction(DashboardConstants.GET_BILLING_SETTING);
export const putBillingSetting = createAction(DashboardConstants.PUT_BILLING_SETTING);
export const fetchCustomerDetail = createAction(DashboardConstants.FETCH_CUSTOMER_DETAIL);
export const fetchAutopayInfo = createAction(DashboardConstants.FETCH_AUTOPAY_INFO);
export const postForkInfo = createAction(DashboardConstants.POST_FORK_INFO);
export const fetchAutopays = createAction(DashboardConstants.FETCH_AUTOPAYS);
export const fetchLoanCharges = createAction(DashboardConstants.FETCH_LOAN_CHARGES);
export const validateBankRoutingNumber = createAction(DashboardConstants.VALIDATE_BANK_ROUTING_NUMBER);
