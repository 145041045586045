import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { signOut } from 'actions/auth';
import ContactModal from 'components/ContactModal';
import { ButtonLink } from 'components/Button/ButtonLink';
import { numberUnmask } from 'utils/masks';
import { appConfig } from 'config/appConfig';
import './style.scss';

export const HeaderComponent = (props) => {
  const { loanDetail } = props;
  const { envLabel, collectionsPhone, servicingPhone } = appConfig;
  const [dropdown, setDropdown] = useState(false);
  const [modal, setModal] = useState(false);
  const pastDue = useMemo(() => Number(get(loanDetail, 'data.0.daysPastDue', 0)), [loanDetail]);
  const phoneNo = useMemo(() => (pastDue > 16 ? collectionsPhone : servicingPhone), [pastDue, collectionsPhone, servicingPhone]);

  const toggle = () => {
    setDropdown(!dropdown);
  };

  const handlePageNavigation = (route, e) => {
    e.preventDefault();
    const { history } = props;
    history.push(route);
  };

  const handleSignOut = () => {
    props.signOut();
  };

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <div>
      <Navbar light expand="lg" className="consumer-header">
        <div className="d-flex align-items-center position-relative">
          <NavbarBrand className="text-hide" onClick={handlePageNavigation.bind(null, '/consumer/dashboard')}>LendingUSA Portal</NavbarBrand>
          { envLabel && envLabel !== 'production' && <div className="envLabel">{ envLabel }</div> }
        </div>
        {
          sessionStorage.idToken
            ? (
              <>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={dropdown} navbar>
                  <Nav navbar className="w-100">
                    <div className="lu-main-nav d-lg-flex ml-lg-auto">
                      <NavItem>
                        <NavLink to="/consumer/dashboard" className="nav-link">Dashboard</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink to="/consumer/loans" className="nav-link">My Loan</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink to="/consumer/documents" className="nav-link">Documents</NavLink>
                      </NavItem>
                      <NavItem className="--hide-above-small">
                        <ButtonLink onClick={handleModal} className="nav-link" style={{ cursor: 'pointer' }}>Contact Us</ButtonLink>
                      </NavItem>
                    </div>
                    <div className="lu-second-nav d-lg-flex ml-lg-auto">
                      <NavItem className="align-items-end --show-above-small flex-column">
                        <ButtonLink className="btn-link" onClick={handleModal} style={{ lineHeight: 1, fontSize: 14, marginTop: -2, cursor: 'pointer' }}>
                          <strong>Contact Us</strong>
                        </ButtonLink>
                        <a href={`tel:1${numberUnmask(phoneNo)}`} className="p-0 text-right nav-link nav-tel" style={{ lineHeight: 1, marginTop: 3 }}>
                          <strong>{phoneNo}</strong>
                        </a>
                      </NavItem>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          Account
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem tag="span" onClick={handlePageNavigation.bind(null, '/consumer/settings')}>Account Settings</DropdownItem>
                          <DropdownItem tag="span" to="/" onClick={handleSignOut}>Sign Out</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Nav>
                </Collapse>
              </>
            )
            : (
              <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              >
                <a href={`tel:+1${numberUnmask(servicingPhone)}`} className="phoneNumber">
                  <span>Questions?&nbsp;</span>
                  <strong>{servicingPhone}</strong>
                </a>
              </div>
            )
        }
      </Navbar>

      <ContactModal isOpen={modal} toggle={handleModal} />
    </div>
    // <!-- End Portal Header -->
    // End Global Header
  );
};

HeaderComponent.propTypes = {
  signOut: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loanDetail: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      loanDetail: state.dashboard.loanDetail,
    }),
    {
      signOut,
    }
  )
)(HeaderComponent);
