import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import emailMask from 'text-mask-addons/dist/emailMask';
import {
  Container,
  Row,
  Form,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import Input from 'components/Form/Input';
import Validator from 'components/Validator/Validator';
import { Button } from 'components/Button';

import { validateRegistration } from 'actions/dashboard';
import { numberUnmask } from 'utils/masks';
import schema from './registrationSchema';

const Registration = (props) => {
  const {
    validator: {
      values,
      errors,
    },
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const notification = useRef(null);

  const handleInputChange = (event) => {
    const {
      validator: { onChangeHandler },
    } = props;

    const value = event.target.value.trim();
    onChangeHandler(event.target.name, value);
  };

  const handleSubmitFrom = (e) => {
    e.preventDefault();
    const {
      validator: { validate },
      googleReCaptchaProps: { executeRecaptcha },
      history,
    } = props;
    setIsLoading(true);
    if (validate(schema).isValid) {
      const emailLowercase = `${values.email}`.toLowerCase();
      executeRecaptcha('user_registration').then((token) => {
        props.validateRegistration({
          data: {
            ...values,
            email: emailLowercase,
            ssn: numberUnmask(values.ssn),
            token,
          },
          success: (res) => {
            switch (res.code) {
              case 'RegistrationCompleted':
                history.push('/registration-completed', { ssn: numberUnmask(values.ssn), email: emailLowercase });
                break;
              case 'PendingConfirmation':
                history.push('/registration-success', { ssn: numberUnmask(values.ssn), email: emailLowercase, userName: res.accessUserName });
                break;
              default:
                history.push('/registration-confirm', { ssn: numberUnmask(values.ssn), email: emailLowercase });
            }
            setIsLoading(false);
          },
          fail: (err) => {
            notification.current
            && notification.current.addNotification({
              message: err.data.message ? err.data.message : 'Try again',
              level: 'error',
              position: 'tc',
            });
            setIsLoading(false);
          },
        });
      });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="registration-page">
      <NotificationSystem ref={notification} />
      <Container fluid>
        <Form onSubmit={handleSubmitFrom}>
          <Row className="align-items-center" style={{ minHeight: '500px' }}>
            <Col lg={{ size: 4, offset: 4 }} md={{ size: 6, offset: 3 }}>
              <Card>
                <CardHeader>User Registration</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <h5 className="mb-2 text-center">
                        Enter the information below to verify your identity
                      </h5>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col>
                      <Input
                        name="ssn"
                        label="SSN"
                        value={values.ssn}
                        onChange={handleInputChange}
                        hasError={!!errors.ssn}
                        hasValue
                        isMasked={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeHolder="Type SSN"
                        isBadgeVisible={false}
                        isRequired
                        errorMessage={errors.ssn}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        name="email"
                        label="Email"
                        isMasked={emailMask}
                        value={values.email}
                        onChange={handleInputChange}
                        hasError={!!errors.email}
                        hasValue
                        placeHolder="Email"
                        isBadgeVisible={false}
                        isRequired
                        errorMessage={errors.email}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="w-100"
                        onClick={handleSubmitFrom}
                        isLoading={isLoading}
                        color="primary"
                        type="submit"
                      >
                        {'Next >>'}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ fontSize: 12, color: '#888' }}>
                      This site is protected by reCAPTCHA and the Google&nbsp;
                      <a href="https://policies.google.com/privacy">Privacy Policy</a>
                      {' '}
                      and&nbsp;
                      <a href="https://policies.google.com/terms">Terms of Service</a>
&nbsp;also apply.
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

Registration.propTypes = {
  validator: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired,
  validateRegistration: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  validateRegistration,
};

export default compose(
  withRouter,
  Validator(schema),
  connect(null, mapDispatchToProps),
  withGoogleReCaptcha
)(Registration);
